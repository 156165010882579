.recurrent-remote-hands-detail {
  .head {
    @extend %important-text;
  }
  .service-label {
    margin-bottom: 10px;
  }
  .hosting-spaces {
    margin-top: 20px;
  }
}