.cgu-bar {
  padding: 0 20px;
  display: flex;
  min-height: 30px;

  color: $c-white;
  background: $c-grey-variant-3;
  font-family: $ft-fam-primary;
  @include fontSize(14px);

  .text-cgu, .btn-cgu {
    display: flex;
    align-items: center;
  }
  .text-cgu {
    flex: 1;
  }
  .btn-cgu {
    @include fontSize(12px);
    justify-content: flex-end;
    align-items: center;
    display: flex;
    .icon {
      @include fontSize(20px)
    }

    &.link {
      color: $c-white;
      &:hover {
        .label {
          &:after {
            opacity: 0;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    padding: 0 20px;
    flex-direction: column;

    > * {
      margin-top: 10px;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}
