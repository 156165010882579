$w-xs: 100px;
$w-sm: 150px;
$w-md: 250px;
$w-lg: 500px;
$w-full: 100%;

.alert-msg {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 60px;
	padding: 10px 20px;
	background-color: $c-grey-variant-3;
	color: $c-white;
	margin-bottom: 30px;
	@include fontSize(14px);
	font-family: $ft-fam-primary;

	.head {
		display: flex;
		flex-direction: row;
	}

	.important {
		font-weight: $ft-sm-bold;
		margin-right: 10px;
	}

	.text {
		@include fontSize(14px);
		display: inline;
		margin-bottom: 0;
	}
	.btn {
		@include fontSize(12px);
		text-transform: uppercase;
		color: $c-white;

	}
	//md
	@media (min-width: 991px + 1px) {
		flex-direction: row;
		.head {
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: space-between;
		}
		.text {
			display: flex;
			flex: 1;
		}
	}
}

.stepper-desk {
	font-family: $ft-fam-primary;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 15px;
	background-color: $c-white;
	box-shadow: 0 1px 2px 0 $c-black-t10;
	%remain-steps{
		&:before {
			content: counter(stepNum);
			font-family: inherit;
			font-weight: 700;
		}
		&:after{
			background-color: $c-grey-variant-8 ;
		}
	}
	.multi-steps{
		display: table;
		table-layout: fixed;
		width: 100%;
		padding-bottom: 25px;
		> li{
			counter-increment: stepNum;
			text-align: center;
			display: table-cell;
			position: relative;
			color: $c-grey-variant-3;
			@include fontSize(12px);
			font-weight: $ft-bold;

			&:before{
				content: '\f00c';
				content: '\2713;';
				content: '\10003';
				content: '\10004';
				content: '\2713';
				display: block;
				margin: 0 auto 12px;
				background-color: $c-main;
				color: $c-white;
				width: 30px;
				height: 30px;
				line-height: 28px;
				text-align: center;
				font-weight: bold;
				z-index: 2;
				position: relative;
				border:{
					width: 2px;
					style: solid;
					color: $c-main;
					radius: 50%;
				}
			}
			&:after{
				content: '';
				height: 2px;
				width: 100%;
				background-color: $c-main;
				position: absolute;
				top: 16px;
				left: 50%;
				z-index: 0;
			}
			&:last-child{
				&:after{
					display: none;
				}
			}

			&.is-active{
				@extend %remain-steps;
				&:before{
					background-color: $c-main;
					border-color: $c-main;
				}

				~ li{
					color: $c-grey-variant-4;
					@extend %remain-steps;
					&:before{
						background-color: $c-grey-variant-8;
						border-color: $c-grey-variant-8;
					}
				}
			}
		}
	}
}

.stepper-mobile {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;

	.step-number {
		background-color: $c-main;
		color: $c-white;
		@include fontSize(14px);
		font-weight: $ft-bold;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		min-height: 50px;
		min-width: 50px;
		border-radius:  100px;
		margin-right: 10px;
	}
	.step-title  {}
}

.champ-obligatoire {
	margin-bottom: 20px;
	.label {
		@include fontSize(13px);
		font-weight: $ft-reg;
		color: $c-grey-variant-6;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.43;
		letter-spacing: normal;
	}
}

.form-ctas {
	display: flex;
	justify-content: space-evenly;
	align-items: baseline;
}

//md
@media (max-width: $md-queries + 1px) {
	.form-ctas {
		display: flex;
		justify-content: center;
		align-items: unset;
		flex-direction: column;
	}
}

.form-standard {
	.error {
		@extend %error-text;
	}
	&+.form-standard {
		margin-top: 20px;
	}
	.form-group {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 10px;
		.form-group {
			margin-bottom: 0 !important;
		}
		& +.form-title {
			margin-top: 20px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&.active {
			.form-control {
				border-color: $c-main;
				box-shadow: 0px 3px 0px -1px $c-grey-variant-11,
				0px 5px 0px -1px $c-black-t01;
			}
		}
		&.error {
			.form-control {
				border-color: $c-error;
			}
			.error {
				color: $c-error;
			}
		}
	}
	.form-label {
		@extend %label;
		min-width: 250px;
		margin-bottom: 0;
	}
	.form-title {
		@extend %form-title;
		min-width: 250px;
		margin-bottom: 10px;
	}
	.form-control {
		&:focus {
			border-color: $c-main;
			box-shadow:
				 0px 3px 0px -1px $c-grey-variant-11,
				 0px 5px 0px -1px $c-black-t01;
		}
		@include fontSize(14px);
		min-height: $form-field-height;
		border-radius: 2px;
		border: 1px solid $c-grey-variant-9;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.71;
		letter-spacing: normal;
		margin-top: 4px;
		margin-bottom: 4px
	}
	.group-form-control {
		display: flex;
		flex-direction: column;
        .error {
          display: flex;
          flex: 1;
          flex-wrap: wrap ;
          @include fontSize(12px);
          margin-left: 0;
          margin-top: 5px ;
        }
	}
	.form-input {
		display: flex;
		align-items: flex-start;
		.form-label {
			margin-top: 15px;
		}
		.form-timezone {
			margin-left: 15px;
			margin-top: 15px;
			.btn-tooltip {
				margin-left: 15px;
			}
		}
	}
	.form-text-area {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		> * {
			width: 100%;
		}
		.form-label {
			margin-bottom: 5px;
		}
		.form-control {
			min-height: 120px;
		}
	}
	.form-checkbox {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		> * {
			width: auto;
		}
		.form-label {
			margin-bottom: 5px;
		}
		&.form-group {
			&.form-xs {
				.pretty {
					width: $w-xs;
				}
			}
			&.form-sm {
				.pretty {
					width: $w-sm;
				}
			}
			&.form-md {
				.pretty {
					width: $w-md;
				}
			}
			&.form-lg {
				.pretty {
					width: $w-lg;
				}
			}
		}
		.label {
			@include fontSize(14px);
		}
	}
	.form-radio {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		> * {
			width: auto;
		}
		.form-label {
			margin-bottom: 5px;
		}
		.form-group {
			&.form-xs {
				.pretty {
					width: $w-xs;
				}
			}
			&.form-sm {
				.pretty {
					width: $w-sm;
				}
			}
			&.form-md {
				.pretty {
					width: $w-md;
				}
			}
			&.form-lg {
				.pretty {
					width: $w-lg;
				}
			}
		}
		.label {
			@include fontSize(14px);
		}
	}
	.form-data {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		> * {
			width: auto;
		}
		.form-label {
			margin-bottom: 10px;
		}
		.form-control-file {
			@include fontSize(14px);
		}
	}
	.form-file {
		cursor: pointer;
		border: 1px solid $c-grey-variant-7;
		padding: 24px 41px;
		flex-grow: 1;
		background-color: $c-white;
		margin-bottom: 10px;
		width: 100%;
		.icon {
			font-size: 40px;
			margin-right: 25px;
		}
		&:active {
			background-color: $c-grey-variant-10;
		}
	}
	.form-file-disabled {
		background-color: $c-grey-variant-10;
		cursor: not-allowed;
	}
	@mixin dragover-border($border-color) {
		border: 1px solid $border-color;
	}
	.form-file-dragover-requests {
		@include dragover-border($c-data4-yellow)
	}
	.form-file-dragover-services {
		@include dragover-border($c-data4-red)
	}
	.form-file-dragover-sustainability {
		@include dragover-border($c-data4-green)
	}
	.form-file-dragover-management {
		@include dragover-border($c-data4-blue)
	}
	.form-file-dragover-support {
		@include dragover-border($c-main)
	}

	.form-xs {
		.form-control, .error {
			width: $w-xs;
		}
	}
	.form-sm {
		.form-control, .error {
			width: $w-sm;
		}
	}
	.form-md {
		.form-control, .error {
			width: $w-md;
		}
	}
	.form-lg {
		.form-control, .error {
			width: $w-lg;
		}
	}
	.form-full {
		.form-control, .error {
			width: $w-full;
		}
	}

	.form-row {
		display: flex;
		align-items: center;
		>  .col, [class*="col-"] {
			padding-right: 0;
		}
		.delimiter-label {
			float: left;
			.form-label {
				min-width: 30px;
				margin-left: 15px;
				margin-top: 15px;
			}
		}
	}

	//md
	@media (max-width: $md-queries + 1px) {
		.form-group {
			flex-direction: column;
			> * {
				width: 100%;
			}
			.form-label {
				margin-top: 0;
			}
			.form-control, .error {
				width: 100%;
			}
			.form-input {
				display: inline;
			}
			.form-timezone {
				margin-left: 0;
				margin-top: 10px;
			}
		}
		.form-radio {
			.form-group {
				> * {
					width: 100% !important;
				}
			}
		}
		.form-file {
			margin-bottom: 10px;
		}
	}

	// sm
	@media (max-width: $sm-queries + 1) {
		.form-row {
			flex-direction: column;
			.delimiter-label .form-label {
				margin-left: 0;
			}
		}
	}
}

.nouveau-visiteur {
	position: relative;
	.title {
		margin-bottom: 15px;
	}
	.form-input .form-label {
		font-weight: $ft-reg;
	}
	.contenu {
		@include fontSize(14px);
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.43;
		letter-spacing: normal;
		color: $c-grey-variant-3;
	}
	.position-corner-right {
		position: absolute;
		top: -10px;
		right: 0;
	}
	.link {
		margin-top: 0.5rem;
		margin-bottom: 1.5rem;
	}
}

.editer-visiteur {
	position: relative;
	.title {
		margin-bottom: 15px;
	}
	.form-input .form-label {
		font-weight: $ft-reg;
	}

	.position-corner-right {
		position: absolute;
		top:   0;
		right: 0;
	}
	.link .icon {
		display: none!important;
	}
	.link:hover .label:after {
		width: 100%;
	}
}

.paper {
	> .collapsable-element {
		> .collapse-action {
			padding: 20px;
		}
	}
}

.collapsable-element {
	.collapse-action {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 0;
		min-height: 64px;
		min-width: inherit;
		max-width: inherit;
		background: none!important;
		color: $c-grey-variant-3!important;
		cursor: pointer;
		&:after {
			display: none;
		}
	}
	.chevron {
		display: flex;
		align-items: center;
		.icon {
			height: 20px;
			width: 20px;
		}
		&+.titre {
			margin-left: 20px;
		}
	}
	.titre {
		flex: 1;
		height: auto;
		display: flex;
		align-items: center;
		.icon {
			height: 20px;
			width: 20px;
			color: $c-green-variant-3;
			&+.text {
				margin-left: 10px;
			}
		}
		.text {
			white-space: pre-wrap;
			margin: 0
		}
	}
	.error {
		.titre {
			color: $c-error;
			> * {
				color: $c-error;
			}
		}
	}
	.body {
		.contenu {
			margin-bottom: 10px;
		}
		.group-input {
			display: flex;
			flex-direction: row;
		}
	}
}

.contenu {
	@extend %content-text;
}

.contenu-important {
	color: $c-main;
}

.information-group {
  .information {
    @extend %option-text;
  }
}

.form-validation {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	> * + * {
		margin-left: 15px;
	}
	> .left {
		flex: 1;
	}
	.error {
		@extend %error-text;
	}
}





