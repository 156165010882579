
.main-body-annuaire {
	.liste-annuaire {
		background: $c-grey-variant-11;
		margin-left: -20px;
		margin-right: -20px;
		padding: 10px 20px;
		.label {
			@extend %text-ellipsis;
			width: auto;
			span {
				margin-right: 2px;
			}
		}
		.paper {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			margin: 10px 0;
		}
		.btn {
			box-shadow: none!important;
			outline: none!important;
			border:none!important;
			margin: 0;
            padding-right: 0;
			background: none;
			height: 20px;
			&:hover, &:focus {
				color: $c-main;
			}
			&:active {
				background-color: transparent;
				color: $c-main;
			}
		}
		@media (min-width: $md-queries + 1px) {
          height: 200px; // fix la hauteur de la popin en desktop
        }
	}
}

