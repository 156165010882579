$c-column-select: $c-main;
$c-column: fade_out($c-column-select, 0.4);
$c-line: $c-error;

.tickets-consumption-chart {
  $font-family: $font-family-sans-serif;
  $colors: $c-column $c-line;
  @import '~highcharts/css/highcharts.scss';
  background-color: $c-grey-variant-11;
  .highcharts-background {
    fill: $c-grey-variant-11;
  }
  .highcharts-markers {
    .highcharts-point {
      fill: $c-white;
      stroke-width: 2;
      stroke: $c-line;
    }
    .highcharts-point-select {
      fill: $c-line !important;
      stroke: $c-white !important;
    }
  }

  .highcharts-column-series {
    .highcharts-point-select {
      fill: $c-column-select !important;
      stroke: $c-column-select !important;
    }
  }

  .highcharts-series-hover {
    cursor: pointer;
  }

  .highcharts-axis-line {
    stroke: $c-grey-variant-6;
  }

  .highcharts-axis-labels {
    @include fontSize(12px);
    font-weight: $ft-reg;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-grey-variant-6;
  }
}
