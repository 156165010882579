$slick-font-path: $icomoon-font-path;
$slick-font-family: $icomoon-font-family;
$slick-loader-path: "~slick-carousel/slick/" !default;
$slick-prev-character: $icon-navigation-fleche-gauche;
$slick-next-character: $icon-navigation-fleche-droite;
$slick-arrow-color: $c-main;

@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';

.forfait-period-slider-container {
  background-color: $c-grey-variant-11;
}

.forfait-period-slider {
  &.slick-initialized {
    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.slick-disabled {
        cursor: not-allowed;
      }
      &.slick-invisible {
        visibility: hidden;
      }
    }
  }
  .slick-track {
    display: flex;
    justify-content: center;
  }
  .slick-disabled {
    opacity: 0.5;
  }
  .slick-slide {
    position: relative;
    background: $c-white;
    box-shadow: 0 1px 2px 0 $c-black-t10;
    padding: 10px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    min-height: 74px;
    min-width: 154px;
    &.slick-center {
      box-shadow: 0 6px 12px 0 $c-grey-variant-7;
      .slick-prev,
      .slick-next {
        display: none;
      }
      .tickets-information {
        display: block;
      }
    }
    .tickets-information {
      display: none;
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    + .slick-slide {
      margin-left: 10px;
    }
  }
  .slick-prev, .slick-next {
    position: relative;
    transform: none;
  }
  .slick-prev {
    left: auto;
    margin-right: 10px;
  }
  .slick-next {
    right: auto;
    margin-left: 10px;
  }
  .disabled {
    &.slick-prev,
    &.slick-next {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
}
