#App {
  font-family: $ft-fam-primary;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
  overflow-x: hidden;
}

.bm-menu-wrap {
  width: 320px;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: $c-grey-variant-6;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: $c-white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  width: 20px;
  height: 30px;
  left: 20px;
  top: 10px;
  color: $c-white;

  @media (min-width: $md-queries + 1px) {
    width: 20px;
    height: 30px;
    left: 36px;
    top: 10px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $c-grey-variant-1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: absolute;
  right: 18px !important;
  top: 25px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: inherit;
  color: $c-grey-variant-3;
  &.icon {
    font-size: 20px;
  }
}

/* General sidebar styles */
.bm-menu {
  padding: 0;
  font-size: 1.15em;
  width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-menu, .bm-morph-shape {
  fill: black;
  background: black;
}

/* Wrapper for item list */
.bm-item-list {
  color: $c-grey-variant-7;
}

/* Styling of overlay */
.bm-overlay {
  background: $c-grey-variant-7;
}

.icon {
  @include fontSize(20px);
}

.menu-item {
  display: flex !important;
  width: 100%;
  margin-bottom: 0;

  &.head {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    box-shadow: 0 2px 11px 0 $c-black-t10 !important;
    position: relative;
    outline-style: none;
  }

  &.redirection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 100%;
    padding: 0 25px;
    color: $c-white;
    text-decoration: none;
    background: black;
    transition: background .1s linear !important;

    &.context-management-sidebar {
      color: $c-data4-lightblue !important;
    }

    &.context-management {
      color: $c-data4-blue !important;
    }

    &.context-sustainability {
      color: $c-data4-green !important;
    }

    &.context-services {
      color: $c-data4-red !important;
    }

    &.context-requests {
      color: $c-data4-yellow !important;
    }

    &.nested {
      justify-content: space-between !important;
    }

    &.redirection-lg {
      padding-left: 50px;
    }

    &.menu-item-root {
      padding-left: 20px;
      &.open {
        border-bottom: 0;
      }
      .chevron {
        margin: 0;
      }
    }

    &.sub-menu-item {
      padding-left: 50px;
    }

    &:hover {
      background: $c-grey-variant-3;
      transition: background .1s linear !important;
    }
    &.active {
      &:not(.menu-item-root) {
        background: $c-grey-variant-3;
        transition: background .1s linear !important;
      }
    }

    .icon {
      margin-right: 20px;
    }

    .label {
      @include fontSize(14px);
      font-weight: $ft-med;
    }
  }

  &.foot {
    position: fixed;
    background: black;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    width: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .link {
      display: flex;
      width: auto;
      justify-content: center;
      align-items: center;
      height: 60px;
      border-top: 1px solid $c-grey-variant-10;
    }

    .link .label:after {
      top: -17px;
      bottom: inherit;
    }

    .link:hover .label:after {
      width: 100%;
    }

    .label {
      @include fontSize(14px);
      font-weight: $ft-med;
      margin: 0;
      color: $c-white !important;
    }
  }

  .complement {
    margin-left: 15px;
  }

  .counter {
    @include fontSize(10px);
    background-color: $c-white;
    color: $c-red-variant-1;
    border-radius: 8px;
    height: 19px;
    width: 27px;
    text-align: center;
    line-height: 1.5;
    font-weight: $ft-med;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
