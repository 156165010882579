/* BEGIN Thin */
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "Thin");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: RobotoCondensed;
	@include fontdef-woff($FontPathCondensed, $FontNameCondensed, $FontVersion, "Thin");
	font-weight: 100;
	font-style: normal;
}
/* END Thin */
