/* BEGIN Thin Italic */
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "ThinItalic");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: RobotoCondensed;
	@include fontdef-woff($FontPathCondensed, $FontNameCondensed, $FontVersion, "ThinItalic");
	font-weight: 100;
	font-style: italic;
}
/* END Thin Italic */
