@charset "UTF-8";
/* VARIABLES D4 */
/* Colors */
/* Typography */
/* Layout */
/* BEGIN Thin */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Thin/Roboto-Thin.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Thin/Roboto-Thin.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Thin/RobotoCondensed-Thin.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Thin/RobotoCondensed-Thin.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal; }

/* END Thin */
/* BEGIN Thin Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/ThinItalic/RobotoCondensed-ThinItalic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/ThinItalic/RobotoCondensed-ThinItalic.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: italic; }

/* END Thin Italic */
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Light/RobotoCondensed-Light.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Light/RobotoCondensed-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal; }

/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/LightItalic/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/LightItalic/Roboto-LightItalic.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/LightItalic/RobotoCondensed-LightItalic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/LightItalic/RobotoCondensed-LightItalic.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: italic; }

/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Regular/RobotoCondensed-Regular.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Regular/RobotoCondensed-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Regular/RobotoCondensed-Regular.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Regular/RobotoCondensed-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal; }

/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Italic/RobotoCondensed-Italic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Italic/RobotoCondensed-Italic.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Italic/RobotoCondensed-Italic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Italic/RobotoCondensed-Italic.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: italic; }

/* END Italic */
/* BEGIN Medium */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Medium/Roboto-Medium.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Medium/Roboto-Medium.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Medium/RobotoCondensed-Medium.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Medium/RobotoCondensed-Medium.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal; }

/* END Medium */
/* BEGIN Medium Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/MediumItalic/RobotoCondensed-MediumItalic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/MediumItalic/RobotoCondensed-MediumItalic.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: italic; }

/* END Medium Italic */
/* BEGIN Bold */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Bold/RobotoCondensed-Bold.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Bold/RobotoCondensed-Bold.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Bold/RobotoCondensed-Bold.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Bold/RobotoCondensed-Bold.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal; }

/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/BoldItalic/RobotoCondensed-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/BoldItalic/RobotoCondensed-BoldItalic.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/BoldItalic/RobotoCondensed-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/BoldItalic/RobotoCondensed-BoldItalic.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: italic; }

/* END Bold Italic */
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/Black/RobotoCondensed-Black.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/Black/RobotoCondensed-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal; }

/* END Black */
/* BEGIN Black Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: RobotoCondensed;
  src: url("../fonts/RobotoCondensed/BlackItalic/RobotoCondensed-BlackItalic.woff2?v=2.137") format("woff2"), url("../fonts/RobotoCondensed/BlackItalic/RobotoCondensed-BlackItalic.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: italic; }

/* END Black Italic */
html, body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400; }

h1 {
  font-family: "RobotoCondensed"; }

.ft-s-8 {
  font-size: 8px; }

.ft-s-9 {
  font-size: 9px; }

.ft-s-10 {
  font-size: 10px; }

.ft-s-11 {
  font-size: 11px; }

.ft-s-12 {
  font-size: 12px; }

.ft-s-13 {
  font-size: 13px; }

.ft-s-14 {
  font-size: 14px; }

.ft-s-15 {
  font-size: 15px; }

.ft-s-16 {
  font-size: 16px; }

.ft-s-18 {
  font-size: 18px; }

.ft-s-20 {
  font-size: 20px; }

.ft-s-21 {
  font-size: 21px; }

.ft-s-22 {
  font-size: 22px; }

.ft-s-24 {
  font-size: 24px; }

.ft-s-25 {
  font-size: 25px; }

.ft-s-26 {
  font-size: 26px; }

.ft-s-28 {
  font-size: 28px; }

.ft-s-30 {
  font-size: 30px; }

.ft-s-32 {
  font-size: 32px; }

.ft-s-35 {
  font-size: 35px; }

.ft-s-36 {
  font-size: 36px; }

.ft-s-40 {
  font-size: 40px; }

.ft-s-45 {
  font-size: 45px; }

.ft-s-50 {
  font-size: 50px; }

.ft-s-54 {
  font-size: 54px; }

.ft-s-60 {
  font-size: 60px; }

.ft-s-68 {
  font-size: 68px; }

.ft-s-76 {
  font-size: 76px; }

.ft-s-82 {
  font-size: 82px; }

.ft-s-90 {
  font-size: 90px; }

.ft-s-100 {
  font-size: 100px; }

.ft-fam-primary {
  font-family: "Roboto", sans-serif; }

.ft-fam-secondary {
  font-family: "Arial", sans-serif; }

.ft-thin {
  font-weight: 100; }

.ft-light {
  font-weight: 300; }

.ft-reg {
  font-weight: 400; }

.ft-med {
  font-weight: 500; }

.ft-sm-bold {
  font-weight: 600; }

.ft-bold {
  font-weight: 700; }

.ft-black {
  font-weight: 800; }

.ft-xblack {
  font-weight: 900; }

.ft-uppercase {
  text-transform: uppercase; }

.ft-low {
  text-transform: lowercase; }

.ft-italic {
  font-style: italic; }

.important {
  font-weight: 700; }

.text-normal {
  word-break: normal;
  overflow-wrap: normal; }

.text-ellipsis, .main-tab .body .resume, .list-collapse .list-collapse-head .list-head-label .label-group, .list-collapse .list-collapse-head .list-head-label .label-group .label-1, .list-collapse .list-collapse-head .list-head-label .label-group .label-2, .list-collapse .list-collapse-head .list-head-label .label-group .label-2 > *, .list-collapse .list-collapse-head .list-head-label .label-group .label-3 > *, .tick-round > span:last-child, .main-body-annuaire .liste-annuaire .label, .form-dropdown .single-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-pre-wrap {
  white-space: pre-wrap; }

.paper.paper-button, .nav-search .dropdown-item.selected, .list-collapse .demande-espace-associe .body table td, .forfait-actions-item .head, .form-button-group .selectable-button, .nav-tabs .nav-item .nav-link, .table-container table tbody td .primary-text, .hosting-spaces .head, .hosting-spaces table tbody td, .access-detail .head, .delivery-instructions .content, .delivery-instructions .instructions-title, .two-fa-instructions, .recurrent-remote-hands-detail .head, .login-form .password-forgotten, .kpi-env .reports-list, .kpi-env .impacts-text, .my-smart-dc-configuration .global-lifetime .title {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #595959; }

.c-background-card, .contenu, .forfait-actions-item .content, .forfait-actions-item .date, .table-container table tbody td, .access-detail .access-information {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #595959; }

.form-standard .form-title, .tickets-information, .delivery-instructions .title, .collapsable-cgu .head, .cgu-locked-navigation .cgu-locked-label {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #595959; }

.list-collapse .demande-espace-associe .body table th, .table-container table thead th, .hosting-spaces table thead th {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9a9a9a; }

.information-group .information, .table-container table .dropdown-item {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #595959; }

.form-standard .error, .form-validation .error {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ee7202; }

.form-standard .form-label, .modify-tickets .information-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #595959; }

@media (min-width: 1px) and (max-width: 991px) {
  body .d-desktop {
    display: none; }
  body .d-mobile {
    display: flex; } }

@media (min-width: 992px) {
  body .d-desktop {
    display: flex; }
  body .d-mobile {
    display: none; } }

/* VARIABLES D4 */
/* Colors */
/* Typography */
/* Layout */
button, a {
  cursor: pointer;
  outline: none !important;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none; }

.btn {
  font-size: 14px;
  border-radius: 2px;
  line-height: 20px;
  margin: 4px 0;
  padding: 0 16px;
  height: 36px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  width: auto; }
  .btn .icon + * {
    margin-left: 10px; }

.btn.btn-sm {
  height: 30px;
  padding: 0 10px; }

.btn.btn-md {
  /* normal */ }

.btn.btn-lg {
  /* TODO */ }

.btn.btn-yellow {
  color: black;
  background-color: #FFC900; }

.btn.btn-primary-v-1 {
  color: #ffffff;
  background: #008997; }

.btn.btn-secondary-v-1 {
  color: #ffffff;
  background: #008997; }

.btn.btn-secondary-v-2 {
  color: #ffffff;
  background: #008997; }

.btn.btn-tertiary {
  color: #ffffff;
  background: #595959; }
  .btn.btn-tertiary:not(:disabled):hover {
    background: #ffffff;
    color: #595959; }

.btn.btn-white-bg {
  background: #ffffff; }
  .btn.btn-white-bg:not(:disabled):hover:after {
    background: rgba(38, 38, 38, 0.01); }
  .btn.btn-white-bg.btn-primary {
    color: #262626; }
  .btn.btn-white-bg.btn-primary-v-1 {
    color: #f16c6e; }
  .btn.btn-white-bg.btn-secondary {
    color: #1178B3; }
  .btn.btn-white-bg.btn-secondary-v-1 {
    color: #2D5499; }
  .btn.btn-white-bg.btn-tertiary {
    color: #FF7777; }

.btn.btn-link {
  border: none;
  text-decoration: none;
  box-shadow: inherit;
  background: none; }
  .btn.btn-link:after {
    display: none; }
  .btn.btn-link:not(:disabled):hover {
    box-shadow: none; }
  .btn.btn-link.btn-primary {
    color: #262626; }
  .btn.btn-link.btn-primary-v-1 {
    color: #f16c6e; }
  .btn.btn-link.btn-secondary {
    color: #1178B3; }
  .btn.btn-link.btn-secondary-v-1 {
    color: #2D5499; }
  .btn.btn-link.btn-tertiary {
    color: #FF7777; }

.btn.btn-disable {
  opacity: .5;
  pointer-events: none; }

.btn.btn-uppercase {
  font-size: 12px;
  text-transform: uppercase; }

@media (min-width: 768px) and (max-width: 991px) {
  .btn.btn-bigger {
    height: 75px;
    margin: 0 auto;
    padding: 20px 30px;
    min-width: 185px;
    width: auto !important; } }

.redirection-g {
  display: inline-flex;
  width: auto;
  align-items: center;
  text-decoration: none !important;
  font-weight: 700; }
  .redirection-g .icon-r {
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .redirection-g .icon {
    font-size: 10px; }
  .redirection-g .i-arrow {
    margin-left: 2px; }

.btn.btn-tertiary, .btn.btn-tertiary:not(:disabled):hover, .nav-search, .nav-search .textfield, .nav-search .search-btn, .nav-search .search-btn .icon, .nav-search .search-btn:hover .icon, .nav-search.toggled, .nav-search.toggled .textfield, .nav-search.toggled .search-btn, .nav-search.toggled .search-btn .icon, .nav-search.toggled .search-btn:hover .icon {
  transition: all .1s ease-in-out; }

.main-breadcrumb .breadcrumb-tools button, .main-breadcrumb .breadcrumb-tools button:hover, .add-btn button, .add-btn button:hover {
  transition: all .2s ease-in-out; }

.btn > * {
  position: relative; }

.btn:hover:after {
  z-index: -1 !important;
  background: rgba(241, 108, 110, 0.65) !important; }

.btn-requests {
  background-color: #FFC900 !important;
  border: 1px solid #FFC900 !important;
  color: #262626 !important; }
  .btn-requests:hover {
    background: #ffffff !important; }

.btn-requests-outline {
  background: #ffffff !important;
  border: 1px solid #FFC900 !important;
  color: #262626 !important; }
  .btn-requests-outline:hover {
    background-color: #FFC900 !important; }

.btn-services {
  background-color: #F50500 !important;
  border: 1px solid #F50500 !important;
  color: #ffffff !important; }
  .btn-services:hover {
    background: #ffffff !important;
    color: #262626 !important; }

.btn-services-outline {
  background: #ffffff !important;
  border: 1px solid #F50500 !important;
  color: #F50500 !important; }
  .btn-services-outline:hover {
    background-color: #F50500 !important;
    color: #ffffff !important; }

.btn-sustainability {
  background-color: #00C484 !important;
  border: 1px solid #00C484 !important;
  color: #262626 !important; }
  .btn-sustainability:hover {
    background: #ffffff !important; }

.btn-sustainability-outline {
  background: #ffffff !important;
  border: 1px solid #00C484 !important;
  color: #262626 !important; }
  .btn-sustainability-outline:hover {
    background-color: #00C484 !important; }

.btn-management {
  background-color: #1211AF !important;
  border: 1px solid #1211AF !important;
  color: #ffffff; }
  .btn-management:hover {
    background: #ffffff !important;
    color: #262626 !important; }

.btn-management-outline {
  background-color: #ffffff;
  border: 1px solid #1211AF !important;
  color: #262626 !important; }
  .btn-management-outline:hover {
    background-color: #1211AF !important;
    color: #ffffff !important; }

.btn-login-panel {
  width: 277px; }

body {
  background-color: #f5f5f5; }

.main-body {
  z-index: 0;
  position: relative;
  height: auto;
  min-height: 100%;
  background-image: linear-gradient(#C7C7C700, #f5f5f5), url("/resources/img/fond-v2.jpg");
  background-position: right top;
  background-size: auto;
  background-repeat: no-repeat;
  padding-bottom: 60px; }

.main-wrapper {
  padding-top: 50px; }

.error {
  color: #ee7202; }

.important-error {
  color: #262626; }

.warning {
  color: #ee7202; }

.btn-tooltip, .pointer {
  cursor: pointer; }

.tooltip-inner {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  max-width: 300px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #595959;
  border-radius: 0.25rem; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #595959; }

.arrow-tooltip {
  background: red !important; }

.c-background-card {
  background-color: #f5f5f5;
  padding: 20px;
  box-shadow: 0 1px 2px 0 #0000001a; }
  .c-background-card + .c-background-card {
    margin-top: 10px; }

.page-container .information + .content {
  margin-top: 20px; }

.section-support-other {
  background-color: #ffffff !important;
  border: 1px solid #262626 !important;
  color: #262626 !important; }

.section-management-other {
  background-color: #1211AF !important;
  border: 1px solid #1211AF !important;
  color: #ffffff !important; }

.section-requests-other {
  background-color: #FFC900 !important;
  border: 1px solid #FFC900 !important;
  color: #262626 !important; }

.section-services-other {
  background-color: #F50500 !important;
  border: 1px solid #F50500 !important;
  color: #ffffff !important; }

.section-sustainability-other {
  background-color: #00C484 !important;
  border: 1px solid #00C484 !important;
  color: #262626 !important; }

.section-management-menu {
  color: #7F8EFF !important; }

.section-requests-menu {
  color: #FFC900 !important; }

.section-services-menu {
  color: #F50500 !important; }

.section-sustainability-menu {
  color: #00C484 !important; }

.section-support-title {
  color: #262626; }

.section-management-title {
  color: #1211AF; }

.section-requests-title {
  color: #FFC900; }

.section-services-title {
  color: #F50500; }

.section-sustainability-title {
  color: #00C484; }

.section-requests-btn button {
  border: 1px solid #FFC900 !important; }
  .section-requests-btn button:hover {
    background: #FFC900 !important; }

.section-services-btn button {
  border: 1px solid #F50500 !important; }
  .section-services-btn button:hover {
    background: #F50500 !important; }

.section-sustainability-btn button {
  border: 1px solid #00C484 !important; }
  .section-sustainability-btn button:hover {
    background: #00C484 !important; }

.section-management-btn button {
  border: 1px solid #1211AF !important; }
  .section-management-btn button:hover {
    background: #1211AF !important; }

.headline {
  font-size: 25px;
  color: #262626;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal; }
  .headline.underline {
    color: #262626; }
    .headline.underline:after {
      content: ' ';
      display: block;
      border: 1px solid black;
      width: 240px; }
  .headline-2 {
    font-size: 20px;
    color: #595959;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin-bottom: 20px; }

.title-headline {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-headline .logo {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-right: 13px;
    height: 20px;
    width: auto; }
  .title-headline .logo-dashboard {
    margin-bottom: 30px;
    margin-left: -10px;
    font-size: 35px; }

.title-advanced {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px; }
  .title-advanced:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 1px;
    width: 50px;
    background: #262626; }
  .title-advanced .label-small {
    font-size: 16px;
    font-weight: 700;
    color: #262626;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }
  .title-advanced .label-large {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #595959;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }

.paper {
  position: relative;
  background: #ffffff; }
  .paper.paper-form {
    padding: 20px; }
  .paper.paper-shadow {
    box-shadow: 0 1px 2px 0 #0000001a; }
  .paper.dashboard-shadow {
    box-shadow: 0 2px 16px 0 #0000001a; }
  .paper.paper-button {
    border: 1px solid;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    padding: 20px;
    cursor: pointer; }
  .paper.paper-button-primary {
    border: 1px solid #262626;
    color: #262626; }

.link .label {
  position: relative;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  margin-right: 5px;
  opacity: 1; }
  .link .label:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    opacity: 0;
    background: #262626;
    transition: all .3s ease; }

.link .icon {
  font-size: 22px;
  right: 0;
  position: relative;
  opacity: 1;
  transition: all .3s ease; }

.link:hover {
  text-decoration: none; }
  .link:hover .label:after {
    width: calc(100% + 20px);
    opacity: 1;
    transition: all .3s ease; }
  .link:hover .icon {
    right: 0;
    position: relative;
    transition: all .3s ease; }

.link:active .label, .link:active .icon {
  opacity: .7;
  transition: all .1s ease; }

.empty-space {
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px; }
  .empty-space .empty-space-box {
    width: 100%;
    margin: 0 auto; }
  .empty-space .icon {
    font-size: 36px;
    color: #9a9a9a;
    text-align: center;
    width: 100%;
    font-size: 50px;
    display: block; }
  .empty-space .label {
    font-size: 14px;
    color: #9a9a9a;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    margin-top: 10px; }
  .empty-space .legend {
    position: relative;
    font-size: 12px;
    color: #b2b2b2;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 25px; }
    .empty-space .legend:after {
      content: '';
      position: absolute;
      top: -15px;
      margin: 0 auto;
      display: block;
      height: 1px;
      background: #b2b2b2;
      width: 200px;
      right: 0;
      left: 0; }

.page-container .empty-space {
  background-color: #ffffff; }

.bloc-config {
  display: flex;
  height: 100%;
  border-top: 1px solid #e9ecef; }
  .bloc-config:first-child {
    border-top: none; }
  .bloc-config .box {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%; }
    .bloc-config .box .head {
      margin-bottom: 10px; }
      .bloc-config .box .head .title {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #9a9a9a;
        margin: 0; }
    .bloc-config .box .body {
      flex: 1; }
      .bloc-config .box .body .list-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px; }
        .bloc-config .box .body .list-items .legend {
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.45;
          letter-spacing: normal;
          color: #595959; }
        .bloc-config .box .body .list-items .result {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: right;
          color: #595959; }
    .bloc-config .box .foot {
      display: flex;
      justify-content: flex-end; }

.main-tab {
  display: flex;
  flex-direction: column; }
  .main-tab .head.nav.nav-tabs {
    height: 0;
    min-height: 35px;
    display: flex;
    align-items: center;
    border: none;
    flex-wrap: nowrap; }
    .main-tab .head.nav.nav-tabs .nav-item {
      margin: 0;
      height: 100%; }
    .main-tab .head.nav.nav-tabs .nav-link {
      display: flex;
      align-items: center;
      border-radius: 8px 8px 0 0;
      background-color: #424242;
      border: none;
      height: 100%;
      margin: 0;
      text-transform: none;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
      .main-tab .head.nav.nav-tabs .nav-link:hover {
        color: #9a9a9a; }
      .main-tab .head.nav.nav-tabs .nav-link.active {
        font-weight: 500; }
        .main-tab .head.nav.nav-tabs .nav-link.active:hover {
          color: #e9ecef; }
    .main-tab .head.nav.nav-tabs + .tab-content {
      margin-top: 0; }
  .main-tab .body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .main-tab .body .table thead th {
      border: inherit;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      color: #ffffff;
      background-color: #4C4C4CCC; }
      @media (max-width: 991px) {
        .main-tab .body .table thead th {
          padding-left: 10px; } }
      .main-tab .body .table thead th:first-child {
        width: 10%;
        padding-left: 10px; }
      .main-tab .body .table thead th:last-child {
        width: 20%;
        padding-right: 10px; }
        @media (max-width: 991px) {
          .main-tab .body .table thead th:last-child {
            width: 60%; } }
    .main-tab .body .table thead td {
      border-color: #dcdcdc; }
    .main-tab .body .table th,
    .main-tab .body .table td {
      padding: 0;
      vertical-align: middle; }
    .main-tab .body .table td {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 50px;
      letter-spacing: normal;
      color: #595959; }
      .main-tab .body .table td .icon-type-demande {
        font-size: 20px;
        margin-right: 10px;
        color: #1514ED;
        font-weight: 500;
        border-radius: 50%;
        background-color: #F2F5FF; }
    .main-tab .body .table tbody tr {
      cursor: pointer; }
      .main-tab .body .table tbody tr:hover {
        background-color: #ffffff; }
    .main-tab .body .table tbody td:first-child {
      padding-left: 10px; }
    .main-tab .body .table tbody td:last-child {
      padding-right: 10px; }
    .main-tab .body .resume-mobile {
      line-height: 20px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      white-space: normal; }
    .main-tab .body .resume {
      padding-left: 10px; }
    .main-tab .body .code-ref {
      color: #9a9a9a;
      font-size: 12px;
      font-weight: 400; }
  .main-tab .foot {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    min-height: 64px;
    border-top: 1px solid #e9ecef;
    flex-wrap: wrap; }
  .main-tab .foot-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; }
    @media (max-width: 991px) {
      .main-tab .foot-right {
        flex-direction: column; }
        .main-tab .foot-right .dropdown-select button {
          width: 100%; }
        .main-tab .foot-right button.link {
          text-align: right;
          margin-top: 10px; } }
  .main-tab .criticite {
    position: relative; }
    .main-tab .criticite.urgent:before {
      background-color: #F50500; }
    .main-tab .criticite.standard:before {
      background-color: #FFC900; }
    .main-tab .criticite.other:before {
      background-color: #ee7202; }
    .main-tab .criticite:before {
      position: absolute;
      width: 3px;
      border-radius: 4px;
      height: 25px;
      top: 30%;
      content: ""; }
      @media (max-width: 991px) {
        .main-tab .criticite:before {
          height: 60%;
          top: 20%; } }

.dcim-dashboard {
  display: flex;
  height: 100%; }
  .dcim-dashboard .box {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .dcim-dashboard .kpi-charts {
    flex: 1;
    min-height: 300px; }
  .dcim-dashboard .mysmart-dc {
    border-top: 0px solid #e9ecef;
    margin: 10px;
    padding: 10px; }
    .dcim-dashboard .mysmart-dc .body {
      display: flex;
      flex-direction: row; }
      .dcim-dashboard .mysmart-dc .body .icon {
        font-size: 52px;
        text-align: center;
        width: auto;
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #e9ecef; }
      .dcim-dashboard .mysmart-dc .body .ring {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #595959;
        border-radius: 50px;
        padding: 10px;
        height: 60px;
        width: 60px; }
      .dcim-dashboard .mysmart-dc .body .wording {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center; }
        .dcim-dashboard .mysmart-dc .body .wording .label {
          font-size: 16px;
          color: #595959;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          margin-bottom: 5px; }
          .dcim-dashboard .mysmart-dc .body .wording .label .important {
            color: #262626;
            text-transform: inherit; }
        .dcim-dashboard .mysmart-dc .body .wording .legend {
          position: relative;
          font-size: 14px;
          color: #595959;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left; }
    .dcim-dashboard .mysmart-dc .foot {
      display: flex;
      justify-content: flex-end; }

#nav {
  z-index: 999; }
  @media (min-width: 992px) {
    #nav > * {
      padding: 0 55px; } }

.footer {
  height: 45px;
  background: black;
  width: 100%;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.main-navigation {
  width: 100%;
  background: black;
  color: #ffffff;
  height: 56px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  box-shadow: 0 2px 4px #0000001a; }
  .main-navigation .menu-link {
    cursor: pointer; }
  .main-navigation .nav-left {
    display: flex;
    flex-direction: row; }
    .main-navigation .nav-left .logo-label {
      font-size: 16px;
      text-transform: uppercase; }
    @media (min-width: 991px) {
      .main-navigation .nav-left .logo-desktop {
        display: flex; }
      .main-navigation .nav-left .logo-mobile {
        display: none; } }
    @media (max-width: 991px) {
      .main-navigation .nav-left .logo-desktop {
        display: none; }
      .main-navigation .nav-left .logo-mobile {
        display: flex; } }
  .main-navigation .nav-right {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end; }
  .main-navigation .nav-link {
    padding: 0 2rem;
    display: flex;
    align-items: center; }
    .main-navigation .nav-link:hover, .main-navigation .nav-link:focus, .main-navigation .nav-link:active {
      color: #ffffff; }

.nav-tools {
  display: flex;
  align-items: center; }
  .nav-tools .nav-item + .nav-item {
    margin: 0 0 0 15px; }
  .nav-tools .nav-item .btn.btn-icon {
    padding: 0 !important; }
    .nav-tools .nav-item .btn.btn-icon .icon {
      padding: 5px;
      margin: 0 !important; }

.nav-search {
  display: flex;
  position: relative;
  height: 30px;
  opacity: 1; }
  .nav-search .textfield {
    border: none;
    outline: none;
    box-shadow: inherit;
    border-radius: 8px 0 0 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 20px;
    width: 145px;
    background: #595959;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    text-overflow: ellipsis; }
  .nav-search input::placeholder {
    color: #ffffff;
    opacity: .75; }
  .nav-search .search-btn {
    background: #595959;
    border-radius: 0 8px 8px 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px; }
    .nav-search .search-btn .icon {
      color: #ffffff;
      opacity: .5; }
    .nav-search .search-btn:hover .icon {
      opacity: 1; }
  .nav-search .dropdown {
    z-index: 12;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    padding: 8px 0;
    background: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 3px;
    box-shadow: 0 2px 4px #0000001a; }
  .nav-search .dropdown-item {
    color: #595959;
    cursor: pointer; }
    .nav-search .dropdown-item:active {
      color: #ffffff; }
  .nav-search.toggled {
    opacity: 1; }
    .nav-search.toggled .textfield {
      width: 330px;
      color: #595959;
      background: #ffffff; }
    .nav-search.toggled .search-btn {
      background: #ffffff; }
      .nav-search.toggled .search-btn .icon {
        color: #595959;
        opacity: .5; }
      .nav-search.toggled .search-btn:hover .icon {
        opacity: 1; }

.nav-notification {
  position: relative; }
  .nav-notification .btn {
    min-width: inherit;
    border: none;
    background-color: #ffffff;
    color: #262626;
    border-radius: 8px; }
  .nav-notification .btn[aria-expanded="true"] {
    background: #ffffff !important; }
    .nav-notification .btn[aria-expanded="true"] .icon {
      color: #262626; }
  .nav-notification .dropdown-menu {
    width: 200px;
    top: 3px !important;
    padding: 0;
    background: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    box-shadow: 0 2px 4px #0000001a; }
  .nav-notification .dropdown-item {
    padding: 10px;
    border-bottom: 1px solid #e9ecef;
    white-space: normal;
    line-height: 1.43; }
    .nav-notification .dropdown-item:not(:active):not(.active) {
      color: #595959; }
    .nav-notification .dropdown-item:last-child {
      border: none; }
  .nav-notification .btn[aria-expanded="true"]:hover:after {
    display: none !important;
    background: none !important; }
  .nav-notification.active .badge::before {
    content: '';
    display: block;
    z-index: 10;
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 10px;
    right: 2px;
    top: 2px;
    background: #ffffff; }
  .nav-notification.active.show .badge::before, .nav-notification.active:not(:active):hover .badge::before {
    background: #262626; }
  .nav-notification .notification-item {
    word-break: break-word; }
  .nav-notification .not-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    color: #9a9a9a; }
    .nav-notification .not-notification .icon {
      font-size: 50px; }
    .nav-notification .not-notification span:last-child {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      margin-top: 10px; }

.nav-help.btn {
  min-width: inherit; }

.nav-account .btn {
  background-color: #262626;
  color: #ffffff;
  border: 1px solid #ffffff; }
  .nav-account .btn .user-name {
    font-weight: 500; }
  .nav-account .btn .icon {
    transform: rotate(90deg); }
  .nav-account .btn .icon-appbar-utilisateur {
    transform: rotate(0deg);
    margin: 0 !important; }

.nav-account .btn[aria-expanded="true"] {
  background: #ffffff;
  color: #262626; }
  .nav-account .btn[aria-expanded="true"] .icon {
    color: #262626; }

.nav-account .dropdown-menu {
  top: 3px !important;
  left: inherit !important;
  right: 0 !important;
  padding: 8px 0;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 3px;
  box-shadow: 0 2px 4px #0000001a; }

.nav-account .dropdown-item {
  color: #595959;
  padding: .3rem 1.3rem; }
  .nav-account .dropdown-item:active {
    color: #ffffff;
    background-color: #595959; }

.nav-account.show .btn:hover:after {
  display: none !important;
  background: none !important; }

.search-field-mobile {
  height: 80px;
  padding: 0 20px; }
  .search-field-mobile form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; }
    .search-field-mobile form .form-input {
      margin: auto 0;
      border-radius: 8px 0 0 8px;
      background: #595959;
      text-overflow: ellipsis; }
  .search-field-mobile .form-control {
    height: 44px;
    border-radius: 2px;
    padding: 10px 20px;
    font-size: 14px;
    border: none !important; }
  .search-field-mobile .group-form-control {
    flex: 1; }
  .search-field-mobile .search-btn {
    cursor: pointer;
    background: #595959;
    border-radius: 0 8px 8px 0;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px; }
    .search-field-mobile .search-btn .icon {
      color: #f5f5f5; }

@media (min-width: 1px) and (max-width: 991px) {
  .dropdown-item {
    line-height: 2 !important;
    white-space: normal; } }

#App {
  font-family: "Roboto", sans-serif;
  /* Give app full page to work with */
  height: 100vh; }

#page-wrap {
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
  overflow-x: hidden; }

.bm-menu-wrap {
  width: 320px; }

/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #9a9a9a;
  transition: color 0.2s; }

/* Change color on hover */
.bm-item:hover {
  color: #ffffff; }

/* The rest copied directly from react-burger-menu docs */
/* Position and sizing of burger button */
.bm-burger-button {
  width: 20px;
  height: 30px;
  left: 20px;
  top: 10px;
  color: #ffffff; }
  @media (min-width: 992px) {
    .bm-burger-button {
      width: 20px;
      height: 30px;
      left: 36px;
      top: 10px; } }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: absolute;
  right: 18px !important;
  top: 25px !important; }

/* Color/shape of close button cross */
.bm-cross {
  background: inherit;
  color: #595959; }
  .bm-cross.icon {
    font-size: 20px; }

/* General sidebar styles */
.bm-menu {
  padding: 0;
  font-size: 1.15em;
  width: 100%; }

/* Morph shape necessary with bubble or elastic */
.bm-menu, .bm-morph-shape {
  fill: black;
  background: black; }

/* Wrapper for item list */
.bm-item-list {
  color: #b2b2b2; }

/* Styling of overlay */
.bm-overlay {
  background: #b2b2b2; }

.icon {
  font-size: 20px; }

.menu-item {
  display: flex !important;
  width: 100%;
  margin-bottom: 0; }
  .menu-item.head {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    box-shadow: 0 2px 11px 0 #0000001a !important;
    position: relative;
    outline-style: none; }
  .menu-item.redirection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 100%;
    padding: 0 25px;
    color: #ffffff;
    text-decoration: none;
    background: black;
    transition: background .1s linear !important; }
    .menu-item.redirection.context-management-sidebar {
      color: #7F8EFF !important; }
    .menu-item.redirection.context-management {
      color: #1211AF !important; }
    .menu-item.redirection.context-sustainability {
      color: #00C484 !important; }
    .menu-item.redirection.context-services {
      color: #F50500 !important; }
    .menu-item.redirection.context-requests {
      color: #FFC900 !important; }
    .menu-item.redirection.nested {
      justify-content: space-between !important; }
    .menu-item.redirection.redirection-lg {
      padding-left: 50px; }
    .menu-item.redirection.menu-item-root {
      padding-left: 20px; }
      .menu-item.redirection.menu-item-root.open {
        border-bottom: 0; }
      .menu-item.redirection.menu-item-root .chevron {
        margin: 0; }
    .menu-item.redirection.sub-menu-item {
      padding-left: 50px; }
    .menu-item.redirection:hover {
      background: #595959;
      transition: background .1s linear !important; }
    .menu-item.redirection.active:not(.menu-item-root) {
      background: #595959;
      transition: background .1s linear !important; }
    .menu-item.redirection .icon {
      margin-right: 20px; }
    .menu-item.redirection .label {
      font-size: 14px;
      font-weight: 500; }
  .menu-item.foot {
    position: fixed;
    background: black;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    width: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .menu-item.foot .link {
      display: flex;
      width: auto;
      justify-content: center;
      align-items: center;
      height: 60px;
      border-top: 1px solid #e9ecef; }
    .menu-item.foot .link .label:after {
      top: -17px;
      bottom: inherit; }
    .menu-item.foot .link:hover .label:after {
      width: 100%; }
    .menu-item.foot .label {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      color: #ffffff !important; }
  .menu-item .complement {
    margin-left: 15px; }
  .menu-item .counter {
    font-size: 10px;
    background-color: #ffffff;
    color: #eb2629;
    border-radius: 8px;
    height: 19px;
    width: 27px;
    text-align: center;
    line-height: 1.5;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center; }

.main-breadcrumb {
  background: #ffffff;
  height: 30px;
  box-shadow: 0 10px 10px 0 #e9ecef; }
  .main-breadcrumb .breadcrumb {
    background: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    height: 30px; }
    .main-breadcrumb .breadcrumb ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0; }
    .main-breadcrumb .breadcrumb li {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0; }
    .main-breadcrumb .breadcrumb a {
      color: #595959;
      text-decoration: none;
      font-size: 13px; }
    .main-breadcrumb .breadcrumb li:last-child a {
      color: #b2b2b2;
      cursor: default;
      pointer-events: none; }
    .main-breadcrumb .breadcrumb .icon {
      font-size: 16px; }
  .main-breadcrumb .breadcrumb-tools {
    z-index: 11;
    position: absolute;
    right: 15px;
    background: none;
    display: flex;
    flex-direction: row;
    padding: 10px 0 0 0;
    margin: 0;
    height: auto; }
    .main-breadcrumb .breadcrumb-tools .dropdown-menu {
      min-width: 320px; }
    .main-breadcrumb .breadcrumb-tools button {
      border-radius: 50px;
      height: 40px;
      width: 40px;
      background: #ffffff;
      box-shadow: 0 1px 3px 1px #0000001a;
      margin-left: 20px;
      margin-top: 0;
      margin-bottom: 0;
      color: #595959; }
      .main-breadcrumb .breadcrumb-tools button.active {
        color: #262626; }
      .main-breadcrumb .breadcrumb-tools button:hover {
        background: #262626;
        color: #ffffff; }
      .main-breadcrumb .breadcrumb-tools button .icon {
        font-size: 22px; }

.cgu-bar {
  padding: 0 20px;
  display: flex;
  min-height: 30px;
  color: #ffffff;
  background: #595959;
  font-family: "Roboto", sans-serif;
  font-size: 14px; }
  .cgu-bar .text-cgu, .cgu-bar .btn-cgu {
    display: flex;
    align-items: center; }
  .cgu-bar .text-cgu {
    flex: 1; }
  .cgu-bar .btn-cgu {
    font-size: 12px;
    justify-content: flex-end;
    align-items: center;
    display: flex; }
    .cgu-bar .btn-cgu .icon {
      font-size: 20px; }
    .cgu-bar .btn-cgu.link {
      color: #ffffff; }
      .cgu-bar .btn-cgu.link:hover .label:after {
        opacity: 0; }
  @media (max-width: 575px) {
    .cgu-bar {
      padding: 0 20px;
      flex-direction: column; }
      .cgu-bar > * {
        margin-top: 10px; }
        .cgu-bar > *:last-child {
          margin-bottom: 10px; } }

.modal {
  display: flex !important;
  align-items: center !important; }
  .modal .modal-dialog.modal-xl {
    max-width: 780px; }
    .modal .modal-dialog.modal-xl .modal-content {
      width: 780px;
      line-height: 1.43; }
  .modal .modal-content {
    border: none;
    border-radius: 0; }
  .modal .modal-header {
    background: #262626;
    color: #ffffff;
    height: 60px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0;
    padding: 20px; }
    .modal .modal-header .modal-title {
      font-size: 14px;
      font-weight: 700; }
    .modal .modal-header .icon {
      cursor: pointer; }
  .modal .modal-body {
    padding: 20px; }
  .modal .modal-footer {
    border: none;
    border-radius: 0;
    padding: 0 20px 20px; }
    .modal .modal-footer > :not(:first-child) {
      margin-left: 20px; }
    .modal .modal-footer > :not(:last-child) {
      margin-right: 0; }
    .modal .modal-footer > .left {
      flex: 1; }

.modal .modal-dialog.modal-xl .modal-content {
  width: 100%; }

@media (max-width: 767px) {
  .modal-dialog, .modal-content {
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important; } }

@font-face {
  font-family: "data4-webfont";
  src: url("../fonts/icons/data4-webfont.eot?wgvs20");
  src: url("../fonts/icons/data4-webfont.eot?wgvs20#iefix") format("embedded-opentype"), url("../fonts/icons/data4-webfont.ttf?wgvs20") format("truetype"), url("../fonts/icons/data4-webfont.woff?wgvs20") format("woff"), url("../fonts/icons/data4-webfont.svg?wgvs20#data4-webfont") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "data4-webfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-menu-ticket:before {
  content: ""; }

.icon-acces-badge:before {
  content: ""; }

.icon-acces-cle:before {
  content: ""; }

.icon-acces-digicode:before {
  content: ""; }

.icon-acces-non-gere:before {
  content: ""; }

.icon-appbar-aide:before {
  content: ""; }

.icon-appbar-menu:before {
  content: ""; }

.icon-appbar-notifications:before {
  content: ""; }

.icon-appbar-pictogramme:before {
  content: ""; }

.icon-appbar-rechercher:before {
  content: ""; }

.icon-appbar-utilisateur:before {
  content: ""; }

.icon-demande-acces:before {
  content: ""; }

.icon-demande-multiple:before {
  content: ""; }

.icon-demande-courant-faible:before {
  content: ""; }

.icon-demande-courant-fort:before {
  content: ""; }

.icon-demande-geste-de-proximite:before {
  content: ""; }

.icon-demande-incident:before {
  content: ""; }

.icon-demande-livraison:before {
  content: ""; }

.icon-demande-revoquer-acces:before {
  content: ""; }

.icon-demande-autre:before {
  content: ""; }

.icon-icon-acces-biometrie:before {
  content: ""; }

.icon-menu-consignes-livraison:before {
  content: ""; }

.icon-menu-demandes:before {
  content: ""; }

.icon-menu-espace-documentaire:before {
  content: ""; }

.icon-menu-liste-acces-en-cours:before {
  content: ""; }

.icon-menu-salles:before {
  content: ""; }

.icon-menu-societes:before {
  content: ""; }

.icon-menu-tableau-de-bord:before {
  content: ""; }

.icon-menu-utilisateurs:before {
  content: ""; }

.icon-navigation-ajouter:before {
  content: ""; }

.icon-navigation-alerte:before {
  content: ""; }

.icon-navigation-confidentiel:before {
  content: ""; }

.icon-navigation-editer:before {
  content: ""; }

.icon-navigation-equipe:before {
  content: ""; }

.icon-navigation-fermer:before {
  content: ""; }

.icon-navigation-filtrer:before {
  content: ""; }

.icon-navigation-fleche-bas:before {
  content: ""; }

.icon-navigation-fleche-droite:before {
  content: ""; }

.icon-navigation-fleche-gauche:before {
  content: ""; }

.icon-navigation-fleche-haut:before {
  content: ""; }

.icon-navigation-graphique-batons:before {
  content: ""; }

.icon-navigation-ko:before {
  content: ""; }

.icon-navigation-localisation:before {
  content: ""; }

.icon-navigation-ok:before {
  content: ""; }

.icon-navigation-perte-connexion:before {
  content: ""; }

.icon-navigation-planification:before {
  content: ""; }

.icon-navigation-plus:before {
  content: ""; }

.icon-navigation-statut:before {
  content: ""; }

.icon-navigation-telecharger:before {
  content: ""; }

.icon-navigation-valide:before {
  content: ""; }

.icon-page-demande:before {
  content: ""; }

.icon-page-lien:before {
  content: ""; }

.icon-page-serveur:before {
  content: ""; }

.icon-navigation-carnet-d-adresses:before {
  content: ""; }

.icon-page-tickets:before {
  content: ""; }

.icon-page-2factors:before {
  content: ""; }

.icon-menu-planification:before {
  content: ""; }

.icon-navigation-temps:before {
  content: ""; }

.icon-menu-kpi-environementaux:before {
  content: ""; }

.icon-navigation-information:before {
  content: ""; }

.icon-kpi-co2:before {
  content: ""; }

.icon-kpi-eau:before {
  content: ""; }

.icon-kpi-energie:before {
  content: ""; }

.icon-kpi-ressources:before {
  content: ""; }

.icon-kpi-eutrophisation:before {
  content: ""; }

.icon-page-crossconnect:before {
  content: ""; }

.icon-menu-proximite:before {
  content: ""; }

.icon-menu-kpi:before {
  content: ""; }

.icon-menu-rapportparzone:before {
  content: ""; }

.icon-menu-certificat:before {
  content: ""; }

.icon-menu-carbone:before {
  content: ""; }

.icon-chevron:before {
  content: ""; }

.icon-chevron-support:before {
  content: ""; }

.icon-drag-and-drop:before {
  content: ""; }

.icon-visibility:before {
  content: ""; }

.icon-visibility-off:before {
  content: ""; }

.icon-escort:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-power-usage:before {
  content: ""; }

.icon-water-usage:before {
  content: ""; }

.alert-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  padding: 10px 20px;
  background-color: #595959;
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: "Roboto", sans-serif; }
  .alert-msg .head {
    display: flex;
    flex-direction: row; }
  .alert-msg .important {
    font-weight: 600;
    margin-right: 10px; }
  .alert-msg .text {
    font-size: 14px;
    display: inline;
    margin-bottom: 0; }
  .alert-msg .btn {
    font-size: 12px;
    text-transform: uppercase;
    color: #ffffff; }
  @media (min-width: 992px) {
    .alert-msg {
      flex-direction: row; }
      .alert-msg .head {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between; }
      .alert-msg .text {
        display: flex;
        flex: 1; } }

.stepper-desk {
  font-family: "Roboto", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 15px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 #0000001a; }
  .stepper-desk .multi-steps > li.is-active:before, .stepper-desk .multi-steps > li.is-active ~ li:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700; }
  .stepper-desk .multi-steps > li.is-active:after, .stepper-desk .multi-steps > li.is-active ~ li:after {
    background-color: #cdcdcd; }
  .stepper-desk .multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-bottom: 25px; }
    .stepper-desk .multi-steps > li {
      counter-increment: stepNum;
      text-align: center;
      display: table-cell;
      position: relative;
      color: #595959;
      font-size: 12px;
      font-weight: 700; }
      .stepper-desk .multi-steps > li:before {
        content: '\f00c';
        content: '\2713;';
        content: '\10003';
        content: '\10004';
        content: '\2713';
        display: block;
        margin: 0 auto 12px;
        background-color: #262626;
        color: #ffffff;
        width: 30px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        font-weight: bold;
        z-index: 2;
        position: relative;
        border-width: 2px;
        border-style: solid;
        border-color: #262626;
        border-radius: 50%; }
      .stepper-desk .multi-steps > li:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #262626;
        position: absolute;
        top: 16px;
        left: 50%;
        z-index: 0; }
      .stepper-desk .multi-steps > li:last-child:after {
        display: none; }
      .stepper-desk .multi-steps > li.is-active:before {
        background-color: #262626;
        border-color: #262626; }
      .stepper-desk .multi-steps > li.is-active ~ li {
        color: #808080; }
        .stepper-desk .multi-steps > li.is-active ~ li:before {
          background-color: #cdcdcd;
          border-color: #cdcdcd; }

.stepper-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px; }
  .stepper-mobile .step-number {
    background-color: #262626;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    min-width: 50px;
    border-radius: 100px;
    margin-right: 10px; }

.champ-obligatoire {
  margin-bottom: 20px; }
  .champ-obligatoire .label {
    font-size: 13px;
    font-weight: 400;
    color: #9a9a9a;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal; }

.form-ctas {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline; }

@media (max-width: 992px) {
  .form-ctas {
    display: flex;
    justify-content: center;
    align-items: unset;
    flex-direction: column; } }

.form-standard + .form-standard {
  margin-top: 20px; }

.form-standard .form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px; }
  .form-standard .form-group .form-group {
    margin-bottom: 0 !important; }
  .form-standard .form-group + .form-title {
    margin-top: 20px; }
  .form-standard .form-group:last-child {
    margin-bottom: 0; }
  .form-standard .form-group.active .form-control {
    border-color: #262626;
    box-shadow: 0px 3px 0px -1px #f5f5f5, 0px 5px 0px -1px #00000003; }
  .form-standard .form-group.error .form-control {
    border-color: #ee7202; }
  .form-standard .form-group.error .error {
    color: #ee7202; }

.form-standard .form-label {
  min-width: 250px;
  margin-bottom: 0; }

.form-standard .form-title {
  min-width: 250px;
  margin-bottom: 10px; }

.form-standard .form-control {
  font-size: 14px;
  min-height: 30px;
  border-radius: 2px;
  border: 1px solid #dcdcdc;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 4px;
  margin-bottom: 4px; }
  .form-standard .form-control:focus {
    border-color: #262626;
    box-shadow: 0px 3px 0px -1px #f5f5f5, 0px 5px 0px -1px #00000003; }

.form-standard .group-form-control {
  display: flex;
  flex-direction: column; }
  .form-standard .group-form-control .error {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    font-size: 12px;
    margin-left: 0;
    margin-top: 5px; }

.form-standard .form-input {
  display: flex;
  align-items: flex-start; }
  .form-standard .form-input .form-label {
    margin-top: 15px; }
  .form-standard .form-input .form-timezone {
    margin-left: 15px;
    margin-top: 15px; }
    .form-standard .form-input .form-timezone .btn-tooltip {
      margin-left: 15px; }

.form-standard .form-text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .form-standard .form-text-area > * {
    width: 100%; }
  .form-standard .form-text-area .form-label {
    margin-bottom: 5px; }
  .form-standard .form-text-area .form-control {
    min-height: 120px; }

.form-standard .form-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .form-standard .form-checkbox > * {
    width: auto; }
  .form-standard .form-checkbox .form-label {
    margin-bottom: 5px; }
  .form-standard .form-checkbox.form-group.form-xs .pretty {
    width: 100px; }
  .form-standard .form-checkbox.form-group.form-sm .pretty {
    width: 150px; }
  .form-standard .form-checkbox.form-group.form-md .pretty {
    width: 250px; }
  .form-standard .form-checkbox.form-group.form-lg .pretty {
    width: 500px; }
  .form-standard .form-checkbox .label {
    font-size: 14px; }

.form-standard .form-radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .form-standard .form-radio > * {
    width: auto; }
  .form-standard .form-radio .form-label {
    margin-bottom: 5px; }
  .form-standard .form-radio .form-group.form-xs .pretty {
    width: 100px; }
  .form-standard .form-radio .form-group.form-sm .pretty {
    width: 150px; }
  .form-standard .form-radio .form-group.form-md .pretty {
    width: 250px; }
  .form-standard .form-radio .form-group.form-lg .pretty {
    width: 500px; }
  .form-standard .form-radio .label {
    font-size: 14px; }

.form-standard .form-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .form-standard .form-data > * {
    width: auto; }
  .form-standard .form-data .form-label {
    margin-bottom: 10px; }
  .form-standard .form-data .form-control-file {
    font-size: 14px; }

.form-standard .form-file {
  cursor: pointer;
  border: 1px solid #b2b2b2;
  padding: 24px 41px;
  flex-grow: 1;
  background-color: #ffffff;
  margin-bottom: 10px;
  width: 100%; }
  .form-standard .form-file .icon {
    font-size: 40px;
    margin-right: 25px; }
  .form-standard .form-file:active {
    background-color: #e9ecef; }

.form-standard .form-file-disabled {
  background-color: #e9ecef;
  cursor: not-allowed; }

.form-standard .form-file-dragover-requests {
  border: 1px solid #FFC900; }

.form-standard .form-file-dragover-services {
  border: 1px solid #F50500; }

.form-standard .form-file-dragover-sustainability {
  border: 1px solid #00C484; }

.form-standard .form-file-dragover-management {
  border: 1px solid #1211AF; }

.form-standard .form-file-dragover-support {
  border: 1px solid #262626; }

.form-standard .form-xs .form-control, .form-standard .form-xs .error {
  width: 100px; }

.form-standard .form-sm .form-control, .form-standard .form-sm .error {
  width: 150px; }

.form-standard .form-md .form-control, .form-standard .form-md .error {
  width: 250px; }

.form-standard .form-lg .form-control, .form-standard .form-lg .error {
  width: 500px; }

.form-standard .form-full .form-control, .form-standard .form-full .error {
  width: 100%; }

.form-standard .form-row {
  display: flex;
  align-items: center; }
  .form-standard .form-row > .col, .form-standard .form-row [class*="col-"] {
    padding-right: 0; }
  .form-standard .form-row .delimiter-label {
    float: left; }
    .form-standard .form-row .delimiter-label .form-label {
      min-width: 30px;
      margin-left: 15px;
      margin-top: 15px; }

@media (max-width: 992px) {
  .form-standard .form-group {
    flex-direction: column; }
    .form-standard .form-group > * {
      width: 100%; }
    .form-standard .form-group .form-label {
      margin-top: 0; }
    .form-standard .form-group .form-control, .form-standard .form-group .error {
      width: 100%; }
    .form-standard .form-group .form-input {
      display: inline; }
    .form-standard .form-group .form-timezone {
      margin-left: 0;
      margin-top: 10px; }
  .form-standard .form-radio .form-group > * {
    width: 100% !important; }
  .form-standard .form-file {
    margin-bottom: 10px; } }

@media (max-width: 768px) {
  .form-standard .form-row {
    flex-direction: column; }
    .form-standard .form-row .delimiter-label .form-label {
      margin-left: 0; } }

.nouveau-visiteur {
  position: relative; }
  .nouveau-visiteur .title {
    margin-bottom: 15px; }
  .nouveau-visiteur .form-input .form-label {
    font-weight: 400; }
  .nouveau-visiteur .contenu {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #595959; }
  .nouveau-visiteur .position-corner-right {
    position: absolute;
    top: -10px;
    right: 0; }
  .nouveau-visiteur .link {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem; }

.editer-visiteur {
  position: relative; }
  .editer-visiteur .title {
    margin-bottom: 15px; }
  .editer-visiteur .form-input .form-label {
    font-weight: 400; }
  .editer-visiteur .position-corner-right {
    position: absolute;
    top: 0;
    right: 0; }
  .editer-visiteur .link .icon {
    display: none !important; }
  .editer-visiteur .link:hover .label:after {
    width: 100%; }

.paper > .collapsable-element > .collapse-action {
  padding: 20px; }

.collapsable-element .collapse-action {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  min-height: 64px;
  min-width: inherit;
  max-width: inherit;
  background: none !important;
  color: #595959 !important;
  cursor: pointer; }
  .collapsable-element .collapse-action:after {
    display: none; }

.collapsable-element .chevron {
  display: flex;
  align-items: center; }
  .collapsable-element .chevron .icon {
    height: 20px;
    width: 20px; }
  .collapsable-element .chevron + .titre {
    margin-left: 20px; }

.collapsable-element .titre {
  flex: 1;
  height: auto;
  display: flex;
  align-items: center; }
  .collapsable-element .titre .icon {
    height: 20px;
    width: 20px;
    color: #04a583; }
    .collapsable-element .titre .icon + .text {
      margin-left: 10px; }
  .collapsable-element .titre .text {
    white-space: pre-wrap;
    margin: 0; }

.collapsable-element .error .titre {
  color: #ee7202; }
  .collapsable-element .error .titre > * {
    color: #ee7202; }

.collapsable-element .body .contenu {
  margin-bottom: 10px; }

.collapsable-element .body .group-input {
  display: flex;
  flex-direction: row; }

.contenu-important {
  color: #262626; }

.form-validation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }
  .form-validation > * + * {
    margin-left: 15px; }
  .form-validation > .left {
    flex: 1; }

.blocCharts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin: 0 -20px; }
  .blocCharts > * {
    width: 140px;
    height: 185px; }

.background-icon {
  position: fixed;
  bottom: 20px;
  pointer-events: none;
  z-index: -1; }
  .background-icon .icon {
    font-size: 300px;
    color: rgba(89, 89, 89, 0.1); }

@media (max-width: 575px) {
  .background-icon {
    display: none; } }

@media (min-width: 576px) {
  .background-icon {
    right: calc((100vw - 540px) / 2 - 100px); } }

@media (min-width: 768px) {
  .background-icon {
    right: calc((100vw - 720px) / 2 - 100px); } }

@media (min-width: 992px) {
  .background-icon {
    right: calc((100vw - 960px) / 2 - 100px); } }

@media (min-width: 1200px) {
  .background-icon {
    right: calc((100vw - 1140px) / 2 - 100px); } }

.list-separator {
  background: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; }
  .list-separator h6 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
    margin: 10px 0;
    background: #f5f5f5;
    height: 26px;
    z-index: 1;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #595959; }
  .list-separator hr {
    position: absolute;
    top: 23px;
    margin-top: inherit;
    margin-bottom: inherit;
    border: inherit;
    border-top: inherit;
    height: 2px;
    width: 100%;
    background: #e9ecef; }

.load-more-container {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .load-more-container .load-more {
    position: relative;
    right: 0;
    top: 0; }

.align-items-horizontally {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.align-items-vertically {
  display: flex;
  flex-direction: column;
  line-height: 20px; }

.add-btn .dropdown-menu {
  min-width: 320px; }

.add-btn button {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  background: #ffffff;
  box-shadow: 0 1px 3px 1px #0000001a;
  color: #424242; }
  .add-btn button:hover {
    background: #424242;
    color: #ffffff; }
  .add-btn button .icon {
    font-size: 22px; }

.synthese-liste-title {
  margin-bottom: 15px; }

.synthese-liste {
  border-collapse: collapse;
  display: flex;
  flex-direction: column; }
  .synthese-liste thead {
    background-color: #ffffff !important;
    display: flex;
    flex-direction: row; }
    .synthese-liste thead tr {
      display: flex;
      flex: 1; }
      .synthese-liste thead tr th {
        display: flex;
        border: none !important;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #262626 !important; }
        .synthese-liste thead tr th .synthese-liste-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: 400; }
          .synthese-liste thead tr th .synthese-liste-header .icon-type-demande {
            font-size: 20px;
            padding: 5px;
            color: #1514ED;
            font-weight: 500;
            border-radius: 50%;
            background-color: #F2F5FF; }
        .synthese-liste thead tr th .synthese-liste-header-total {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1; }
  .synthese-liste tbody {
    display: flex;
    flex-direction: column;
    border: none !important; }
    .synthese-liste tbody tr {
      display: flex;
      flex-direction: row;
      background-color: #ffffff !important; }
      .synthese-liste tbody tr:first-child td {
        border-top: none !important; }
      .synthese-liste tbody tr td {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        cursor: default !important; }
      .synthese-liste tbody tr .synthese-liste-chip {
        display: flex;
        align-items: center;
        justify-content: center; }
        .synthese-liste tbody tr .synthese-liste-chip .chip {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          border-radius: 16px;
          width: fit-content;
          padding: 0 10px; }
          .synthese-liste tbody tr .synthese-liste-chip .chip.open {
            background-color: #00C4841A !important; }
          .synthese-liste tbody tr .synthese-liste-chip .chip.in-progress {
            background-color: #1211AF1A !important; }
          .synthese-liste tbody tr .synthese-liste-chip .chip.closed {
            background-color: #dcdcdc !important; }
          .synthese-liste tbody tr .synthese-liste-chip .chip-badge {
            height: 6px;
            width: 6px;
            border-radius: 5px; }
            .synthese-liste tbody tr .synthese-liste-chip .chip-badge.open {
              background-color: #00C484; }
            .synthese-liste tbody tr .synthese-liste-chip .chip-badge.in-progress {
              background-color: #1211AF; }
            .synthese-liste tbody tr .synthese-liste-chip .chip-badge.closed {
              background-color: #373a47; }
      .synthese-liste tbody tr .synthese-liste-total-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800; }

.synthese-liste-mobile {
  border-collapse: collapse;
  display: flex;
  flex-direction: column; }
  .synthese-liste-mobile thead {
    display: flex;
    flex-direction: row;
    background-color: #ffffff !important; }
    .synthese-liste-mobile thead tr {
      display: flex;
      flex: 1; }
      .synthese-liste-mobile thead tr th {
        display: flex;
        border: none !important;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #262626 !important;
        font-weight: 400; }
      .synthese-liste-mobile thead tr .synthese-liste-header-chip {
        display: flex;
        align-items: center;
        justify-content: center; }
        .synthese-liste-mobile thead tr .synthese-liste-header-chip .chip {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 16px;
          width: fit-content;
          padding: 0 10px; }
          .synthese-liste-mobile thead tr .synthese-liste-header-chip .chip.open {
            background-color: #00C4841A !important; }
          .synthese-liste-mobile thead tr .synthese-liste-header-chip .chip.in-progress {
            background-color: #1211AF1A !important; }
          .synthese-liste-mobile thead tr .synthese-liste-header-chip .chip.closed {
            background-color: #dcdcdc !important; }
  .synthese-liste-mobile tbody {
    display: flex;
    flex-direction: column;
    border: none !important; }
    .synthese-liste-mobile tbody tr {
      display: flex;
      flex: 1;
      flex-direction: row;
      background-color: #ffffff !important; }
      .synthese-liste-mobile tbody tr th {
        display: flex;
        min-height: 94px;
        flex: 1;
        align-items: center;
        justify-content: center;
        white-space: break-spaces; }
        .synthese-liste-mobile tbody tr th .synthese-liste-mobile-row-header {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: 400; }
          .synthese-liste-mobile tbody tr th .synthese-liste-mobile-row-header .icon-type-demande {
            font-size: 20px;
            padding: 5px;
            color: #1514ED;
            font-weight: 500;
            border-radius: 50%;
            background-color: #F2F5FF; }
      .synthese-liste-mobile tbody tr:first-child td, .synthese-liste-mobile tbody tr:first-child th {
        border-top: none !important; }
      .synthese-liste-mobile tbody tr td {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center; }
      .synthese-liste-mobile tbody tr .synthese-liste-total-cell-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800; }

.filtre-liste {
  cursor: pointer; }

.list-collapse {
  position: relative;
  background: #ffffff;
  box-shadow: 0 0 10px 0 #00000000, 0 0 20px 0 #00000000;
  z-index: 1;
  margin: 0;
  padding: 0;
  border: 0px solid #ffffff;
  border-bottom: 1px solid #dcdcdc;
  transition: all .15s ease-in-out;
  transition-delay: 0ms; }
  .list-collapse:last-child {
    margin-bottom: 20px;
    border-bottom: 0; }
  .list-collapse.active {
    box-shadow: 0 0 10px 0 #e9ecef, 0px 20px 20px 0 #f5f5f5;
    z-index: 10;
    margin: 20px -20px;
    padding: 0;
    border: 1px solid #e9ecef;
    transition: all .25s ease-in-out; }
    .list-collapse.active .list-collapse-head .list-head-label .label-group .label-1 {
      word-break: break-word;
      white-space: pre-line; }
  .list-collapse .list-collapse-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    min-height: 70px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    background: inherit;
    color: #595959; }
    .list-collapse .list-collapse-head:last-child {
      padding-right: 10px; }
    .list-collapse .list-collapse-head.list-header {
      cursor: auto;
      background-color: #4C4C4CCC;
      color: #ffffff;
      min-height: auto;
      height: 45px; }
    .list-collapse .list-collapse-head .list-head-right {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      width: 100%; }
    .list-collapse .list-collapse-head .list-head-right-top {
      display: flex;
      flex-direction: row;
      width: 50%; }
    .list-collapse .list-collapse-head .list-head-right-bottom {
      display: flex;
      flex-direction: row;
      flex: 0 1 0;
      align-items: center; }
    .list-collapse .list-collapse-head .list-head .icon-escort {
      font-size: 22px;
      color: #F50500;
      font-weight: 100; }
    .list-collapse .list-collapse-head .list-head-icon {
      width: 10%;
      display: flex;
      justify-content: space-evenly; }
      .list-collapse .list-collapse-head .list-head-icon .icon {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #1514ED;
        background-color: #F2F5FF; }
    .list-collapse .list-collapse-head .list-head-ref {
      display: flex;
      flex-direction: column;
      flex: 0 1 145px;
      min-width: 145px; }
      .list-collapse .list-collapse-head .list-head-ref .societe {
        font-size: 14px;
        position: relative;
        max-height: calc(1.3em * 2);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal; }
      .list-collapse .list-collapse-head .list-head-ref .code-ref {
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
        color: #9a9a9a;
        text-transform: uppercase;
        white-space: nowrap; }
    .list-collapse .list-collapse-head .list-head-alert {
      padding-right: 10px; }
      .list-collapse .list-collapse-head .list-head-alert .important {
        margin: 0;
        background-color: #262626; }
    .list-collapse .list-collapse-head .list-head-notes {
      display: flex;
      flex: 0 1;
      position: relative;
      min-width: 45px;
      align-items: center; }
      .list-collapse .list-collapse-head .list-head-notes .bullet-pick {
        position: relative;
        z-index: 10;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        height: 20px;
        width: 24px;
        background: #e9ecef;
        border: solid 1px #e9ecef;
        border-radius: 2px; }
      .list-collapse .list-collapse-head .list-head-notes .arrow {
        content: '';
        position: relative;
        z-index: 0;
        right: 4px;
        top: 0;
        display: flex;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        background: #e9ecef;
        box-shadow: 0 0 0px 1px #e9ecef; }
      .list-collapse .list-collapse-head .list-head-notes .important {
        position: absolute;
        display: block;
        height: 14px;
        width: 14px;
        z-index: 11;
        top: calc((100% - 20px) / 2 - 6px);
        right: 15px;
        background: #ffffff;
        border: 1px solid #262626;
        border-radius: 10px;
        font-size: 8px;
        text-align: center;
        line-height: 1.5; }
        .list-collapse .list-collapse-head .list-head-notes .important.question-mark {
          color: #262626; }
          .list-collapse .list-collapse-head .list-head-notes .important.question-mark:after {
            content: '?'; }
    .list-collapse .list-collapse-head .list-head-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1 1 100%; }
      .list-collapse .list-collapse-head .list-head-label .badge-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #262626;
        color: #ffffff;
        text-transform: inherit;
        padding: 0 .6rem;
        border-radius: 50px;
        margin-right: 7px;
        min-height: 20px;
        max-height: 20px;
        font-size: 11px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .list-collapse .list-collapse-head .list-head-label .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #262626;
        color: #ffffff;
        text-transform: uppercase;
        padding: 0 1rem;
        border-radius: 50px;
        margin-right: 15px;
        min-height: 20px;
        max-height: 20px;
        font-size: 11px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .list-collapse .list-collapse-head .list-head-label .label-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 24px;
        width: 100%;
        max-width: inherit; }
        .list-collapse .list-collapse-head .list-head-label .label-group .label-1, .list-collapse .list-collapse-head .list-head-label .label-group .label-2 {
          position: relative;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal; }
        .list-collapse .list-collapse-head .list-head-label .label-group .label-1 {
          color: #595959;
          margin-right: 5px;
          max-width: 330px;
          padding-bottom: 5px;
          padding-top: 5px; }
        .list-collapse .list-collapse-head .list-head-label .label-group .label-1-urgent {
          color: #eb2629; }
        .list-collapse .list-collapse-head .list-head-label .label-group .label-2 {
          color: #9a9a9a;
          margin-right: 15px;
          display: flex;
          align-items: center;
          max-width: 250px; }
        .list-collapse .list-collapse-head .list-head-label .label-group .label-3 {
          color: #9a9a9a;
          font-size: 12px; }
        .list-collapse .list-collapse-head .list-head-label .label-group .post-recent {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: right;
          color: #ee7202;
          white-space: break-spaces;
          max-width: 150px; }
    .list-collapse .list-collapse-head .list-head-date {
      width: 10%; }
      .list-collapse .list-collapse-head .list-head-date .post-timer {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        white-space: nowrap; }
    .list-collapse .list-collapse-head .list-head-status {
      width: 10%; }
      .list-collapse .list-collapse-head .list-head-status div {
        display: flex;
        justify-content: left;
        align-items: center; }
        .list-collapse .list-collapse-head .list-head-status div::before {
          content: '';
          border-radius: 50%;
          height: 10px;
          width: 10px;
          margin-right: 10px; }
        .list-collapse .list-collapse-head .list-head-status div.urgent {
          color: #eb2629; }
        .list-collapse .list-collapse-head .list-head-status div.urgent:before {
          background-color: #F50500; }
        .list-collapse .list-collapse-head .list-head-status div.standard:before {
          background-color: #FFC900; }
        .list-collapse .list-collapse-head .list-head-status div.other:before {
          background-color: #ee7202; }
        .list-collapse .list-collapse-head .list-head-status div.open:before {
          background-color: #00C484; }
        .list-collapse .list-collapse-head .list-head-status div.in-progress:before {
          background-color: #1211AF; }
        .list-collapse .list-collapse-head .list-head-status div.closed:before {
          background-color: #262626; }
      .list-collapse .list-collapse-head .list-head-status .chip {
        border-radius: 16px;
        width: fit-content;
        padding: 0 10px; }
        .list-collapse .list-collapse-head .list-head-status .chip.open {
          background-color: #00C4841A !important; }
        .list-collapse .list-collapse-head .list-head-status .chip.in-progress {
          background-color: #1211AF1A !important; }
        .list-collapse .list-collapse-head .list-head-status .chip.closed {
          background-color: #dcdcdc !important; }
    .list-collapse .list-collapse-head .align-space-between {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .list-collapse .list-collapse-body {
    padding: 20px 20px 0;
    opacity: 0;
    transition: height .2s ease;
    border-top: 1px solid #dcdcdc; }
    .list-collapse .list-collapse-body.collapse.show {
      opacity: 1; }
    .list-collapse .list-collapse-body.collapse {
      opacity: 0; }
    .list-collapse .list-collapse-body.collapsing {
      opacity: 0;
      padding: 0 20px;
      border-top: 0; }
    .list-collapse .list-collapse-body .list-body-item {
      margin-bottom: 20px; }
    .list-collapse .list-collapse-body .separator-container {
      margin-right: -20px;
      margin-left: -20px; }
    .list-collapse .list-collapse-body .separator {
      height: 1px;
      width: 100%;
      background-color: #dcdcdc; }
  .list-collapse .btn.btn-secondary {
    background: #ffffff;
    color: inherit;
    border-radius: 16px;
    max-width: inherit; }
  .list-collapse .list-body-item .head {
    margin-bottom: 20px; }
  .list-collapse .list-body-item .paper {
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 #0000001a;
    border: solid 1px #dcdcdc;
    background-color: #f5f5f5; }
  .list-collapse .list-body-item .title {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .list-collapse .list-body-item .content {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #262626; }
    .list-collapse .list-body-item .content + .content {
      margin-top: 10px; }
  .list-collapse .list-body-item .link {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262626; }
  .list-collapse .demande-acces .paper {
    padding: 20px;
    background-color: #f5f5f5; }
  .list-collapse .demande-acces .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0; }
    .list-collapse .demande-acces .head + .body {
      margin-top: 20px; }
  .list-collapse .demande-acces .body .btn-download {
    padding-left: 0; }
  .list-collapse .demande-acces .highlight-data {
    color: #F50500; }
  .list-collapse .demande-espace-associe .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 20px 0; }
  .list-collapse .demande-espace-associe .body {
    padding: 20px; }
    .list-collapse .demande-espace-associe .body table th, .list-collapse .demande-espace-associe .body table td {
      padding: 10px 20px; }
    .list-collapse .demande-espace-associe .body thead th {
      border: none;
      width: 200px; }
    .list-collapse .demande-espace-associe .body tbody td {
      width: 200px;
      color: #262626; }
      .list-collapse .demande-espace-associe .body tbody td span + span {
        margin-left: 10px; }
  .list-collapse .demande-ouverte .paper {
    padding: 20px;
    background-color: #f5f5f5; }
  .list-collapse .demande-ouverte .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .list-collapse .demande-ouverte .body .information-group {
    display: flex;
    align-items: center; }
    .list-collapse .demande-ouverte .body .information-group > .icon {
      font-size: 22px;
      margin-right: 10px; }
    .list-collapse .demande-ouverte .body .information-group > .form-group {
      flex: 1; }
    .list-collapse .demande-ouverte .body .information-group .form-control {
      border: none !important; }
      .list-collapse .demande-ouverte .body .information-group .form-control .btn.btn-secondary.open {
        background-color: #00C4841A !important; }
      .list-collapse .demande-ouverte .body .information-group .form-control .btn.btn-secondary.in-progress {
        background-color: #1211AF1A !important; }
      .list-collapse .demande-ouverte .body .information-group .form-control .btn.btn-secondary.closed {
        background-color: #dcdcdc !important; }
  .list-collapse .geste-action-row {
    row-gap: 30px; }
  .list-collapse .demande-escalader .paper {
    padding: 20px;
    background-color: #f5f5f5; }
  .list-collapse .demande-escalader .body {
    margin: 0;
    padding: 0; }
  .list-collapse .demande-escalader .form-control {
    background: none !important; }
  .list-collapse .demande-actions, .list-collapse .demande-services {
    background: #f5f5f5;
    padding: 20px 20px 40px;
    margin: 0 -20px; }
  .list-collapse .demande-actions .paper, .list-collapse .demande-services .paper, .list-collapse .demande-visiteur .paper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px; }
  .list-collapse .demande-actions .action-block, .list-collapse .demande-services .service-block, .list-collapse .demande-visiteur .visitor-block {
    margin-top: 20px; }
  .list-collapse .demande-actions .head {
    margin-bottom: 0; }
  .list-collapse .demande-actions .paper {
    height: 100%; }
  .list-collapse .demande-actions .action-block {
    min-height: 180px;
    height: 100%; }
  .list-collapse .demande-actions .action-information {
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap; }
    .list-collapse .demande-actions .action-information + .action-information {
      margin-top: 10px; }
    .list-collapse .demande-actions .action-information .info > .tickets {
      display: flex;
      align-items: center; }
      .list-collapse .demande-actions .action-information .info > .tickets .btn-tooltip {
        margin-left: 10px; }
        .list-collapse .demande-actions .action-information .info > .tickets .btn-tooltip .icon {
          font-size: 14px; }
  .list-collapse .demande-actions .btn-add-action {
    margin: auto; }
  .list-collapse .demande-services .head {
    margin-bottom: 0; }
  .list-collapse .demande-services .service-information + .service-information {
    margin-top: 10px; }
  .list-collapse .demande-visiteur .head {
    margin-bottom: 0; }
  .list-collapse .demande-visiteur .btn-tooltip {
    height: 20px; }
  .list-collapse .demande-visiteur .paper {
    height: 100%;
    padding: 20px; }
  .list-collapse .demande-visiteur .visitor-block {
    background-color: #ffffff; }
    .list-collapse .demande-visiteur .visitor-block-lg {
      height: 200px; }
    .list-collapse .demande-visiteur .visitor-block-sm {
      height: 145px; }
    .list-collapse .demande-visiteur .visitor-block .form-control {
      border: none !important;
      background: none !important; }
  .list-collapse .demande-visiteur .name {
    font-size: 14px;
    color: #595959;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }
  .list-collapse .demande-visiteur .mail {
    font-size: 14px;
    color: #595959;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }
  .list-collapse .demande-visiteur .info {
    font-size: 14px;
    color: #595959;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    height: 100%; }
  .list-collapse .demande-visiteur .icon .c-primary {
    color: #262626; }
  .list-collapse .demande-visiteur .visitor-information {
    color: #262626; }
    .list-collapse .demande-visiteur .visitor-information + .visitor-information {
      margin-top: 10px; }
    .list-collapse .demande-visiteur .visitor-information.row {
      align-items: center; }
    .list-collapse .demande-visiteur .visitor-information .open-visitors-book {
      display: flex;
      justify-content: flex-end; }
      .list-collapse .demande-visiteur .visitor-information .open-visitors-book .btn {
        width: auto;
        padding: 0;
        margin: 0; }
  .list-collapse .demande-note .head {
    margin-bottom: 10px;
    margin-top: 20px; }
  .list-collapse .demande-note .body .demande-note-post-commentaire {
    padding: 0;
    border-bottom: 0px solid #e9ecef; }
    .list-collapse .demande-note .body .demande-note-post-commentaire .files-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 15px; }
      .list-collapse .demande-note .body .demande-note-post-commentaire .files-container .btn-download {
        max-height: 36px; }
    .list-collapse .demande-note .body .demande-note-post-commentaire .btn-download .icon {
      margin-right: 0; }
  .list-collapse .demande-note .body .text {
    line-height: normal;
    color: #424242; }
  .list-collapse .demande-note .body .icon {
    margin-right: 1rem; }
  .list-collapse .demande-note .body .form-control-file {
    width: auto; }
  .list-collapse .demande-note .body textarea {
    height: 100px; }
  .list-collapse .demande-note .body .form-standard.add-note .group-checkbox {
    flex: 1; }
  .list-collapse .taken-over-data4-label:after {
    content: " - "; }

@media (min-width: 1200px) {
  .list-collapse .list-collapse-head .list-head-date {
    white-space: nowrap; } }

.list-collapse.mobile {
  margin-left: -15px;
  margin-right: -15px; }
  .list-collapse.mobile .list-collapse-head {
    justify-content: flex-start; }
    .list-collapse.mobile .list-collapse-head .list-head {
      padding-right: 0;
      padding-left: 10px; }
    .list-collapse.mobile .list-collapse-head .list-head-right {
      flex-direction: column; }
    .list-collapse.mobile .list-collapse-head .list-head-icon {
      width: auto;
      padding: 0 10px; }
    .list-collapse.mobile .list-collapse-head .list-head-right-top {
      width: 100%; }
      .list-collapse.mobile .list-collapse-head .list-head-right-top .label-group {
        padding: 5px 0; }
        .list-collapse.mobile .list-collapse-head .list-head-right-top .label-group .summary-mobile {
          width: 200px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .list-collapse.mobile .list-collapse-head .list-head-right-top .list-head-status, .list-collapse.mobile .list-collapse-head .list-head-right-top .list-head-date {
        width: auto; }
    .list-collapse.mobile .list-collapse-head .criticite {
      position: relative; }
      .list-collapse.mobile .list-collapse-head .criticite.urgent:before {
        background-color: #F50500; }
      .list-collapse.mobile .list-collapse-head .criticite.standard:before {
        background-color: #FFC900; }
      .list-collapse.mobile .list-collapse-head .criticite.other:before {
        background-color: #ee7202; }
      .list-collapse.mobile .list-collapse-head .criticite:before {
        position: absolute;
        width: 3px;
        border-radius: 4px;
        height: 25px;
        top: 30%;
        content: ""; }
        @media (max-width: 991px) {
          .list-collapse.mobile .list-collapse-head .criticite:before {
            height: 60%;
            top: 20%; } }
    .list-collapse.mobile .list-collapse-head .list-head-ref {
      flex-direction: row;
      align-items: center;
      flex: 1; }
    .list-collapse.mobile .list-collapse-head .list-head-notes {
      padding-right: 0;
      margin-right: 10px;
      min-width: 35px; }
      .list-collapse.mobile .list-collapse-head .list-head-notes .important {
        right: 0; }
    .list-collapse.mobile .list-collapse-head .societe, .list-collapse.mobile .list-collapse-head .code-ref {
      margin-right: 10px; }
    .list-collapse.mobile .list-collapse-head .list-head-date {
      padding-right: 1rem;
      padding-left: 0; }
  .list-collapse.mobile .information-group {
    width: 100%; }
  @media (min-width: 768px) {
    .list-collapse.mobile .list-collapse-head .icon-escort {
      margin-right: 20px; }
    .list-collapse.mobile .list-collapse-head .list-head-right {
      flex-direction: row-reverse;
      justify-content: flex-end; }
    .list-collapse.mobile .list-collapse-head .list-head-right-top {
      margin-bottom: 0; }
    .list-collapse.mobile .list-collapse-head .list-head-date {
      bottom: 25px; } }

.custom-select {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #595959;
  border-color: #e9ecef;
  height: 30px;
  background: #f5f5f5;
  padding: 3px 10px; }

.btn-select .tick-round {
  margin: 0;
  padding: 0; }

.tick-round {
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 7px 10px; }
  .tick-round:active {
    background: none;
    color: #b2b2b2; }
  .tick-round .tick {
    position: relative;
    z-index: 5000;
    border-radius: 10px;
    height: 13px;
    width: 13px;
    margin-right: 5px; }
  .tick-round .validation {
    background-color: #04a583; }
  .tick-round .error {
    background-color: #ee7202; }
  .tick-round .refusal {
    background-color: #eb2629; }
  .tick-round .urgent {
    background-color: #eb5b5d; }
  .tick-round .standard {
    background-color: #FFC107; }
  .tick-round .other {
    background-color: #ee7202; }
  .tick-round .open {
    background-color: #00C484; }
  .tick-round .in-progress {
    background-color: #1211AF; }
  .tick-round .closed {
    background-color: #262626; }
  .tick-round .close {
    background-color: #424242; }

.loading-is-active .btn-select {
  position: inherit;
  overflow: visible !important; }
  .loading-is-active .btn-select:after {
    content: '';
    position: absolute;
    z-index: 10;
    right: -35px;
    display: block;
    opacity: 1;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background-image: url("../img/loader.svg") !important;
    background-size: contain !important; }
  .loading-is-active .btn-select:active:after {
    display: block;
    opacity: 1; }

.loading-is-active ~ * {
  margin-left: 30px !important; }

.search-bar-annuaire + .liste-annuaire {
  margin-top: 20px; }

.search-bar-annuaire .search-btn {
  height: 100%;
  display: flex;
  align-items: center; }
  .search-bar-annuaire .search-btn .btn {
    width: 100%; }

.liste-annuaire + .pagination {
  margin-top: 20px; }

.pagination .pagination-container {
  display: flex;
  flex-direction: row; }

.pagination .pagination-count {
  height: 25px;
  margin: 6px 0; }
  .pagination .pagination-count span {
    margin: 0 1px; }
  .pagination .pagination-count > span:nth-child(4) {
    margin: 0 3px; }

.pagination .btn {
  height: 25px;
  padding: 0 5px;
  box-shadow: none !important;
  outline: none !important;
  border: none !important; }

.main-body-annuaire .liste-annuaire {
  background: #f5f5f5;
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 20px; }
  .main-body-annuaire .liste-annuaire .label {
    width: auto; }
    .main-body-annuaire .liste-annuaire .label span {
      margin-right: 2px; }
  .main-body-annuaire .liste-annuaire .paper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0; }
  .main-body-annuaire .liste-annuaire .btn {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    margin: 0;
    padding-right: 0;
    background: none;
    height: 20px; }
    .main-body-annuaire .liste-annuaire .btn:hover, .main-body-annuaire .liste-annuaire .btn:focus {
      color: #262626; }
    .main-body-annuaire .liste-annuaire .btn:active {
      background-color: transparent;
      color: #262626; }
  @media (min-width: 992px) {
    .main-body-annuaire .liste-annuaire {
      height: 200px; } }

.no-suggestions {
  color: #9a9a9a;
  padding: 0.5rem; }

.form-standard .autocomplete.group-form-control {
  position: relative;
  display: inline-block; }

.suggestions {
  font-size: 14px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  border-radius: 2px;
  list-style: none;
  padding-left: 0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #595959;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  left: 0;
  width: 100%; }

.suggestions li {
  padding: 0.5rem;
  min-height: 44px; }

.suggestion-active,
.suggestions li:hover {
  color: #ffffff;
  background-color: #262626;
  cursor: pointer; }

@media (min-width: 1px) and (max-width: 991px) {
  .forfait-select-block .forfait-selector {
    margin-bottom: 10px; } }

.forfait-actions-list .champ-obligatoire {
  margin-bottom: 10px;
  margin-top: 30px; }

.forfait-actions-item {
  border-top: 1px solid #dcdcdc;
  padding: 15px 10px;
  position: relative; }
  .forfait-actions-item .head {
    margin-bottom: 5px; }
  .forfait-actions-item .date {
    position: absolute;
    top: 15px;
    right: 10px; }
    @media (min-width: 1px) and (max-width: 991px) {
      .forfait-actions-item .date {
        display: none; } }

.forfait-deficit {
  color: #ee7202 !important; }

.tickets-information .icon {
  margin-right: 10px; }

.one-shot-forfait-header {
  display: flex;
  align-items: center; }
  .one-shot-forfait-header .tickets-information {
    text-align: right; }
    @media (min-width: 1px) and (max-width: 991px) {
      .one-shot-forfait-header .tickets-information {
        text-align: left; } }

/* SURCHARGE DES VARIABLES */
.pretty * {
  box-sizing: border-box; }

.pretty input:not([type='checkbox']):not([type='radio']) {
  display: none; }

.pretty {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1; }
  .pretty input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer; }
  .pretty .state label {
    position: initial;
    display: inline-block;
    font-weight: normal;
    margin: 0;
    text-indent: 0;
    min-width: 18px; }
    .pretty .state label:before, .pretty .state label:after {
      content: '';
      width: 18px;
      height: 18px;
      display: block;
      box-sizing: border-box;
      border-radius: 0;
      border: 1px solid transparent;
      z-index: 0;
      position: absolute;
      left: 0;
      top: calc((0% - (100% - 1em)) - 8%);
      background-color: transparent; }
    .pretty .state label:before {
      border-color: #9a9a9a; }
  .pretty .state.p-is-hover, .pretty .state.p-is-indeterminate {
    display: none; }

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0); } }

@keyframes tada {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7); }
  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1); }
  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5); }
  72% {
    animation-timing-function: ease-out;
    transform: scale(1); }
  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24); }
  89% {
    animation-timing-function: ease-out;
    transform: scale(1); }
  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04); }
  100% {
    animation-timing-function: ease-out;
    transform: scale(1); } }

@keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(0.75, 1.25, 1); }
  40% {
    transform: scale3d(1.25, 0.75, 1); }
  50% {
    transform: scale3d(0.85, 1.15, 1); }
  65% {
    transform: scale3d(1.05, 0.95, 1); }
  75% {
    transform: scale3d(0.95, 1.05, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-200px) rotate(-45deg); }
  100% {
    opacity: 1;
    transform: translateZ(0) rotate(0); } }

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #9a9a9a; }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(154, 154, 154, 0); } }

.pretty.p-default.p-fill .state label:after {
  transform: scale(1); }

.pretty.p-default .state label:after {
  transform: scale(0.6); }

.pretty.p-default input:checked ~ .state label:after {
  background-color: #9a9a9a !important; }

.pretty.p-default.p-thick .state label:before, .pretty.p-default.p-thick .state label:after {
  border-width: calc(1em / 7); }

.pretty.p-default.p-thick .state label:after {
  transform: scale(0.4) !important; }

.pretty.p-icon .state .icon {
  position: absolute;
  font-size: 1em;
  width: 18px;
  height: 18px;
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-icon .state .icon:before {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1; }

.pretty.p-icon input:checked ~ .state .icon {
  opacity: 1; }

.pretty.p-icon input:checked ~ .state label:before {
  border-color: #5a656b; }

.pretty.p-svg .state .svg {
  position: absolute;
  font-size: 1em;
  width: 18px;
  height: 18px;
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-svg .state svg {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1; }

.pretty.p-svg input:checked ~ .state .svg {
  opacity: 1; }

.pretty.p-image .state img {
  opacity: 0;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  left: 0;
  z-index: 0;
  text-align: center;
  line-height: normal;
  transform: scale(0.8); }

.pretty.p-image input:checked ~ .state img {
  opacity: 1; }

.pretty.p-switch input {
  min-width: 2em; }

.pretty.p-switch .state {
  position: relative; }
  .pretty.p-switch .state:before {
    content: '';
    border: 1px solid #9a9a9a;
    border-radius: 60px;
    width: 2em;
    box-sizing: unset;
    height: 18px;
    position: absolute;
    top: 0;
    top: calc((0% - (100% - 1em)) - 16%);
    z-index: 0;
    transition: all 0.5s ease; }
  .pretty.p-switch .state label {
    text-indent: 2.5em; }
    .pretty.p-switch .state label:before, .pretty.p-switch .state label:after {
      transition: all 0.5s ease;
      border-radius: 100%;
      left: 0;
      border-color: transparent;
      transform: scale(0.8); }
    .pretty.p-switch .state label:after {
      background-color: #9a9a9a !important; }

.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b; }

.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch input:checked ~ .state label:after {
  background-color: #5a656b !important;
  left: 1em; }

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch.p-fill input:checked ~ .state label:after {
  background-color: #fff !important;
  left: 1em; }

.pretty.p-switch.p-slim .state:before {
  height: 0.1em;
  background: #9a9a9a !important;
  top: calc(50% - 0.1em); }

.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none; }

.pretty.p-has-hover input:hover ~ .state.p-is-hover {
  display: block; }
  .pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
    display: block; }

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0px 0px 3px 0px #bdc3c7; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state:not(.p-is-indeterminate) {
  display: none; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate {
  display: block; }
  .pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate .icon {
    display: block;
    opacity: 1; }

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none; }

.pretty.p-toggle .state.p-off,
.pretty.p-toggle .state .icon,
.pretty.p-toggle .state .svg,
.pretty.p-toggle .state img {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle .state.p-off .icon {
  color: #9a9a9a; }

.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none; }

.pretty.p-plain input:checked ~ .state label:before,
.pretty.p-plain.p-toggle .state label:before {
  content: none; }

.pretty.p-plain.p-plain .icon {
  transform: scale(1.1); }

.pretty.p-round .state label:before, .pretty.p-round .state label:after {
  border-radius: 100%; }

.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden; }
  .pretty.p-round.p-icon .state .icon:before {
    transform: scale(0.8); }

.pretty.p-curve .state label:before, .pretty.p-curve .state label:after {
  border-radius: 3px; }

.pretty.p-smooth label:before,
.pretty.p-smooth label:after,
.pretty.p-smooth .icon,
.pretty.p-smooth .svg {
  transition: all 0.5s ease; }

.pretty.p-smooth input:checked + .state label:after {
  transition: all 0.3s ease; }

.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
  animation: zoom 0.2s ease; }

.pretty.p-smooth.p-default input:checked + .state label:after {
  animation: zoom 0.2s ease; }

.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: '';
  transform: scale(0);
  transition: all 0.5s ease; }

.pretty.p-tada:not(.p-default) input:checked + .state .icon,
.pretty.p-tada:not(.p-default) input:checked + .state .svg,
.pretty.p-tada:not(.p-default) input:checked + .state img,
.pretty.p-tada:not(.p-default) input:checked + .state label:before,
.pretty.p-tada:not(.p-default) input:checked + .state label:after {
  animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state .icon,
.pretty.p-jelly:not(.p-default) input:checked + .state .svg,
.pretty.p-jelly:not(.p-default) input:checked + .state img,
.pretty.p-jelly:not(.p-default) input:checked + .state label:before,
.pretty.p-jelly:not(.p-default) input:checked + .state label:after {
  animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: transparent; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon,
.pretty.p-rotate:not(.p-default) input:checked ~ .state .svg,
.pretty.p-rotate:not(.p-default) input:checked ~ .state img,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:after {
  animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: transparent; }

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  animation: pulse 1s; }

.pretty input[disabled] {
  cursor: not-allowed;
  display: none; }
  .pretty input[disabled] ~ * {
    opacity: .5; }

.pretty.p-locked input {
  display: none;
  cursor: not-allowed; }

.pretty input:checked ~ .state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #262626 !important; }

.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg,
.pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
  border-color: #262626; }

.pretty input:checked ~ .state.p-primary-o label:after,
.pretty.p-toggle .state.p-primary-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-primary-o .icon,
.pretty input:checked ~ .state.p-primary-o .svg,
.pretty input:checked ~ .state.p-primary-o svg,
.pretty.p-toggle .state.p-primary-o .icon,
.pretty.p-toggle .state.p-primary-o .svg,
.pretty.p-toggle .state.p-primary-o svg {
  color: #262626;
  stroke: #262626; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #262626 !important; }

.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #262626; }

.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #262626 !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: black;
  background-color: black !important; }

.pretty input:checked ~ .state.p-info label:after,
.pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important; }

.pretty input:checked ~ .state.p-info .icon,
.pretty input:checked ~ .state.p-info .svg,
.pretty.p-toggle .state.p-info .icon,
.pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-info-o label:before,
.pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de; }

.pretty input:checked ~ .state.p-info-o label:after,
.pretty.p-toggle .state.p-info-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-info-o .icon,
.pretty input:checked ~ .state.p-info-o .svg,
.pretty input:checked ~ .state.p-info-o svg,
.pretty.p-toggle .state.p-info-o .icon,
.pretty.p-toggle .state.p-info-o .svg,
.pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important; }

.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de; }

.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important; }

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #262626 !important; }

.pretty input:checked ~ .state.p-success .icon,
.pretty input:checked ~ .state.p-success .svg,
.pretty.p-toggle .state.p-success .icon,
.pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-success-o label:before,
.pretty.p-toggle .state.p-success-o label:before {
  border-color: #262626; }

.pretty input:checked ~ .state.p-success-o label:after,
.pretty.p-toggle .state.p-success-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-success-o .icon,
.pretty input:checked ~ .state.p-success-o .svg,
.pretty input:checked ~ .state.p-success-o svg,
.pretty.p-toggle .state.p-success-o .icon,
.pretty.p-toggle .state.p-success-o .svg,
.pretty.p-toggle .state.p-success-o svg {
  color: #262626;
  stroke: #262626; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #262626 !important; }

.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #262626; }

.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #262626 !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: black;
  background-color: black !important; }

.pretty input:checked ~ .state.p-warning label:after,
.pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important; }

.pretty input:checked ~ .state.p-warning .icon,
.pretty input:checked ~ .state.p-warning .svg,
.pretty.p-toggle .state.p-warning .icon,
.pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-warning-o label:before,
.pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e; }

.pretty input:checked ~ .state.p-warning-o label:after,
.pretty.p-toggle .state.p-warning-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-warning-o .icon,
.pretty input:checked ~ .state.p-warning-o .svg,
.pretty input:checked ~ .state.p-warning-o svg,
.pretty.p-toggle .state.p-warning-o .icon,
.pretty.p-toggle .state.p-warning-o .svg,
.pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important; }

.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e; }

.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important; }

.pretty input:checked ~ .state.p-danger label:after,
.pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important; }

.pretty input:checked ~ .state.p-danger .icon,
.pretty input:checked ~ .state.p-danger .svg,
.pretty.p-toggle .state.p-danger .icon,
.pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-danger-o label:before,
.pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f; }

.pretty input:checked ~ .state.p-danger-o label:after,
.pretty.p-toggle .state.p-danger-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-danger-o .icon,
.pretty input:checked ~ .state.p-danger-o .svg,
.pretty input:checked ~ .state.p-danger-o svg,
.pretty.p-toggle .state.p-danger-o .icon,
.pretty.p-toggle .state.p-danger-o .svg,
.pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important; }

.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f; }

.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important; }

.pretty.p-bigger label:before,
.pretty.p-bigger label:after,
.pretty.p-bigger .icon,
.pretty.p-bigger .svg,
.pretty.p-bigger .img {
  font-size: 1.2em !important;
  top: calc((0% - (100% - 1em)) - 35%) !important; }

.pretty.p-bigger label {
  text-indent: 1.7em; }

@media print {
  .pretty .state:before,
  .pretty .state label:before,
  .pretty .state label:after,
  .pretty .state .icon {
    color-adjust: exact;
    /* stylelint-disable */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; } }

.pretty {
  margin: 10px 0;
  line-height: 1.72em;
  white-space: normal; }
  .pretty .state {
    padding-left: calc(1.5em + 9px); }
    .pretty .state label:after, .pretty .state label:before {
      top: calc((1.72em - 18px) / 2);
      border-width: 2px; }
  .pretty input:checked ~ .state label:before, .pretty input:checked ~ .state label:after {
    border-color: #262626; }
  .pretty.p-default .state label:after {
    transform: scale(0.5); }
  .pretty.p-icon .state .icon {
    font-weight: 900;
    top: calc((1.72em - 18px) / 2); }
  .pretty.p-icon .state label:after, .pretty.p-icon .state label:before {
    top: calc((1.72em - 18px) / 2); }

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
  background-color: #262626;
  color: #ffffff;
  text-shadow: 0 -1px 0 #00000040; }

.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover, .rdtPicker td span.rdtDisabled, .rdtPicker td span.rdtDisabled:hover, .rdtPicker th.rdtDisabled, .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #9a9a9a;
  cursor: not-allowed !important; }

td.rdtMonth, td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover {
  background: #e9ecef;
  cursor: pointer; }

.rdt {
  position: relative; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #ffffff;
  box-shadow: 0 1px 3px #0000001a;
  border: 1px solid #f8f9fa; }
  .rdtPicker .rdtTimeToggle {
    text-align: center; }
    .rdtPicker .rdtTimeToggle:hover {
      background: #e9ecef;
      cursor: pointer; }
  .rdtPicker table {
    width: 100%;
    margin: 0; }
  .rdtPicker td {
    text-align: center;
    height: 28px;
    cursor: pointer; }
    .rdtPicker td.rdtOld {
      color: #9a9a9a; }
    .rdtPicker td.rdtNew {
      color: #9a9a9a; }
    .rdtPicker td.rdtToday {
      position: relative; }
      .rdtPicker td.rdtToday:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #262626;
        border-top-color: #00000033;
        position: absolute;
        bottom: 4px;
        right: 4px; }
    .rdtPicker td.rdtActive:hover {
      color: unset; }
    .rdtPicker td.rdtActive.rdtToday:before {
      border-bottom-color: #ffffff; }
    .rdtPicker td span.rdtOld {
      color: #9a9a9a; }
  .rdtPicker th {
    text-align: center;
    height: 28px;
    border-bottom: 1px solid #f8f9fa; }
    .rdtPicker th.rdtSwitch {
      width: 100px; }
    .rdtPicker th.rdtNext {
      font-size: 21px;
      vertical-align: top; }
    .rdtPicker th.rdtPrev {
      font-size: 21px;
      vertical-align: top; }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default; }
  .rdtPicker thead button {
    width: 100%;
    height: 100%; }
  .rdtPicker thead tr:first-child th {
    cursor: pointer; }
    .rdtPicker thead tr:first-child th:hover {
      background: #e9ecef; }
  .rdtPicker tfoot {
    border-top: 1px solid #f8f9fa; }
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer; }
    .rdtPicker button:hover {
      background-color: #e9ecef; }

td.rdtMonth:hover {
  background: #e9ecef; }

td.rdtYear:hover {
  background: #e9ecef; }

.rdtCounters {
  display: inline-block; }
  .rdtCounters > div {
    float: left; }

.rdtCounter {
  height: 100px;
  width: 40px; }
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    user-select: none; }
    .rdtCounter .rdtBtn:hover {
      background: #e9ecef; }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em; }

.rdtCounterSeparator {
  line-height: 100px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px; }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPrev span {
  display: block;
  user-select: none; }

.rdtNext span {
  display: block;
  user-select: none; }

.rdtTime td {
  cursor: default; }

@media (min-width: 1px) and (max-width: 991px) {
  .rdtPicker {
    position: absolute; } }

.form-switch .form-control {
  display: flex;
  align-items: center;
  border: none;
  padding: 0; }
  .form-switch .form-control label {
    margin-bottom: 0; }

.form-switch.form-group {
  position: relative; }
  .form-switch.form-group .custom-switch {
    padding-left: 36px;
    width: 36px; }
    .form-switch.form-group .custom-switch .custom-control-label::before {
      position: absolute;
      height: 18px;
      width: 36px;
      margin: 0px;
      background: #888888;
      border-radius: 14px;
      cursor: pointer;
      transition: background 0.25s ease 0s;
      top: 1px;
      left: -36px; }
    .form-switch.form-group .custom-switch .custom-control-label::after {
      width: 20px;
      height: 20px;
      background: #ffffff;
      display: inline-block;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      transform: translateX(0);
      top: 0;
      left: -36px;
      outline: 0;
      border: 0;
      transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
      box-shadow: 0px 1px 5px #00000099; }
    .form-switch.form-group .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(18px); }
    .form-switch.form-group .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #262626;
      background-color: #262626; }
    .form-switch.form-group .custom-switch + .form-label {
      margin-left: 10px; }

.form-select .form-control {
  padding: 0;
  min-height: 30px; }
  .form-select .form-control:hover {
    border-color: #dcdcdc; }

.form-select__value-container {
  padding-bottom: 0 !important;
  padding-top: 0 !important; }

.form-select__indicator {
  padding: 7px !important; }

.form-select__indicator-separator {
  margin-bottom: 7px !important;
  margin-top: 7px !important; }

.form-select__control--is-focused {
  border: 1px solid #595959 !important;
  box-shadow: 0 3px 0px -1px #f5f5f5, 0px 5px 0px -1px #00000003 !important; }
  .form-select__control--is-focused:hover {
    border-color: #595959 !important; }

.form-select__option.form-select-option {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #595959;
  font-family: "Roboto", sans-serif;
  cursor: pointer; }
  .form-select__option.form-select-option:active {
    color: #ffffff;
    background-color: #595959; }
  .form-select__option.form-select-option > * {
    padding: 0; }

.form-select__option--is-focused.form-select-option {
  color: #595959;
  background-color: #f8f9fa; }

.form-select__option--is-selected.form-select-option {
  color: #ffffff;
  background-color: #595959; }

.form-select__multi-value__remove:hover {
  background-color: #262626 !important;
  color: #ffffff !important; }

.form-dropdown .value-container {
  padding-left: 0; }

.form-dropdown .single-value {
  margin: 0;
  color: #595959; }

.form-dropdown .dropdown-indicator {
  padding: 0 !important; }
  .form-dropdown .dropdown-indicator .icon {
    color: #595959; }

.form-dropdown .form-control {
  font-size: 14px;
  background-color: #f5f5f5;
  border-color: #e9ecef;
  padding: 0;
  display: flex;
  align-items: center; }

.form-dropdown .btn {
  border: none;
  justify-content: space-between;
  width: 100%;
  line-height: 1.71;
  height: 100% !important; }
  .form-dropdown .btn:hover {
    cursor: pointer; }

.multi-select .form-control {
  height: auto; }

.form-button-group .selectable-button {
  cursor: pointer;
  border: 1px solid #e9ecef;
  background: #f5f5f5;
  position: relative;
  margin-bottom: 5.5px;
  margin-top: 5.5px; }
  .form-button-group .selectable-button.checked {
    background: #262626;
    border: none;
    color: #ffffff; }

.form-button-group .btn-circle {
  border-radius: 100px;
  width: 33px;
  height: 33px;
  text-align: center; }
  .form-button-group .btn-circle .label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.form-standard .form-button-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .form-standard .form-button-group.inline {
    flex-direction: row; }
    .form-standard .form-button-group.inline .selectable-button + .selectable-button {
      margin-left: 10px; }
  @media (max-width: 991px) {
    .form-standard .form-button-group.inline {
      flex-direction: column; }
      .form-standard .form-button-group.inline .selectable-button + .selectable-button {
        margin-left: 0; } }

/* SURCHARGE DES VARIABLES */
.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }
  .Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    .Toastify__toast-container--rtl {
      right: 0;
      left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify__toast--rtl {
    direction: rtl; }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa; }
  .Toastify__toast--info {
    background: #3498db; }
  .Toastify__toast--success {
    background: #07bc0c; }
  .Toastify__toast--warning {
    background: #f1c40f; }
  .Toastify__toast--error {
    background: #e74c3c; }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; }
  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards; }
  .Toastify__progress-bar--controlled {
    transition: transform .2s; }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right; }
  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

.toast-container {
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal; }

.toast-inner {
  padding-left: 20px;
  padding-right: 20px; }
  .toast-inner button.close {
    color: #ffffff;
    text-shadow: none;
    opacity: 1;
    font-weight: 700; }
    .toast-inner button.close .icon {
      font-size: 10px; }

.tickets-consumption-chart {
  /**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
  /* Titles */
  /* Axes */
  /* Credits */
  /* Tooltip */
  /* Legend hover affects points and series */
  /* Series options */
  /* Default colors */
  /* Legend */
  /* Bubble legend */
  /* Loading */
  /* Plot bands and polar pane backgrounds */
  /* Highcharts More and modules */
  /* Highstock */
  /* Highmaps */
  /* 3d charts */
  /* Exporting module */
  /* Drilldown module */
  /* No-data module */
  /* Drag-panes module */
  /* Bullet type series */
  /* Lineargauge type series */
  /* Annotations module */
  /* Gantt */
  background-color: #f5f5f5; }
  .tickets-consumption-chart .highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    /* #1072 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Roboto", sans-serif;
    font-size: 12px; }
  .tickets-consumption-chart .highcharts-root {
    display: block; }
  .tickets-consumption-chart .highcharts-root text {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-strong {
    font-weight: bold; }
  .tickets-consumption-chart .highcharts-emphasized {
    font-style: italic; }
  .tickets-consumption-chart .highcharts-anchor {
    cursor: pointer; }
  .tickets-consumption-chart .highcharts-background {
    fill: #ffffff; }
  .tickets-consumption-chart .highcharts-plot-border, .tickets-consumption-chart .highcharts-plot-background {
    fill: none; }
  .tickets-consumption-chart .highcharts-label-box {
    fill: none; }
  .tickets-consumption-chart .highcharts-button-box {
    fill: inherit; }
  .tickets-consumption-chart .highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none; }
  .tickets-consumption-chart .highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-title {
    fill: #333333;
    font-size: 1.5em; }
  .tickets-consumption-chart .highcharts-subtitle {
    fill: #666666; }
  .tickets-consumption-chart .highcharts-axis-line {
    fill: none;
    stroke: #ccd6eb; }
  .tickets-consumption-chart .highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-axis-title {
    fill: #666666; }
  .tickets-consumption-chart .highcharts-axis-labels {
    fill: #666666;
    cursor: default;
    font-size: 0.9em; }
  .tickets-consumption-chart .highcharts-grid-line {
    fill: none;
    stroke: #e6e6e6; }
  .tickets-consumption-chart .highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0px; }
  .tickets-consumption-chart .highcharts-tick {
    stroke: #ccd6eb; }
  .tickets-consumption-chart .highcharts-yaxis .highcharts-tick {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-minor-grid-line {
    stroke: #f2f2f2; }
  .tickets-consumption-chart .highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: #cccccc; }
  .tickets-consumption-chart .highcharts-crosshair-category {
    stroke: #ccd6eb;
    stroke-opacity: 0.25; }
  .tickets-consumption-chart .highcharts-credits {
    cursor: pointer;
    fill: #999999;
    font-size: 0.7em;
    transition: fill 250ms, font-size 250ms; }
  .tickets-consumption-chart .highcharts-credits:hover {
    fill: black;
    font-size: 1em; }
  .tickets-consumption-chart .highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms; }
  .tickets-consumption-chart .highcharts-tooltip text {
    fill: #333333; }
  .tickets-consumption-chart .highcharts-tooltip .highcharts-header {
    font-size: 0.85em; }
  .tickets-consumption-chart .highcharts-tooltip-box {
    stroke-width: 1px;
    fill: #f7f7f7;
    fill-opacity: 0.85; }
  .tickets-consumption-chart .highcharts-tooltip-box .highcharts-label-box {
    fill: #f7f7f7;
    fill-opacity: 0.85; }
  .tickets-consumption-chart .highcharts-selection-marker {
    fill: #335cad;
    fill-opacity: 0.25; }
  .tickets-consumption-chart .highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round; }
  .tickets-consumption-chart .highcharts-state-hover .highcharts-graph {
    stroke-width: 3; }
  .tickets-consumption-chart .highcharts-state-hover path {
    transition: stroke-width 50;
    /* quick in */ }
  .tickets-consumption-chart .highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */ }
  .tickets-consumption-chart g.highcharts-series,
  .tickets-consumption-chart .highcharts-point,
  .tickets-consumption-chart .highcharts-markers,
  .tickets-consumption-chart .highcharts-data-labels {
    transition: opacity 250ms; }
  .tickets-consumption-chart .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
  .tickets-consumption-chart .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
  .tickets-consumption-chart .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
  .tickets-consumption-chart .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.2; }
  .tickets-consumption-chart .highcharts-color-0 {
    fill: rgba(38, 38, 38, 0.6);
    stroke: rgba(38, 38, 38, 0.6); }
  .tickets-consumption-chart .highcharts-color-1 {
    fill: #ee7202;
    stroke: #ee7202; }
  .tickets-consumption-chart .highcharts-area {
    fill-opacity: 0.75;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-markers {
    stroke-width: 1px;
    stroke: #ffffff; }
  .tickets-consumption-chart .highcharts-point {
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-dense-data .highcharts-point {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold; }
  .tickets-consumption-chart .highcharts-data-label-box {
    fill: none;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-data-label text, .tickets-consumption-chart text.highcharts-data-label {
    fill: #333333; }
  .tickets-consumption-chart .highcharts-data-label-connector {
    fill: none; }
  .tickets-consumption-chart .highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
  .tickets-consumption-chart .highcharts-markers .highcharts-point-select {
    fill: #cccccc;
    stroke: #000000; }
  .tickets-consumption-chart .highcharts-column-series rect.highcharts-point {
    stroke: #ffffff; }
  .tickets-consumption-chart .highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms; }
  .tickets-consumption-chart .highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .tickets-consumption-chart .highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #ffffff; }
  .tickets-consumption-chart .highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .tickets-consumption-chart .highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #ffffff; }
  .tickets-consumption-chart .highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .tickets-consumption-chart .highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit; }
  .tickets-consumption-chart .highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #ffffff; }
  .tickets-consumption-chart .highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .tickets-consumption-chart .highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit; }
  .tickets-consumption-chart .highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: #e6e6e6;
    transition: stroke 250ms, fill 250ms, fill-opacity 250ms; }
  .tickets-consumption-chart .highcharts-treemap-series .highcharts-point-hover {
    stroke: #999999;
    transition: stroke 25ms, fill 25ms, fill-opacity 25ms; }
  .tickets-consumption-chart .highcharts-treemap-series .highcharts-above-level {
    display: none; }
  .tickets-consumption-chart .highcharts-treemap-series .highcharts-internal-node {
    fill: none; }
  .tickets-consumption-chart .highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer; }
  .tickets-consumption-chart .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75; }
  .tickets-consumption-chart .highcharts-legend-box {
    fill: none;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-legend-item > text {
    fill: #333333;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-legend-item:hover text {
    fill: #000000; }
  .tickets-consumption-chart .highcharts-legend-item-hidden * {
    fill: #cccccc !important;
    stroke: #cccccc !important;
    transition: fill 250ms; }
  .tickets-consumption-chart .highcharts-legend-nav-active {
    fill: #003399;
    cursor: pointer; }
  .tickets-consumption-chart .highcharts-legend-nav-inactive {
    fill: #cccccc; }
  .tickets-consumption-chart circle.highcharts-legend-nav-active, .tickets-consumption-chart circle.highcharts-legend-nav-inactive {
    /* tracker */
    fill: rgba(192, 192, 192, 0.0001); }
  .tickets-consumption-chart .highcharts-legend-title-box {
    fill: none;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5; }
  .tickets-consumption-chart .highcharts-bubble-legend-connectors {
    stroke-width: 1; }
  .tickets-consumption-chart .highcharts-bubble-legend-labels {
    fill: #333333; }
  .tickets-consumption-chart .highcharts-loading {
    position: absolute;
    background-color: #ffffff;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms; }
  .tickets-consumption-chart .highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end; }
  .tickets-consumption-chart .highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%; }
  .tickets-consumption-chart .highcharts-plot-band, .tickets-consumption-chart .highcharts-pane {
    fill: #000000;
    fill-opacity: 0.05; }
  .tickets-consumption-chart .highcharts-plot-line {
    fill: none;
    stroke: #999999;
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-boxplot-box {
    fill: #ffffff; }
  .tickets-consumption-chart .highcharts-boxplot-median {
    stroke-width: 2px; }
  .tickets-consumption-chart .highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5; }
  .tickets-consumption-chart .highcharts-errorbar-series .highcharts-point {
    stroke: #000000; }
  .tickets-consumption-chart .highcharts-gauge-series .highcharts-data-label-box {
    stroke: #cccccc;
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-gauge-series .highcharts-dial {
    fill: #000000;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-waterfall-series .highcharts-graph {
    stroke: #333333;
    stroke-dasharray: 1, 3; }
  .tickets-consumption-chart .highcharts-sankey-series .highcharts-point {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-sankey-series .highcharts-link {
    transition: fill 250ms, fill-opacity 250ms;
    fill-opacity: 0.5; }
  .tickets-consumption-chart .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition: fill 50ms, fill-opacity 50ms;
    fill-opacity: 1; }
  .tickets-consumption-chart .highcharts-venn-series .highcharts-point {
    fill-opacity: 0.75;
    stroke: #cccccc;
    transition: stroke 250ms, fill-opacity 250ms; }
  .tickets-consumption-chart .highcharts-venn-series .highcharts-point-hover {
    fill-opacity: 1;
    stroke: #cccccc; }
  .tickets-consumption-chart .highcharts-navigator-mask-outside {
    fill-opacity: 0; }
  .tickets-consumption-chart .highcharts-navigator-mask-inside {
    fill: #6685c2;
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize; }
  .tickets-consumption-chart .highcharts-navigator-outline {
    stroke: #cccccc;
    fill: none; }
  .tickets-consumption-chart .highcharts-navigator-handle {
    stroke: #cccccc;
    fill: #f2f2f2;
    cursor: ew-resize; }
  .tickets-consumption-chart .highcharts-navigator-series {
    fill: #335cad;
    stroke: #335cad; }
  .tickets-consumption-chart .highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.05; }
  .tickets-consumption-chart .highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: #e6e6e6; }
  .tickets-consumption-chart .highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: #999999; }
  .tickets-consumption-chart .highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-scrollbar-thumb {
    fill: #cccccc;
    stroke: #cccccc;
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-scrollbar-button {
    fill: #e6e6e6;
    stroke: #cccccc;
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-scrollbar-arrow {
    fill: #666666; }
  .tickets-consumption-chart .highcharts-scrollbar-rifles {
    stroke: #666666;
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #f2f2f2;
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-button {
    fill: #f7f7f7;
    stroke: #cccccc;
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms; }
  .tickets-consumption-chart .highcharts-button text {
    fill: #333333; }
  .tickets-consumption-chart .highcharts-button-hover {
    transition: fill 0ms;
    fill: #e6e6e6;
    stroke: #cccccc; }
  .tickets-consumption-chart .highcharts-button-hover text {
    fill: #333333; }
  .tickets-consumption-chart .highcharts-button-pressed {
    font-weight: bold;
    fill: #e6ebf5;
    stroke: #cccccc; }
  .tickets-consumption-chart .highcharts-button-pressed text {
    fill: #333333;
    font-weight: bold; }
  .tickets-consumption-chart .highcharts-button-disabled text {
    fill: #333333; }
  .tickets-consumption-chart .highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 0px; }
  .tickets-consumption-chart .highcharts-range-label rect {
    fill: none; }
  .tickets-consumption-chart .highcharts-range-label text {
    fill: #666666; }
  .tickets-consumption-chart .highcharts-range-input rect {
    fill: none; }
  .tickets-consumption-chart .highcharts-range-input text {
    fill: #333333; }
  .tickets-consumption-chart .highcharts-range-input {
    stroke-width: 1px;
    stroke: #cccccc; }
  .tickets-consumption-chart input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */ }
  .tickets-consumption-chart .highcharts-crosshair-label text {
    fill: #ffffff;
    font-size: 1.1em; }
  .tickets-consumption-chart .highcharts-crosshair-label .highcharts-label-box {
    fill: inherit; }
  .tickets-consumption-chart .highcharts-candlestick-series .highcharts-point {
    stroke: #000000;
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-candlestick-series .highcharts-point-up {
    fill: #ffffff; }
  .tickets-consumption-chart .highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px; }
  .tickets-consumption-chart .highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: #999999;
    fill: #ffffff;
    transition: fill 250ms; }
  .tickets-consumption-chart .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: #000000;
    fill: #ccd6eb; }
  .tickets-consumption-chart .highcharts-flags-series .highcharts-point text {
    fill: #000000;
    font-size: 0.9em;
    font-weight: bold; }
  .tickets-consumption-chart .highcharts-map-series .highcharts-point {
    transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
    stroke: #cccccc; }
  .tickets-consumption-chart .highcharts-map-series .highcharts-point-hover {
    transition: fill 0ms, fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px; }
  .tickets-consumption-chart .highcharts-mapline-series .highcharts-point {
    fill: none; }
  .tickets-consumption-chart .highcharts-heatmap-series .highcharts-point {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center; }
  .tickets-consumption-chart .highcharts-coloraxis {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-coloraxis-marker {
    fill: #999999; }
  .tickets-consumption-chart .highcharts-null-point {
    fill: #f7f7f7; }
  .tickets-consumption-chart .highcharts-3d-frame {
    fill: transparent; }
  .tickets-consumption-chart .highcharts-contextbutton {
    fill: #ffffff;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round; }
  .tickets-consumption-chart .highcharts-contextbutton:hover {
    fill: #e6e6e6;
    stroke: #e6e6e6; }
  .tickets-consumption-chart .highcharts-button-symbol {
    stroke: #666666;
    stroke-width: 3px; }
  .tickets-consumption-chart .highcharts-menu {
    border: 1px solid #999999;
    background: #ffffff;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888; }
  .tickets-consumption-chart .highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: #333333;
    cursor: pointer;
    transition: background 250ms, color 250ms; }
  .tickets-consumption-chart .highcharts-menu-item:hover {
    background: #335cad;
    color: #ffffff; }
  .tickets-consumption-chart .highcharts-drilldown-point {
    cursor: pointer; }
  .tickets-consumption-chart .highcharts-drilldown-data-label text,
  .tickets-consumption-chart text.highcharts-drilldown-data-label,
  .tickets-consumption-chart .highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: #003399;
    font-weight: bold;
    text-decoration: underline; }
  .tickets-consumption-chart .highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: #666666; }
  .tickets-consumption-chart .highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px; }
  .tickets-consumption-chart .highcharts-bullet-target {
    stroke-width: 0; }
  .tickets-consumption-chart .highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: #333333; }
  .tickets-consumption-chart .highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: #333333; }
  .tickets-consumption-chart .highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: #000000;
    fill: #000000;
    fill-opacity: 0.75; }
  .tickets-consumption-chart .highcharts-annotation-label text {
    fill: #e6e6e6; }
  .tickets-consumption-chart .highcharts-treegrid-node-collapsed, .tickets-consumption-chart .highcharts-treegrid-node-expanded {
    cursor: pointer; }
  .tickets-consumption-chart .highcharts-point-connecting-path {
    fill: none; }
  .tickets-consumption-chart .highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px; }
  .tickets-consumption-chart .highcharts-background {
    fill: #f5f5f5; }
  .tickets-consumption-chart .highcharts-markers .highcharts-point {
    fill: #ffffff;
    stroke-width: 2;
    stroke: #ee7202; }
  .tickets-consumption-chart .highcharts-markers .highcharts-point-select {
    fill: #ee7202 !important;
    stroke: #ffffff !important; }
  .tickets-consumption-chart .highcharts-column-series .highcharts-point-select {
    fill: #262626 !important;
    stroke: #262626 !important; }
  .tickets-consumption-chart .highcharts-series-hover {
    cursor: pointer; }
  .tickets-consumption-chart .highcharts-axis-line {
    stroke: #9a9a9a; }
  .tickets-consumption-chart .highcharts-axis-labels {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9a9a9a; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("~slick-carousel/slick/ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "data4-webfont";
    font-size: 20px;
    line-height: 1;
    color: #262626;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "data4-webfont";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.forfait-period-slider-container {
  background-color: #f5f5f5; }

.forfait-period-slider.slick-initialized .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .forfait-period-slider.slick-initialized .slick-slide.slick-disabled {
    cursor: not-allowed; }
  .forfait-period-slider.slick-initialized .slick-slide.slick-invisible {
    visibility: hidden; }

.forfait-period-slider .slick-track {
  display: flex;
  justify-content: center; }

.forfait-period-slider .slick-disabled {
  opacity: 0.5; }

.forfait-period-slider .slick-slide {
  position: relative;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 #0000001a;
  padding: 10px 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 74px;
  min-width: 154px; }
  .forfait-period-slider .slick-slide.slick-center {
    box-shadow: 0 6px 12px 0 #b2b2b2; }
    .forfait-period-slider .slick-slide.slick-center .slick-prev,
    .forfait-period-slider .slick-slide.slick-center .slick-next {
      display: none; }
    .forfait-period-slider .slick-slide.slick-center .tickets-information {
      display: block; }
  .forfait-period-slider .slick-slide .tickets-information {
    display: none; }
  .forfait-period-slider .slick-slide .content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
  .forfait-period-slider .slick-slide + .slick-slide {
    margin-left: 10px; }

.forfait-period-slider .slick-prev, .forfait-period-slider .slick-next {
  position: relative;
  transform: none; }

.forfait-period-slider .slick-prev {
  left: auto;
  margin-right: 10px; }

.forfait-period-slider .slick-next {
  right: auto;
  margin-left: 10px; }

.forfait-period-slider .disabled.slick-prev, .forfait-period-slider .disabled.slick-next {
  opacity: 0.65;
  cursor: not-allowed; }

.nav-tabs {
  min-height: 45px;
  display: flex;
  align-items: flex-end;
  border: none;
  flex-wrap: nowrap; }
  .nav-tabs .nav-item {
    margin-top: 12px;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center; }
    .nav-tabs .nav-item .nav-link {
      background-color: #ffffff;
      color: #424242;
      border: none;
      border-radius: 8px 8px 0 0;
      padding: 10px 25px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch; }
      .nav-tabs .nav-item .nav-link.active {
        margin-top: -12px;
        background-color: #424242;
        color: #ffffff; }
        .nav-tabs .nav-item .nav-link.active:hover {
          color: #e9ecef; }
  .nav-tabs + .tab-content {
    margin-top: 20px; }

.nav-selector .form-select .dropdown-select {
  background-color: #ffffff; }
  .nav-selector .form-select .dropdown-select .btn-select {
    background-color: #ffffff; }

.nav-selector .form-select .form-select-menu {
  z-index: 1000; }

.nav-selector .form-select__control--is-focused {
  border: 1px solid #424242 !important; }
  .nav-selector .form-select__control--is-focused:hover {
    border-color: #424242 !important; }

.nav-selector .form-select__option.form-select-option:active {
  background-color: #424242;
  color: #ffffff; }

.nav-selector .form-select__option--is-focused.form-select-option {
  color: #424242;
  background-color: #f8f9fa; }

.nav-selector .form-select__option--is-selected.form-select-option {
  color: #ffffff;
  background-color: #424242; }

.date-selector {
  display: flex;
  max-width: 265px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ecef;
  padding: 4px 6px;
  height: 40px;
  font-weight: 500; }
  .date-selector .date-selector-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center; }
  .date-selector .active {
    border-radius: 2px;
    background: #FFC900; }
  @media (max-width: 991px) {
    .date-selector {
      max-width: none; } }

.table-container {
  background-color: #ffffff;
  padding: 10px 20px; }
  .table-container + .table-container {
    margin-top: 10px; }
  .table-container table {
    background-color: #4C4C4CCC;
    margin: 0 !important; }
    .table-container table thead th {
      border-top: none;
      border-bottom: none;
      padding: 10px 20px;
      color: #ffffff; }
      .table-container table thead th.clickable-table-head {
        cursor: pointer; }
        .table-container table thead th.clickable-table-head .icon {
          color: #ffffff; }
    .table-container table tbody {
      background-color: #ffffff; }
      .table-container table tbody td {
        padding: 10px 20px;
        vertical-align: middle; }
        .table-container table tbody td .primary-text + * {
          margin-top: 5px; }
    .table-container table .clickable-table-row {
      cursor: pointer; }
      .table-container table .clickable-table-row:hover {
        background-color: #f5f5f5; }
    .table-container table .inner-table-cell {
      min-height: 44px;
      display: flex;
      align-items: center; }
      .table-container table .inner-table-cell.menu-cell {
        justify-content: flex-end; }
    .table-container table .btn-tooltip {
      margin-right: 10px; }
    .table-container table .dropdown-item:active {
      color: #ffffff; }
    .table-container table .btn-download {
      padding-left: 0;
      padding-right: 10px;
      color: #262626; }
      .table-container table .btn-download .icon {
        width: 20px;
        height: 20px; }
    .table-container table .dropdown-single .btn-select {
      padding: 0 10px; }
  .table-container .pagination {
    display: flex;
    justify-content: flex-end; }

table {
  margin: 0 !important; }
  table.scrollable {
    background-color: #4C4C4CCC; }
    table.scrollable thead th {
      color: #ffffff !important; }
    table.scrollable thead tr {
      display: block; }
    table.scrollable tbody {
      display: block;
      max-height: 200px;
      overflow-y: auto;
      width: 100%;
      background-color: #ffffff; }

.hosting-spaces .head {
  margin-bottom: 20px; }

.hosting-spaces table thead th {
  border: none;
  width: 200px; }

.hosting-spaces table tbody td {
  vertical-align: middle;
  width: 200px; }
  .hosting-spaces table tbody td span + span {
    margin-left: 10px; }

@media (min-width: 1px) and (max-width: 767px) {
  .table-container table thead th, .table-container table tbody td {
    padding-left: 5px;
    padding-right: 5px; } }

.groupingSpaces > .table-container {
  padding: 0; }

.access-detail .request-access-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .access-detail .request-access-title .head {
    flex: 1; }

.access-detail .access-information {
  margin-bottom: 40px; }

.delivery-instructions .title + .content {
  margin-top: 10px; }

.delivery-instructions .contact + .contact {
  margin-top: 15px; }

.delivery-instructions .contact + .comment {
  margin-top: 20px; }

.delivery-instructions .instructions-title {
  text-transform: uppercase; }

.two-fa-information {
  display: flex;
  justify-content: space-between; }
  .two-fa-information > * :first-child {
    margin-bottom: 20px; }

.two-fa-instructions {
  margin-bottom: 20px; }

.two-fa-container {
  flex-direction: row !important; }

.two-fa-textfield {
  width: 60px;
  height: 80px;
  margin-right: 5px;
  padding: 20px;
  text-align: center;
  font-size: 20px !important; }

.modal .two-fa-instructions {
  margin-bottom: 0; }

.modal .two-fa-qr-code {
  display: flex;
  justify-content: center; }

@media (min-width: 1px) and (max-width: 991px) {
  .two-fa-textfield {
    padding: 10px;
    font-size: 14px !important; }
  .two-fa-information {
    flex-direction: column; }
  .two-fa-qr-code {
    display: flex;
    justify-content: center; } }

.cgu-locked-navigation .cgu-locked-label {
  color: #ffffff; }

@media (max-width: 767px) {
  .cgu-locked-navigation {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px; }
    .cgu-locked-navigation .nav-left {
      width: 80%; } }

.recurrent-remote-hands-detail .service-label {
  margin-bottom: 10px; }

.recurrent-remote-hands-detail .hosting-spaces {
  margin-top: 20px; }

#main.non-connected {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: url("/resources/img/background.jpg") top center no-repeat;
  background-size: cover; }
  #main.non-connected .app-version {
    position: absolute;
    left: 15px;
    top: 0; }
    #main.non-connected .app-version + .full-height {
      padding-top: 20px; }
  #main.non-connected .login-md-title {
    width: 100%;
    padding-right: 35px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;
    color: black; }
  #main.non-connected .login-title-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between; }
  #main.non-connected .login-big-title {
    margin-top: 5%;
    text-align: center;
    font-size: 25px;
    color: black; }
  #main.non-connected .margin-login {
    margin: auto;
    display: flex;
    flex-direction: column; }
  #main.non-connected .display-login-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 100vh; }
  #main.non-connected .login-right {
    width: 55%;
    min-height: 100vh;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  #main.non-connected .footer-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 55%;
    position: fixed;
    bottom: 0; }
  #main.non-connected .login-left {
    width: 45%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  #main.non-connected .footer-left {
    font-size: 17px;
    position: fixed;
    bottom: 0;
    text-align: center; }
  #main.non-connected .half-bg {
    max-width: 50%;
    background: rgba(255, 255, 255, 0.85); }
  #main.non-connected .full-bg {
    background: rgba(255, 255, 255, 0.85); }
  #main.non-connected .full-height {
    height: 100%; }
  #main.non-connected img.logo {
    display: block;
    width: 50%; }
  #main.non-connected .link-box {
    margin-top: 20px;
    padding-top: 20px;
    width: 150px;
    border-top: 2px solid #9a9a9a;
    text-align: center; }
    #main.non-connected .link-box > a:hover {
      text-decoration: none;
      color: #262626; }

.login-form .password-forgotten {
  cursor: pointer; }

.login-form-password {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: #dcdcdc;
  height: 30px;
  padding: 0; }
  .login-form-password .hide-password-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 10px;
    z-index: 2;
    right: 5px; }
    .login-form-password .hide-password-btn:hover {
      color: #eb2629; }

.paper-login {
  width: 300px;
  min-height: 215px;
  box-shadow: none !important; }
  .paper-login .modal {
    position: relative;
    display: block; }
  .paper-login .modal-dialog {
    margin: 0;
    pointer-events: auto; }

.paper-reset-password {
  box-shadow: none !important; }

.empty-body {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  justify-content: center;
  align-items: center; }
  .empty-body .icon {
    font-size: 41px;
    margin-bottom: 10px; }

.login-footer {
  background: transparent !important;
  height: auto !important;
  display: flex;
  justify-content: center; }
  .login-footer > :not(:first-child) {
    margin-left: 20px; }
  .login-footer > :not(:last-child) {
    margin-right: 0; }

@media (min-width: 1px) and (max-width: 991px) {
  .login-footer {
    flex-direction: column; }
    .login-footer > * {
      margin-right: 0 !important;
      margin-left: 0 !important; } }

@media (max-width: 991px) and (orientation: landscape) {
  .full-bg, .login-right {
    overflow: scroll; }
  .footer-right {
    display: none !important; } }

@media (max-width: 767px) {
  .display-login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; }
  .login-big-title, .login-md-title {
    margin-top: 5% !important; }
  .login-right {
    width: 100% !important;
    justify-content: flex-start !important; }
  .footer-right {
    width: 300px !important; }
  .footer-left {
    font-size: 14px !important; }
  .login-left {
    width: 100% !important;
    height: 40% !important; } }

.modify-tickets .information-label + .btn {
  margin-top: 10px; }

.modify-tickets .customized-tickets {
  display: flex;
  align-items: center; }

.modify-tickets .form-input {
  margin: 0 5px; }

@media (min-width: 1px) and (max-width: 991px) {
  .modify-tickets .customized-tickets {
    flex-direction: column;
    align-items: flex-start; }
  .modify-tickets .form-input {
    margin: 0 5px 0 0; } }

.dropdown-item {
  display: flex;
  padding-right: 20px;
  padding-left: 20px; }
  .dropdown-item span {
    margin: auto 0; }

.nav-account .dropdown-menu {
  transform: translate3d(0, 30px, 0) !important; }

.mgn-l-10 {
  margin-left: 10px; }

.icon {
  vertical-align: middle; }

.nav-account {
  position: relative; }
  .nav-account .badge {
    padding: 0 10px !important;
    min-width: inherit;
    border: none;
    background: none !important; }
    .nav-account .badge .icon {
      font-size: 24px !important;
      transform: rotate(0) !important; }
  .nav-account .badge[aria-expanded="true"] {
    background: #ffffff !important; }
    .nav-account .badge[aria-expanded="true"] .icon {
      color: #262626 !important;
      transform: rotate(0) !important; }
  .nav-account .badge[aria-expanded="true"]:hover:after {
    display: none !important;
    background: none !important; }
  .nav-account.active .badge::before {
    content: '';
    display: block;
    z-index: 10;
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 10px;
    right: 10px;
    top: 5px;
    background: #ffffff; }
  .nav-account.active .badge[aria-expanded="true"]::before {
    background: #262626; }

.demandes-en-cours {
  z-index: 10; }
  .demandes-en-cours .table {
    table-layout: fixed; }
    .demandes-en-cours .table thead {
      background-color: #4C4C4CCC; }
      .demandes-en-cours .table thead th {
        color: #f5f5f5; }
    .demandes-en-cours .table tbody {
      background-color: #fcfcfc;
      border: 1px solid #f5f5f5; }
      .demandes-en-cours .table tbody tr {
        white-space: nowrap;
        cursor: pointer; }
        .demandes-en-cours .table tbody tr:hover {
          background-color: #ffffff; }
        .demandes-en-cours .table tbody tr td {
          overflow: hidden;
          text-overflow: ellipsis; }

.tab-content > .active {
  display: block;
  width: 100%;
  height: 100%; }

.no-border {
  border: none !important; }

.font-14 {
  font-size: 14px; }

.font-12 {
  font-size: 12px; }

.modal .modal-dialog.modal-xl {
  width: 778px; }

.form-standard .form-group .form-error .form-control {
  border-color: #ee7202; }

.form-standard .form-group .form-error .form-control + .notification {
  color: #ee7202; }

.appbar-utilisateur .d-mobile {
  border: none !important; }

/* problème sur le dashboard : pour que la colonne de droite soit toujours à la même
hauteur que la colonne de gauche */
.fixme-001 {
  height: 100%; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

.mb-d4 {
  margin-bottom: 20px; }

html, body, #app {
  height: 100%;
  overflow-y: hidden; }

#app {
  display: flex;
  flex-direction: column; }

#main {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  #main.no-scroll {
    overflow-y: hidden; }

.no-scroll #main {
  overflow-y: hidden; }

/**************** NAV ***********************/
.nav-search form {
  display: inherit; }

.nav-search .search-dropdown .options {
  display: none; }
  .nav-search .search-dropdown .options.toggled.dropdown {
    display: flex; }

/**************** TEXT ***********************/
.nouveau-visiteur .form-input .form-label {
  font-weight: 500; }

/**************** LAYOUT ***********************/
.paper + .paper {
  margin-top: 10px; }

.paper + .form-validation {
  margin-top: 20px; }

.paper .head + .body .card {
  border: none;
  border-radius: 0;
  border-top: 1px solid #dcdcdc; }
  .paper .head + .body .card .card-body {
    padding: 20px; }

.form-standard .head {
  display: flex;
  align-items: center; }
  .form-standard .head > *:first-child {
    flex: 1; }
  .form-standard .head .form-group {
    margin-bottom: 0; }
    .form-standard .head .form-group + .btn {
      margin-left: 30px; }

.localisation-collapse-item {
  display: flex;
  align-items: center; }
  .localisation-collapse-item > .form-group:first-child {
    margin-bottom: 0; }
  .localisation-collapse-item > .form-group + .link:before {
    content: " ";
    white-space: pre; }
  .localisation-collapse-item + .localisation-collapse-group {
    margin-left: 25px; }
    .localisation-collapse-item + .localisation-collapse-group .form-group {
      margin-bottom: 0; }

.main-navigation + .main-content {
  margin-top: 50px; }

/**************** FORM ***********************/
.form-standard .form-full .group-form-control {
  width: 100%; }

.form-standard .form-group.date-pickers {
  align-items: start;
  flex-direction: column;
  margin-bottom: 10px; }

.form-standard .form-group.inline.date-pickers {
  flex-direction: row; }
  .form-standard .form-group.inline.date-pickers .form-input.form-group:last-child::before {
    content: '-';
    color: #595959;
    margin: 15px auto 0;
    text-align: center;
    position: relative;
    width: 25px; }

.form-standard .form-group.inline .group-checkbox {
  display: flex; }
  .form-standard .form-group.inline .group-checkbox .form-checkbox + .form-checkbox {
    margin-left: 30px; }

.form-standard .form-group .rdt .form-control[readonly] {
  background-color: #ffffff; }

.form-standard .form-date-picker.fixed-position .rdtPicker {
  position: fixed; }

.form-standard .form-radio.inline {
  flex-direction: row; }
  .form-standard .form-radio.inline .pretty + .pretty {
    margin-left: 30px; }

.form-upload-file {
  flex-direction: row;
  align-items: center; }
  .form-upload-file .form-data + .upload-file {
    margin-top: 10px; }
  .form-upload-file .upload-file {
    display: flex;
    align-items: center; }
  .form-upload-file .btn-delete-file {
    height: 14px;
    margin: 0;
    padding-left: 5px;
    padding-right: 0; }
    .form-upload-file .btn-delete-file .icon {
      font-size: 14px; }

/**************** LINK ***********************/
.link.link-primary {
  font-size: 14px;
  font-weight: 500; }
  .link.link-primary .label {
    font-size: 14px;
    font-weight: 500; }

.link:hover .label:after {
  width: 100%; }

.link.no-underline:hover .label:after {
  display: none; }

.link-icon:hover .label:after {
  width: calc(100% + 20px); }

.configure-delivery-contacts .link .label {
  font-size: inherit;
  font-weight: inherit; }

/******************** DROPDOWN ********************/
.dropdown-select {
  font-size: 14px; }
  .dropdown-select .btn-select {
    color: #595959;
    background-color: #f5f5f5;
    border-color: #e9ecef;
    padding: 0 5px 0 10px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
    height: 44px; }
    .dropdown-select .btn-select:hover {
      color: #595959;
      background-color: #ffffff;
      border-color: #e9ecef; }
    .dropdown-select .btn-select:focus, .dropdown-select .btn-select.focus {
      box-shadow: none; }
    .dropdown-select .btn-select:not(:disabled):not(.disabled):active, .dropdown-select .btn-select:not(:disabled):not(.disabled).active,
    .show > .dropdown-select .btn-select.dropdown-toggle {
      color: #595959;
      background-color: #ffffff;
      border-color: #e9ecef; }
      .dropdown-select .btn-select:not(:disabled):not(.disabled):active:focus, .dropdown-select .btn-select:not(:disabled):not(.disabled).active:focus,
      .show > .dropdown-select .btn-select.dropdown-toggle:focus {
        box-shadow: none; }
  .dropdown-select.show .btn-select {
    background-color: #ffffff; }

.request-item.dropdown-item {
  padding-right: 0;
  padding-left: 0; }
  .request-item.dropdown-item a {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px; }
    .request-item.dropdown-item a:hover {
      text-decoration: none;
      color: inherit; }
  .request-item.dropdown-item .icon {
    margin-right: 10px;
    color: #595959; }

/******************** BUTTON ********************/
.btn-auto:focus, .btn-auto.focus {
  box-shadow: none; }

.btn.disabled {
  cursor: not-allowed; }

@media (min-width: 1px) and (max-width: 991px) {
  .btn-no-border-mobile {
    border: 1px solid #ffffff !important; } }

/******************** SPINNER ********************/
.spinner-border-services {
  border-color: transparent transparent #F50500 #F50500; }

.spinner-border-sustainability {
  border-color: transparent transparent #00C484 #00C484; }

.spinner-border-management {
  border-color: transparent transparent #1211AF #1211AF; }

.spinner-border-requests {
  border-color: transparent transparent #FFC900 #FFC900; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.spinner-border::after {
  animation: spin 0.7s linear infinite; }

.spinner-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.inline-loader-container {
  display: flex;
  align-items: center;
  flex-direction: row !important; }
  .inline-loader-container > * {
    position: relative;
    right: 0;
    top: 0; }
  .inline-loader-container > .spinner-placeholder {
    margin: 5px; }
  .inline-loader-container > .form-group:not(:last-child) {
    margin-bottom: 0; }

/******************** SIDE REQUEST FILTERS ********************/
.side-filters-overlay + .bm-menu-wrap {
  width: 600px !important; }

.side-filters {
  background-color: #ffffff !important; }
  .side-filters .head .headline {
    margin-top: 0;
    margin-bottom: 0; }
  .side-filters .head .close {
    position: absolute;
    right: 20px; }
    .side-filters .head .close .icon {
      font-size: 20px; }
  .side-filters .paper-form {
    padding: 20px;
    border-bottom: 1px solid #dcdcdc; }
    .side-filters .paper-form + .form-validation {
      margin-top: 30px;
      padding-right: 20px;
      padding-left: 20px; }
  .side-filters .form-standard > * {
    color: initial; }

.filter-active {
  color: #ffffff !important;
  background-color: #FFC900 !important; }

/******************** DETAIL DEMANDES ********************/
.visitor-information .email + .company:before {
  content: " - "; }

.visitor-information .telephone + .vehicule:before {
  content: " - "; }

/******************** CONSIGNES LIVRAISON ********************/
.move-arrows {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .move-arrows .move-up, .move-arrows .move-down {
    height: auto;
    padding: 0 20px 0 0;
    margin: 0; }

/******************** ALERTE D'URGENCE ********************/
.alert-emergency {
  background-color: #262626;
  color: #ffffff; }

/******************** ICONE ********************/
.text-icon {
  display: flex;
  align-items: center; }
  .text-icon.text-right-icon .icon {
    margin-right: 10px; }
  .text-icon.text-left-icon .icon {
    margin-left: 10px; }

/**************** Ce bloc doit être mis en dernier ***********************/
/**************** RESPONSIVE ***********************/
@media (min-width: 1px) and (max-width: 991px) {
  .side-filters-overlay + .bm-menu-wrap {
    width: 100% !important; }
  .stepper-desk {
    display: none; }
  .stepper-mobile {
    display: flex; }
  .form-standard .form-radio.inline {
    flex-direction: column; }
    .form-standard .form-radio.inline .pretty + .pretty {
      margin-left: 0; }
  .form-standard .form-group.inline.date-pickers {
    flex-direction: column; }
    .form-standard .form-group.inline.date-pickers .form-input.form-group:last-child::before {
      margin-top: 0; }
  .form-standard .form-group.inline .group-checkbox {
    flex-direction: column; }
    .form-standard .form-group.inline .group-checkbox .form-checkbox + .form-checkbox {
      margin-left: 0; }
  .main-breadcrumb .breadcrumb li.d-desktop {
    display: none; }
  .main-breadcrumb .d-mobile .request-item.dropdown-item .icon {
    display: none; }
  .modal .modal-dialog .modal-content {
    height: 100%;
    overflow: scroll; }
  .select-service .form-standard .head {
    flex-wrap: wrap; }
  .form-standard .head .form-group + .btn {
    margin-top: 16px;
    margin-left: 0;
    width: 100%; }
  .collapsable-element .head .titre .form-title {
    min-width: auto; } }

@media (min-width: 992px) {
  .stepper-desk {
    display: block; }
  .stepper-mobile {
    display: none; } }

.kpi-env .reports-list {
  text-transform: uppercase; }

.kpi-env .reports-list-bloc {
  margin-bottom: 10px; }

.kpi-env .kpi-icon-container {
  padding-top: 5px; }

.kpi-env .kpi-icon {
  color: #F50500;
  font-size: 40px; }

.kpi-env .icon-kpi-eutrophisation {
  font-size: 30px; }

@media (min-width: 768px) {
  .kpi-env .kpi-dropdown {
    display: flex;
    justify-content: flex-end; } }

@media (max-width: 767px) {
  .kpi-env .kpi-dropdown {
    margin-top: 10px; } }

@media (max-width: 767px) {
  .kpi-env .kpi-chart {
    display: none; } }

.kpi-env .impact-category {
  padding: 20px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 -5px; }
  .kpi-env .impact-category:last-child {
    padding-bottom: 0; }
  .kpi-env .impact-category + .impact-category {
    border-top: 1px solid #dcdcdc; }

.kpi-env .impacts-text-container {
  flex: 0.6; }

.kpi-env .impacts-text {
  margin-bottom: 10px; }

.kpi-env .report-datacenter-block {
  margin-top: 20px; }
  .kpi-env .report-datacenter-block .report-datacenter-row:not(:first-child) {
    padding-top: 18px; }
  .kpi-env .report-datacenter-block .report-datacenter-row:not(:last-child) {
    padding-bottom: 18px;
    border-bottom: 1px solid #dcdcdc; }
  .kpi-env .report-datacenter-block .content {
    display: flex;
    flex-direction: row; }
  .kpi-env .report-datacenter-block .indicator {
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 18px 10px; }
    .kpi-env .report-datacenter-block .indicator-divider {
      border-left: 1px solid #dcdcdc; }
  .kpi-env .report-datacenter-block .data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px; }
    @media (max-width: 767px) {
      .kpi-env .report-datacenter-block .data {
        flex-direction: column;
        gap: 20px; } }

.kpi-certificates .kpi-certificates-bloc {
  margin-top: 20px; }

@media (min-width: 1px) and (max-width: 991px) {
  .kpi-certificates .kpi-certificate + .kpi-certificate {
    margin-top: 20px; } }

.kpi-lifetime-chart {
  /**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
  /* Titles */
  /* Axes */
  /* Credits */
  /* Tooltip */
  /* Legend hover affects points and series */
  /* Series options */
  /* Default colors */
  /* Legend */
  /* Bubble legend */
  /* Loading */
  /* Plot bands and polar pane backgrounds */
  /* Highcharts More and modules */
  /* Highstock */
  /* Highmaps */
  /* 3d charts */
  /* Exporting module */
  /* Drilldown module */
  /* No-data module */
  /* Drag-panes module */
  /* Bullet type series */
  /* Lineargauge type series */
  /* Annotations module */
  /* Gantt */ }
  .kpi-lifetime-chart .highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    /* #1072 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Roboto", sans-serif;
    font-size: 12px; }
  .kpi-lifetime-chart .highcharts-root {
    display: block; }
  .kpi-lifetime-chart .highcharts-root text {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-strong {
    font-weight: bold; }
  .kpi-lifetime-chart .highcharts-emphasized {
    font-style: italic; }
  .kpi-lifetime-chart .highcharts-anchor {
    cursor: pointer; }
  .kpi-lifetime-chart .highcharts-background {
    fill: #ffffff; }
  .kpi-lifetime-chart .highcharts-plot-border, .kpi-lifetime-chart .highcharts-plot-background {
    fill: none; }
  .kpi-lifetime-chart .highcharts-label-box {
    fill: none; }
  .kpi-lifetime-chart .highcharts-button-box {
    fill: inherit; }
  .kpi-lifetime-chart .highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none; }
  .kpi-lifetime-chart .highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-title {
    fill: #333333;
    font-size: 1.5em; }
  .kpi-lifetime-chart .highcharts-subtitle {
    fill: #666666; }
  .kpi-lifetime-chart .highcharts-axis-line {
    fill: none;
    stroke: #ccd6eb; }
  .kpi-lifetime-chart .highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-axis-title {
    fill: #666666; }
  .kpi-lifetime-chart .highcharts-axis-labels {
    fill: #666666;
    cursor: default;
    font-size: 0.9em; }
  .kpi-lifetime-chart .highcharts-grid-line {
    fill: none;
    stroke: #e6e6e6; }
  .kpi-lifetime-chart .highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0px; }
  .kpi-lifetime-chart .highcharts-tick {
    stroke: #ccd6eb; }
  .kpi-lifetime-chart .highcharts-yaxis .highcharts-tick {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-minor-grid-line {
    stroke: #f2f2f2; }
  .kpi-lifetime-chart .highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: #cccccc; }
  .kpi-lifetime-chart .highcharts-crosshair-category {
    stroke: #ccd6eb;
    stroke-opacity: 0.25; }
  .kpi-lifetime-chart .highcharts-credits {
    cursor: pointer;
    fill: #999999;
    font-size: 0.7em;
    transition: fill 250ms, font-size 250ms; }
  .kpi-lifetime-chart .highcharts-credits:hover {
    fill: black;
    font-size: 1em; }
  .kpi-lifetime-chart .highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms; }
  .kpi-lifetime-chart .highcharts-tooltip text {
    fill: #333333; }
  .kpi-lifetime-chart .highcharts-tooltip .highcharts-header {
    font-size: 0.85em; }
  .kpi-lifetime-chart .highcharts-tooltip-box {
    stroke-width: 1px;
    fill: #f7f7f7;
    fill-opacity: 0.85; }
  .kpi-lifetime-chart .highcharts-tooltip-box .highcharts-label-box {
    fill: #f7f7f7;
    fill-opacity: 0.85; }
  .kpi-lifetime-chart .highcharts-selection-marker {
    fill: #335cad;
    fill-opacity: 0.25; }
  .kpi-lifetime-chart .highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round; }
  .kpi-lifetime-chart .highcharts-state-hover .highcharts-graph {
    stroke-width: 3; }
  .kpi-lifetime-chart .highcharts-state-hover path {
    transition: stroke-width 50;
    /* quick in */ }
  .kpi-lifetime-chart .highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */ }
  .kpi-lifetime-chart g.highcharts-series,
  .kpi-lifetime-chart .highcharts-point,
  .kpi-lifetime-chart .highcharts-markers,
  .kpi-lifetime-chart .highcharts-data-labels {
    transition: opacity 250ms; }
  .kpi-lifetime-chart .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
  .kpi-lifetime-chart .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
  .kpi-lifetime-chart .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
  .kpi-lifetime-chart .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.2; }
  .kpi-lifetime-chart .highcharts-color-0 {
    fill: #262626;
    stroke: #262626; }
  .kpi-lifetime-chart .highcharts-color-1 {
    fill: #e9ecef;
    stroke: #e9ecef; }
  .kpi-lifetime-chart .highcharts-color-2 {
    fill: #04a583;
    stroke: #04a583; }
  .kpi-lifetime-chart .highcharts-area {
    fill-opacity: 0.75;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-markers {
    stroke-width: 1px;
    stroke: #ffffff; }
  .kpi-lifetime-chart .highcharts-point {
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-dense-data .highcharts-point {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold; }
  .kpi-lifetime-chart .highcharts-data-label-box {
    fill: none;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-data-label text, .kpi-lifetime-chart text.highcharts-data-label {
    fill: #333333; }
  .kpi-lifetime-chart .highcharts-data-label-connector {
    fill: none; }
  .kpi-lifetime-chart .highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
  .kpi-lifetime-chart .highcharts-markers .highcharts-point-select {
    fill: #cccccc;
    stroke: #000000; }
  .kpi-lifetime-chart .highcharts-column-series rect.highcharts-point {
    stroke: #ffffff; }
  .kpi-lifetime-chart .highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms; }
  .kpi-lifetime-chart .highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .kpi-lifetime-chart .highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #ffffff; }
  .kpi-lifetime-chart .highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .kpi-lifetime-chart .highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #ffffff; }
  .kpi-lifetime-chart .highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .kpi-lifetime-chart .highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit; }
  .kpi-lifetime-chart .highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #ffffff; }
  .kpi-lifetime-chart .highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms; }
  .kpi-lifetime-chart .highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit; }
  .kpi-lifetime-chart .highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: #e6e6e6;
    transition: stroke 250ms, fill 250ms, fill-opacity 250ms; }
  .kpi-lifetime-chart .highcharts-treemap-series .highcharts-point-hover {
    stroke: #999999;
    transition: stroke 25ms, fill 25ms, fill-opacity 25ms; }
  .kpi-lifetime-chart .highcharts-treemap-series .highcharts-above-level {
    display: none; }
  .kpi-lifetime-chart .highcharts-treemap-series .highcharts-internal-node {
    fill: none; }
  .kpi-lifetime-chart .highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer; }
  .kpi-lifetime-chart .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75; }
  .kpi-lifetime-chart .highcharts-legend-box {
    fill: none;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-legend-item > text {
    fill: #333333;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-legend-item:hover text {
    fill: #000000; }
  .kpi-lifetime-chart .highcharts-legend-item-hidden * {
    fill: #cccccc !important;
    stroke: #cccccc !important;
    transition: fill 250ms; }
  .kpi-lifetime-chart .highcharts-legend-nav-active {
    fill: #003399;
    cursor: pointer; }
  .kpi-lifetime-chart .highcharts-legend-nav-inactive {
    fill: #cccccc; }
  .kpi-lifetime-chart circle.highcharts-legend-nav-active, .kpi-lifetime-chart circle.highcharts-legend-nav-inactive {
    /* tracker */
    fill: rgba(192, 192, 192, 0.0001); }
  .kpi-lifetime-chart .highcharts-legend-title-box {
    fill: none;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5; }
  .kpi-lifetime-chart .highcharts-bubble-legend-connectors {
    stroke-width: 1; }
  .kpi-lifetime-chart .highcharts-bubble-legend-labels {
    fill: #333333; }
  .kpi-lifetime-chart .highcharts-loading {
    position: absolute;
    background-color: #ffffff;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms; }
  .kpi-lifetime-chart .highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end; }
  .kpi-lifetime-chart .highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%; }
  .kpi-lifetime-chart .highcharts-plot-band, .kpi-lifetime-chart .highcharts-pane {
    fill: #000000;
    fill-opacity: 0.05; }
  .kpi-lifetime-chart .highcharts-plot-line {
    fill: none;
    stroke: #999999;
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-boxplot-box {
    fill: #ffffff; }
  .kpi-lifetime-chart .highcharts-boxplot-median {
    stroke-width: 2px; }
  .kpi-lifetime-chart .highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5; }
  .kpi-lifetime-chart .highcharts-errorbar-series .highcharts-point {
    stroke: #000000; }
  .kpi-lifetime-chart .highcharts-gauge-series .highcharts-data-label-box {
    stroke: #cccccc;
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-gauge-series .highcharts-dial {
    fill: #000000;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-waterfall-series .highcharts-graph {
    stroke: #333333;
    stroke-dasharray: 1, 3; }
  .kpi-lifetime-chart .highcharts-sankey-series .highcharts-point {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-sankey-series .highcharts-link {
    transition: fill 250ms, fill-opacity 250ms;
    fill-opacity: 0.5; }
  .kpi-lifetime-chart .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition: fill 50ms, fill-opacity 50ms;
    fill-opacity: 1; }
  .kpi-lifetime-chart .highcharts-venn-series .highcharts-point {
    fill-opacity: 0.75;
    stroke: #cccccc;
    transition: stroke 250ms, fill-opacity 250ms; }
  .kpi-lifetime-chart .highcharts-venn-series .highcharts-point-hover {
    fill-opacity: 1;
    stroke: #cccccc; }
  .kpi-lifetime-chart .highcharts-navigator-mask-outside {
    fill-opacity: 0; }
  .kpi-lifetime-chart .highcharts-navigator-mask-inside {
    fill: #6685c2;
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize; }
  .kpi-lifetime-chart .highcharts-navigator-outline {
    stroke: #cccccc;
    fill: none; }
  .kpi-lifetime-chart .highcharts-navigator-handle {
    stroke: #cccccc;
    fill: #f2f2f2;
    cursor: ew-resize; }
  .kpi-lifetime-chart .highcharts-navigator-series {
    fill: #335cad;
    stroke: #335cad; }
  .kpi-lifetime-chart .highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.05; }
  .kpi-lifetime-chart .highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: #e6e6e6; }
  .kpi-lifetime-chart .highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: #999999; }
  .kpi-lifetime-chart .highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-scrollbar-thumb {
    fill: #cccccc;
    stroke: #cccccc;
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-scrollbar-button {
    fill: #e6e6e6;
    stroke: #cccccc;
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-scrollbar-arrow {
    fill: #666666; }
  .kpi-lifetime-chart .highcharts-scrollbar-rifles {
    stroke: #666666;
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #f2f2f2;
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-button {
    fill: #f7f7f7;
    stroke: #cccccc;
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms; }
  .kpi-lifetime-chart .highcharts-button text {
    fill: #333333; }
  .kpi-lifetime-chart .highcharts-button-hover {
    transition: fill 0ms;
    fill: #e6e6e6;
    stroke: #cccccc; }
  .kpi-lifetime-chart .highcharts-button-hover text {
    fill: #333333; }
  .kpi-lifetime-chart .highcharts-button-pressed {
    font-weight: bold;
    fill: #e6ebf5;
    stroke: #cccccc; }
  .kpi-lifetime-chart .highcharts-button-pressed text {
    fill: #333333;
    font-weight: bold; }
  .kpi-lifetime-chart .highcharts-button-disabled text {
    fill: #333333; }
  .kpi-lifetime-chart .highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 0px; }
  .kpi-lifetime-chart .highcharts-range-label rect {
    fill: none; }
  .kpi-lifetime-chart .highcharts-range-label text {
    fill: #666666; }
  .kpi-lifetime-chart .highcharts-range-input rect {
    fill: none; }
  .kpi-lifetime-chart .highcharts-range-input text {
    fill: #333333; }
  .kpi-lifetime-chart .highcharts-range-input {
    stroke-width: 1px;
    stroke: #cccccc; }
  .kpi-lifetime-chart input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */ }
  .kpi-lifetime-chart .highcharts-crosshair-label text {
    fill: #ffffff;
    font-size: 1.1em; }
  .kpi-lifetime-chart .highcharts-crosshair-label .highcharts-label-box {
    fill: inherit; }
  .kpi-lifetime-chart .highcharts-candlestick-series .highcharts-point {
    stroke: #000000;
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-candlestick-series .highcharts-point-up {
    fill: #ffffff; }
  .kpi-lifetime-chart .highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px; }
  .kpi-lifetime-chart .highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: #999999;
    fill: #ffffff;
    transition: fill 250ms; }
  .kpi-lifetime-chart .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: #000000;
    fill: #ccd6eb; }
  .kpi-lifetime-chart .highcharts-flags-series .highcharts-point text {
    fill: #000000;
    font-size: 0.9em;
    font-weight: bold; }
  .kpi-lifetime-chart .highcharts-map-series .highcharts-point {
    transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
    stroke: #cccccc; }
  .kpi-lifetime-chart .highcharts-map-series .highcharts-point-hover {
    transition: fill 0ms, fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px; }
  .kpi-lifetime-chart .highcharts-mapline-series .highcharts-point {
    fill: none; }
  .kpi-lifetime-chart .highcharts-heatmap-series .highcharts-point {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center; }
  .kpi-lifetime-chart .highcharts-coloraxis {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-coloraxis-marker {
    fill: #999999; }
  .kpi-lifetime-chart .highcharts-null-point {
    fill: #f7f7f7; }
  .kpi-lifetime-chart .highcharts-3d-frame {
    fill: transparent; }
  .kpi-lifetime-chart .highcharts-contextbutton {
    fill: #ffffff;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round; }
  .kpi-lifetime-chart .highcharts-contextbutton:hover {
    fill: #e6e6e6;
    stroke: #e6e6e6; }
  .kpi-lifetime-chart .highcharts-button-symbol {
    stroke: #666666;
    stroke-width: 3px; }
  .kpi-lifetime-chart .highcharts-menu {
    border: 1px solid #999999;
    background: #ffffff;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888; }
  .kpi-lifetime-chart .highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: #333333;
    cursor: pointer;
    transition: background 250ms, color 250ms; }
  .kpi-lifetime-chart .highcharts-menu-item:hover {
    background: #335cad;
    color: #ffffff; }
  .kpi-lifetime-chart .highcharts-drilldown-point {
    cursor: pointer; }
  .kpi-lifetime-chart .highcharts-drilldown-data-label text,
  .kpi-lifetime-chart text.highcharts-drilldown-data-label,
  .kpi-lifetime-chart .highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: #003399;
    font-weight: bold;
    text-decoration: underline; }
  .kpi-lifetime-chart .highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: #666666; }
  .kpi-lifetime-chart .highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px; }
  .kpi-lifetime-chart .highcharts-bullet-target {
    stroke-width: 0; }
  .kpi-lifetime-chart .highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: #333333; }
  .kpi-lifetime-chart .highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: #333333; }
  .kpi-lifetime-chart .highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: #000000;
    fill: #000000;
    fill-opacity: 0.75; }
  .kpi-lifetime-chart .highcharts-annotation-label text {
    fill: #e6e6e6; }
  .kpi-lifetime-chart .highcharts-treegrid-node-collapsed, .kpi-lifetime-chart .highcharts-treegrid-node-expanded {
    cursor: pointer; }
  .kpi-lifetime-chart .highcharts-point-connecting-path {
    fill: none; }
  .kpi-lifetime-chart .highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px; }
  .kpi-lifetime-chart .highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px; }
  .kpi-lifetime-chart .series-incomplete .highcharts-color-0 {
    fill: #262626;
    stroke: #262626; }
  .kpi-lifetime-chart .series-valid .highcharts-color-0 {
    fill: #04a583;
    stroke: #04a583; }
  .kpi-lifetime-chart .highcharts-subtitle {
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #595959; }

.my-smart-dc-configuration .paper + .paper {
  margin-top: 20px; }

.my-smart-dc-configuration .global-lifetime {
  min-height: 245px; }

.my-smart-dc-configuration .areas .table-container {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }

.my-smart-dc-configuration .dcim-dashboard {
  height: auto;
  display: block; }
  .my-smart-dc-configuration .dcim-dashboard .mysmart-dc {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0; }

@media (min-width: 1px) and (max-width: 991px) {
  .my-smart-dc-configuration .areas .information-group {
    width: 100%; } }

/* VARIABLES D4 */
/* Colors */
/* Typography */
/* Layout */
/* Export des varibales utilisées dans les composants */
:export {
  whiteColor: #ffffff;
  red1Color: #eb2629;
  red2Color: #eb5b5d;
  red3Color: #f07c7e;
  green1Color: #00563A;
  green2Color: #009363;
  green3Color: #00C484;
  gray1Color: #595959;
  gray2Color: #9a9a9a;
  gray3Color: #dcdcdc; }
