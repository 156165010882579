/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
%rdtActive {
    background-color: $pretty--color-primary;
    color: $c-white;
    text-shadow: 0 -1px 0 $c-black-t25;
}

%rdtDisabled {
    background: none;
    color: $c-grey-variant-6;
    cursor: not-allowed !important;
}

%rdtYearMonth {
    height: 50px;
    width: 25%;
    cursor: pointer;
}

%rdtDay {
    background: $c-grey-variant-10;
    cursor: pointer;
}


.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: $c-white;
    box-shadow: 0 1px 3px $c-black-t10;
    border: 1px solid $c-grey-variant-12;
    .rdtTimeToggle {
        text-align: center;
        &:hover {
            background: $c-grey-variant-10;
            cursor: pointer;
        }
    }
    table {
        width: 100%;
        margin: 0;
    }
    td {
        text-align: center;
        height: 28px;
        cursor: pointer;
        &.rdtOld {
            color: $c-grey-variant-6;
        }
        &.rdtNew {
            color: $c-grey-variant-6;
        }
        &.rdtToday {
            position: relative;
            &:before {
                content: '';
                display: inline-block;
                border-left: 7px solid transparent;
                border-bottom: 7px solid $pretty--color-primary;
                border-top-color: $c-black-t20;
                position: absolute;
                bottom: 4px;
                right: 4px;
            }
        }
        &.rdtActive {
            @extend %rdtActive;
            &:hover {
                @extend %rdtActive;
                color: unset;
            }
            &.rdtToday:before {
                border-bottom-color: $c-white;
            }
        }
        &.rdtDisabled {
            @extend %rdtDisabled;
            &:hover {
                @extend %rdtDisabled;
            }
        }
        span {
            &.rdtOld {
                color: $c-grey-variant-6;
            }
            &.rdtDisabled {
                @extend %rdtDisabled;
                &:hover {
                    @extend %rdtDisabled;
                }
            }
        }
        &.rdtDay:hover {
            @extend %rdtDay;
        }
        &.rdtHour:hover {
            @extend %rdtDay;
        }
        &.rdtMinute:hover {
            @extend %rdtDay;
        }
        &.rdtSecond:hover {
            @extend %rdtDay;
        }
    }
    th {
        text-align: center;
        height: 28px;
        border-bottom: 1px solid $c-grey-variant-12;
        &.rdtSwitch {
            width: 100px;
        }
        &.rdtNext {
            font-size: 21px;
            vertical-align: top;
        }
        &.rdtPrev {
            font-size: 21px;
            vertical-align: top;
        }
        &.rdtDisabled {
            @extend %rdtDisabled;
            &:hover {
                @extend %rdtDisabled;
            }
        }
    }
    .dow {
        width: 14.2857%;
        border-bottom: none;
        cursor: default;
    }
    thead {
        button {
            width: 100%;
            height: 100%;
        }
        tr:first-child th {
            cursor: pointer;
            &:hover {
                background: $c-grey-variant-10;
            }
        }
    }
    tfoot {
        border-top: 1px solid $c-grey-variant-12;
    }
    button {
        border: none;
        background: none;
        cursor: pointer;
        &:hover {
            background-color: $c-grey-variant-10;
        }
    }
}
td {
    &.rdtMonth {
        @extend %rdtYearMonth;
        &:hover {
            background: $c-grey-variant-10;
        }
    }
    &.rdtYear {
        @extend %rdtYearMonth;
        &:hover {
            background: $c-grey-variant-10;
        }
    }
}
.rdtCounters {
    display: inline-block;
    > div {
        float: left;
    }
}
.rdtCounter {
    height: 100px;
    width: 40px;
    .rdtBtn {
        height: 40%;
        line-height: 40px;
        cursor: pointer;
        display: block;
        user-select: none;
        &:hover {
            background: $c-grey-variant-10;
        }
    }
    .rdtCount {
        height: 20%;
        font-size: 1.2em;
    }
}
.rdtCounterSeparator {
    line-height: 100px;
}
.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
    input {
        width: 100%;
        font-size: 1.2em;
        margin-top: 37px;
    }
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}
.rdtPrev span {
    display: block;
    user-select: none;
}
.rdtNext span {
    display: block;
    user-select: none;
}
.rdtTime td {
    cursor: default;
}

//md
@media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
    .rdtPicker {
        position: absolute;
    }
}
