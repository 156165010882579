
.link {
	.label {
		position: relative;
		@include fontSize(12px);
		font-weight: $ft-bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 2;
		letter-spacing: normal;
		text-align: right;
		margin-right: 5px;
		opacity: 1;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: -5px;
			left: 0;
			right: 0;
			height: 1px;
			width: 100%;
			opacity: 0;
			background: $c-main;
			transition: all .3s ease;
		}
	}
	.icon {
		@include fontSize(22px);
		right: 0;
		position: relative;
		opacity: 1;
		transition: all .3s ease;
	}

	&:hover {
		text-decoration: none;
		.label {
			&:after {
				width: calc(100% + 20px);
				opacity: 1;
				transition: all .3s ease;
			}
		}
		.icon {
			right: 0;
			position: relative;
			transition: all .3s ease;
		}
	}
	&:active {
		.label, .icon {
			opacity: .7;
			transition: all .1s ease;
		}
	}
}
