$icomoon-font-family: "data4-webfont" !default;
$icomoon-font-path: "../fonts/icons" !default;

$icon-menu-ticket: "\e932";
$icon-acces-badge: "\e900";
$icon-acces-cle: "\e901";
$icon-acces-digicode: "\e902";
$icon-acces-non-gere: "\e903";
$icon-appbar-aide: "\e904";
$icon-appbar-menu: "\e905";
$icon-appbar-notifications: "\e906";
$icon-appbar-pictogramme: "\e907";
$icon-appbar-rechercher: "\e908";
$icon-appbar-utilisateur: "\e909";
$icon-demande-acces: "\e94e";
$icon-demande-multiple: "\e94c";
$icon-demande-courant-faible: "\e94f";
$icon-demande-courant-fort: "\e951";
$icon-demande-geste-de-proximite: "\e949";
$icon-demande-incident: "\e94b";
$icon-demande-livraison: "\e94d";
$icon-demande-revoquer-acces: "\e94a";
$icon-demande-autre: "\e950";
$icon-icon-acces-biometrie: "\e911";
$icon-menu-consignes-livraison: "\e912";
$icon-menu-demandes: "\e913";
$icon-menu-espace-documentaire: "\e914";
$icon-menu-liste-acces-en-cours: "\e915";
$icon-menu-salles: "\e916";
$icon-menu-societes: "\e917";
$icon-menu-tableau-de-bord: "\e918";
$icon-menu-utilisateurs: "\e919";
$icon-navigation-ajouter: "\e91a";
$icon-navigation-alerte: "\e91b";
$icon-navigation-confidentiel: "\e91c";
$icon-navigation-editer: "\e91d";
$icon-navigation-equipe: "\e91e";
$icon-navigation-fermer: "\e91f";
$icon-navigation-filtrer: "\e920";
$icon-navigation-fleche-bas: "\e921";
$icon-navigation-fleche-droite: "\e922";
$icon-navigation-fleche-gauche: "\e923";
$icon-navigation-fleche-haut: "\e924";
$icon-navigation-graphique-batons: "\e925";
$icon-navigation-ko: "\e926";
$icon-navigation-localisation: "\e927";
$icon-navigation-ok: "\e928";
$icon-navigation-perte-connexion: "\e929";
$icon-navigation-planification: "\e92a";
$icon-navigation-plus: "\e92b";
$icon-navigation-statut: "\e92c";
$icon-navigation-telecharger: "\e92d";
$icon-navigation-valide: "\e92e";
$icon-page-demande: "\e92f";
$icon-page-lien: "\e930";
$icon-page-serveur: "\e931";
$navigation-carnet-d-adresses: "\e933";
$icon-page-tickets: "\e934";
$icon-page-2factors: "\e935";
$icon-navigation-temps: "\e936";
$icon-menu-planification: "\e937";
$icon-menu-kpi-environementaux: "\e938";
$icon-navigation-information: "\e939";
$icon-kpi-co2: "\e93b";
$icon-kpi-eau: "\e93c";
$icon-kpi-energie: "\e93d";
$icon-kpi-ressources: "\e93e";
$icon-kpi-eutrophisation: "\e945";
$icon-page-crossconnect: "\e93f";
$icon-menu-proximite: "\e942";
$icon-menu-kpi: "\e944";
$icon-menu-rapportparzone: "\e946";
$icon-menu-certificat: "\e941";
$icon-menu-carbone: "\e943";
$icon-chevron: "\e947";
$icon-chevronsupport: "\e948";
$icon-draganddrop: "\e952";
$icon-escort: "\e953";
$icon-visibility: "\e954";
$icon-visibility-off: "\e955";
$icon-filter: "\e956";
$icon-power-usage: "\e957";
$icon-water-usage: "\e958";
