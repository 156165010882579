.form-select {
  .form-control {
    padding: 0;
    min-height: $form-field-height;
    &:hover {
      border-color: $c-grey-variant-9;
    }
  }
  &__value-container {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  &__indicator {
    padding: 7px !important;
  }
  &__indicator-separator {
    margin-bottom: 7px !important;
    margin-top: 7px !important;
  }
  &__control--is-focused {
    border: 1px solid $c-grey-variant-3 !important;
    box-shadow: 0 3px 0px -1px $c-grey-variant-11,
    0px 5px 0px -1px $c-black-t01 !important;
    &:hover {
      border-color: $c-grey-variant-3 !important;
    }
  }
  &__option {
    &.form-select-option {
      @include fontSize(14px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: $c-grey-variant-3;
      font-family: $ft-fam-primary;
      cursor: pointer;
      &:active {
        color: $c-white;
        background-color: $c-grey-variant-3;
      }
      > * {
        padding: 0;
      }
    }
    &--is-focused {
      &.form-select-option {
        color: $c-grey-variant-3;
        background-color: $c-grey-variant-12; // bootstrap dropdown-item hover color
      }
    }
    &--is-selected {
      &.form-select-option {
        color: $c-white;
        background-color: $c-grey-variant-3;
      }
    }
  }
  &__multi-value__remove:hover {
    background-color: $c-main !important;
    color: $c-white !important;
  }
}

.form-dropdown {
  .value-container {
    padding-left: 0;
  }
  .single-value {
    @extend %text-ellipsis;
    margin: 0;
    color: $c-grey-variant-3;
  }
  .dropdown-indicator {
    padding: 0 !important;
    .icon {
      color: $c-grey-variant-3;
    }
  }
  .form-control {
    @include fontSize(14px);
    background-color: $c-grey-variant-11;
    border-color: $c-grey-variant-10;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .btn {
    border: none;
    justify-content: space-between;
    width: 100%;
    line-height: 1.71;
    height: 100% !important;
    &:hover {
      cursor: pointer;
    }
  }
}

.multi-select {
  .form-control {
    height: auto;
  }
}
