
// MediaQueries
// > Appliquer des exceptions ici.
// > Pour modifier les breakpoint générique : _variables.scss


$xs-queries : 0px    !default;
$sm-queries : 767px  !default;
$md-queries : 991px  !default;
$lg-queries : 1199px !default;
$xl-queries : 1599px !default;

body {

	//md
	@media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
		.d-desktop {
			display: none;
		}
		.d-mobile {
			display: flex;
		}
	}
	//lg
	@media (min-width: $md-queries + 1px)  {
		.d-desktop {
			display: flex;
		}
		.d-mobile {
			display: none;
		}
	}

}
