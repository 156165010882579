
.search-bar-annuaire {
	&+.liste-annuaire {
		margin-top: 20px;
	}
	.search-btn {
		height: 100%;
		display: flex;
		align-items: center;
		.btn {
			width: 100%;
		}
	}
}