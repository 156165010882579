/* VARIABLES D4 */

/* Colors */
$c-red-variant-1: #eb2629;
$c-red-variant-1-light: lighten($c-red-variant-1, 15%);
$c-data4-red: #F50500;
$c-red-variant-2: #eb5b5d;
$c-red-variant-3: #f07c7e;
$c-pink: #FF7777 !default;
$c-error: #ee7202;
$c-data4-yellow: #FFC900;
$c-yellow-variant-1: #FFC107;

$c-data4-blue: #1211AF;
$c-data4-blue-variant-t10: #1211AF1A;
$c-blue-variant-1: #2D5499 !default;
$c-blue-variant-2: #1178B3 !default;
$c-blue-variant-3: #1514ED;
$c-blue-variant-4: #F2F5FF;
$c-data4-lightblue: #7F8EFF;

$c-data4-green: #00C484;
$c-data4-green-variant-t10: #00C4841A;
$c-green-variant-1: #00563A;
$c-green-variant-2: #009363;
$c-green-variant-3: #04a583;
$c-green-variant-4: #008997 !default;


$c-main: #262626;
$c-grey-variant-1: #373a47;
$c-grey-variant-2: #424242 !default;
$c-grey-variant-3: #595959;
$c-grey-variant-4: #808080;
$c-grey-variant-5: #888888;
$c-grey-variant-6: #9a9a9a;
$c-grey-variant-7: #b2b2b2 !default;
$c-grey-variant-8: #cdcdcd;
$c-grey-variant-9: #dcdcdc;
$c-grey-variant-10: #e9ecef !default;
$c-grey-variant-11: #f5f5f5;
$c-grey-variant-12: #f8f9fa;
$c-grey-variant-13: #fcfcfc !default;
$c-grey-variant-14: #C7C7C700;
$c-white: #ffffff !default;

$c-grey-t80: #4C4C4CCC !default;

$c-black-t60: #00000099;
$c-black-t25: #00000040;
$c-black-t20: #00000033;
$c-black-t10: #0000001a;
$c-black-t01: #00000003;
$c-black-t00: #00000000;

/* Typography */

$d4-base-font-size: 14px;
$d4-default-line-height: 20px;
$d4-bigger-line-height: 24px;

/* Layout */

$d4-margin: 20px;
$form-field-height: 30px;
$d4-spinner-size: 28px;
