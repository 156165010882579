.two-fa-information {
  display: flex;
  justify-content: space-between;
  > * :first-child {
    margin-bottom: 20px;
  }
}
.two-fa-instructions {
  @extend %important-text;
  margin-bottom: 20px;
}
.two-fa {
  &-container {
    flex-direction: row !important;
  }
  &-textfield {
    width: 60px;
    height: 80px;
    margin-right: 5px;
    padding: 20px;
    text-align: center;
    font-size: 20px !important;
  }
}
.modal {
  .two-fa-instructions {
    margin-bottom: 0;
  }
  .two-fa-qr-code {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
  .two-fa {
    &-textfield {
      padding: 10px;
      font-size: 14px !important;
    }
  }
  .two-fa-information {
    flex-direction: column;
  }
  .two-fa-qr-code {
    display: flex;
    justify-content: center;
  }
}