
.dcim-dashboard {
	display: flex;
	height: 100%;
	.box {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.kpi-charts {
		flex: 1;
		min-height: 300px;
	}
	.mysmart-dc {
		border-top: 0px solid $c-grey-variant-10;
		margin: 10px;
		padding: 10px;

		.body {
			display: flex;
			flex-direction: row;
			.icon {
				@include fontSize(52px);
				text-align: center;
				width: auto;
				padding-right: 10px;
				margin-right: 10px;
				border-right: 1px solid $c-grey-variant-10;
			}
			.ring {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $c-grey-variant-3;
				border-radius: 50px;
				padding: 10px;
				height: 60px;
				width: 60px;
			}
			.wording {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				.label {
					@include fontSize(16px);
					color: $c-grey-variant-3;
					font-weight: $ft-bold;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.43;
					letter-spacing: normal;
					text-align: left;margin-bottom: 5px;
					.important {
						color: $c-main;
						text-transform: inherit;
					}
				}
				.legend {
					position: relative;
					@include fontSize(14px);
					color: $c-grey-variant-3;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.43;
					letter-spacing: normal;
					text-align: left;
				}
			}
		}
		.foot {
			display: flex;
			justify-content: flex-end;
		}
	}
}
