.modify-tickets {
  .information-label {
    @extend %label;
    + .btn {
      margin-top: 10px;
    }
  }
  .customized-tickets {
    display: flex;
    align-items: center;
  }
  .form-input {
    margin: 0 5px;
  }
  @media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
    .customized-tickets {
      flex-direction: column;
      align-items: flex-start;
    }
    .form-input {
      margin: 0 5px 0 0;
    }
  }
}