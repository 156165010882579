.nav-tabs {
    min-height: 45px;
    display: flex;
    align-items: flex-end;
    border: none;
    flex-wrap: nowrap;

    .nav-item {
        margin-top: 12px;
        display: flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        .nav-link {
            @extend %important-text;
            background-color: $c-white;
            color: $c-grey-variant-2;
            border: none;
            border-radius: 8px 8px 0 0;
            padding: 10px 25px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: stretch;

            &.active {
                margin-top: -12px;
                &:hover {
                    color: $c-grey-variant-10;
                }

                background-color: $c-grey-variant-2;
                color: $c-white;
            }
        }
    }

    + .tab-content {
        margin-top: 20px;
    }
}

.nav-selector {
    .form-select {
        .dropdown-select {
            background-color: $c-white;

            .btn-select {
                background-color: $c-white;
            }
        }
        .form-select-menu {
            z-index: 1000;
        }

        // override custom complementary-react-select scss style
        &__control--is-focused {
            border: 1px solid $c-grey-variant-2 !important;

            &:hover {
                border-color: $c-grey-variant-2 !important;
            }
        }

        &__option {
            &.form-select-option {
                &:active {
                    background-color: $c-grey-variant-2;
                    color: $c-white;
                }
            }

            &--is-focused {
                &.form-select-option {
                    color: $c-grey-variant-2;
                    background-color: $c-grey-variant-12; // bootstrap dropdown-item hover color
                }
            }

            &--is-selected {
                &.form-select-option {
                    color: $c-white;
                    background-color: $c-grey-variant-2;
                }
            }
        }
    }
}

.date-selector {
    display: flex;
    max-width: 265px;
    align-items: center;
    justify-content: center;
    border: 1px solid $c-grey-variant-10;
    padding: 4px 6px;
    height: 40px;
    font-weight: 500;
    .date-selector-item {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
    .active {
        border-radius: 2px;
        background: $c-data4-yellow;
    }
    @media (max-width: $md-queries) {
        max-width: none;
    }
}
