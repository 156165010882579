@import 'variables';

// 01 - Buttons
//reset button + a//
button, a {
  cursor: pointer;
  outline: none !important;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.btn {
  @include fontSize(14px);
  border-radius: 2px;
  line-height: $d4-default-line-height;
  margin: 4px 0;
  padding: 0 16px;
  height: 36px;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: $ft-fam-primary;
  font-weight: $ft-med;

  width: auto;

  .icon {
    & + * {
      margin-left: 10px; // margin entre l'icône et le block à coté
    }
  }
}

.btn {
  &.btn-sm {
    height: 30px;
    padding: 0 10px;
  }

  &.btn-md {
    /* normal */
  }

  &.btn-lg {
    /* TODO */
    //height: 60px;
  }

  &.btn-yellow {
    color: black;
    background-color: $c-data4-yellow;
  }
// TODO harmoniser avec mixin button-variant
  &.btn-primary-v-1 {
    color: $c-white;
    background: $c-green-variant-4;
  }

  &.btn-secondary-v-1 {
    color: $c-white;
    background: $c-green-variant-4;
  }

  &.btn-secondary-v-2 {
    color: $c-white;
    background: $c-green-variant-4;
  }

  &.btn-tertiary {
    color: $c-white;
    background: $c-grey-variant-3;
    @extend %transition-0;
    &:not(:disabled) {
      &:hover {
        background: $c-white;
        color: $c-grey-variant-3;
        @extend %transition-0;
      }
    }
  }

  &.btn-white-bg {
    background: $c-white;
    &:not(:disabled) {
      &:hover:after {
        background: transparentize($c-main, .99);
      }
    }

    &.btn-primary {
      color: $c-main;
    }

    &.btn-primary-v-1 {
      color: $c-red-variant-1-light;
    }

    &.btn-secondary {
      color: $c-blue-variant-2;
    }

    &.btn-secondary-v-1 {
      color: $c-blue-variant-1;
    }

    &.btn-tertiary {
      color: $c-pink;
    }
  }

  &.btn-link {
    border: none;
    text-decoration: none;
    box-shadow: inherit;
    background: none;

    &:after {
      display: none;
    }
    &:not(:disabled) {
      &:hover {
        box-shadow: none;
      }
    }

    &.btn-primary {
      color: $c-main;
    }

    &.btn-primary-v-1 {
      color: $c-red-variant-1-light;
    }

    &.btn-secondary {
      color: $c-blue-variant-2;
    }

    &.btn-secondary-v-1 {
      color: $c-blue-variant-1;
    }

    &.btn-tertiary {
      color: $c-pink;
    }
  }

  &.btn-disable {
    opacity: .5;
    pointer-events: none;
  }

  &.btn-uppercase {
    font-size: 12px;
    text-transform: uppercase;
  }

  //TABLET RESOLUTION BETWEEN - 768/991px //
  @media (min-width: $sm-queries + 1px) and (max-width: $md-queries) {
    &.btn-bigger {
      height: 75px;
      margin: 0 auto;
      padding: 20px 30px;
      min-width: 185px;
      width: auto !important;
    }
  }

}

.redirection-g {
  display: inline-flex;
  width: auto;
  align-items: center;
  text-decoration: none !important;
  font-weight: $ft-bold;

  .icon-r {
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    @include fontSize(10px);
  }

  .i-arrow {
    margin-left: 2px;
  }
}


// 03 - Transistions

%transition-0 {
  transition: all .1s ease-in-out;
}

%transition-1 {
  transition: all .2s ease-in-out;
}

%transition-2 {
  transition: all .5s ease-in-out;
}

%transition-3 {
  transition: all .85s ease-in-out;
}

.btn > * {
  position: relative;
}

.btn:hover:after {
  z-index: -1!important;
  background: transparentize($c-red-variant-1-light, .35  )!important;
}

// Boutons par section

.btn-requests {
  background-color: $c-data4-yellow !important;
  border: 1px solid $c-data4-yellow !important;
  color: $c-main !important;
  &:hover {
    background: $c-white !important;
  }
}

.btn-requests-outline {
  background: $c-white !important;
  border: 1px solid $c-data4-yellow !important;
  color: $c-main !important;
  &:hover {
    background-color: $c-data4-yellow !important;
  }
}

.btn-services {
  background-color: $c-data4-red !important;
  border: 1px solid $c-data4-red !important;
  color: $c-white !important;
  &:hover {
    background: $c-white !important;
    color: $c-main !important;
  }
}

.btn-services-outline {
  background: $c-white !important;
  border: 1px solid $c-data4-red !important;
  color: $c-data4-red !important;
  &:hover {
    background-color: $c-data4-red !important;
    color: $c-white !important;
  }
}

.btn-sustainability {
  background-color: $c-data4-green !important;
  border: 1px solid $c-data4-green !important;
  color: $c-main !important;
  &:hover {
    background: $c-white !important;
  }
}

.btn-sustainability-outline {
  background: $c-white !important;
  border: 1px solid $c-data4-green !important;
  color: $c-main !important;
  &:hover {
    background-color: $c-data4-green !important;
  }
}

.btn-management {
  background-color: $c-data4-blue !important;
  border: 1px solid $c-data4-blue !important;
  color: $c-white;
  &:hover {
    background: $c-white !important;
    color: $c-main !important;
  }
}

.btn-management-outline {
  background-color: $c-white;
  border: 1px solid $c-data4-blue !important;
  color: $c-main !important;
  &:hover {
    background-color: $c-data4-blue !important;
    color: $c-white !important;
  }
}

.btn-login-panel {
  width: 277px;
}