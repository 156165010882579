.paper {
  position: relative;
  background: $c-white;
  &.paper-form {
    padding: 20px;
  }
  &.paper-shadow {
    box-shadow: 0 1px 2px 0 $c-black-t10;
  }
  &.dashboard-shadow {
    box-shadow: 0 2px 16px 0 $c-black-t10;
  }
  &.paper-button {
    border: 1px solid;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    padding: 20px;
    cursor: pointer;
    @extend %important-text;
  }
  &.paper-button-primary {
    border: 1px solid $c-main;
    color: $c-main;
  }
}
