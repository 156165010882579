.table-container {
  background-color: $c-white;
  padding: 10px 20px;
  & + .table-container {
    margin-top: 10px;
  }
  table {
    background-color: $c-grey-t80;
    margin: 0 !important;
    thead {
      th {
        @extend %help-text;
        border-top: none;
        border-bottom: none;
        padding: 10px 20px;
        color: $c-white;
        &.clickable-table-head {
          cursor: pointer;
          .icon {
            color: $c-white;
          }
        }
      }
    }
    tbody {
      background-color: $c-white;
      td {
        @extend %content-text;
        padding: 10px 20px;
        vertical-align: middle;
        .primary-text {
          @extend %important-text;
          & + * {
            margin-top: 5px;
          }
        }
      }
    }
    .clickable-table-row {
      cursor: pointer;
      &:hover {
        background-color: $c-grey-variant-11;
      }
    }
    .inner-table-cell {
      min-height: 44px;
      display: flex;
      align-items: center;
      &.menu-cell {
        justify-content: flex-end;
      }
    }
    .btn-tooltip {
      margin-right: 10px;
    }
    .dropdown-item {
      @extend %option-text;
      &:active {
        color: $c-white;
      }
    }
    .btn-download {
      padding-left: 0;
      padding-right: 10px;
      color: $c-main;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
    .dropdown-single {
      .btn-select {
        padding: 0 10px;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}

table {
  margin: 0 !important;
  &.scrollable {
    background-color: $c-grey-t80;

    thead th {
      color: $c-white !important;
    }

    thead tr {
      display: block;
    }
    tbody {
      display: block;
      max-height: 200px;
      overflow-y: auto;
      width: 100%;
      background-color: $c-white;
    }
  }
}

.hosting-spaces {
  .head {
    @extend %important-text;
    margin-bottom: 20px;
  }
  table {
    thead {
      th {
        @extend %help-text;
        border: none;
        width: 200px;
      }
    }
    tbody {
      td {
        @extend %important-text;
        vertical-align: middle;
        width: 200px;
        span + span {
          margin-left: 10px;
        }
      }
    }
  }
}

// mobile
@media (min-width: $xs-queries + 1px) and (max-width: $sm-queries) {
  .table-container {
    table {
      thead th, tbody td {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.groupingSpaces > .table-container {
  padding: 0;
}
