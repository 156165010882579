/* SURCHARGE DES VARIABLES */
//$rt-color-error: $c-gray1;
//$rt-color-success: $c-gray1;
//$rt-font-family: $font-family-sans-serif;

@import "~react-toastify/scss/main.scss";

.toast-container {
  @include fontSize(14px);
  color: $c-white;
  font-weight: $ft-bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
}

.toast-inner {
  padding-left: 20px;
  padding-right: 20px;
  button {
    &.close {
      color: $c-white;
      text-shadow: none;
      opacity: 1;
      font-weight: $ft-bold;
      .icon {
        @include fontSize(10px);
      }
    }
  }
}
