.delivery-instructions {
  .title {
    @extend %form-title;
    &+.content {
      margin-top: 10px;
    }
  }
  .content {
    @extend %important-text;
  }
  .contact {
    &+.contact {
      margin-top: 15px;
    }
    &+.comment {
      margin-top: 20px;
    }
  }
  .instructions-title {
    @extend %important-text;
    text-transform: uppercase;
  }
}