/* BEGIN Bold Italic */
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "BoldItalic");
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "BoldItalic");
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: RobotoCondensed;
	@include fontdef-woff($FontPathCondensed, $FontNameCondensed, $FontVersion, "BoldItalic");
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: RobotoCondensed;
	@include fontdef-woff($FontPathCondensed, $FontNameCondensed, $FontVersion, "BoldItalic");
	font-weight: bold;
	font-style: italic;
}
/* END Bold Italic */
