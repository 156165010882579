
.dropdown-item {
  display: flex;
  padding-right: $d4-margin;
  padding-left: $d4-margin;

  span {
    margin: auto 0;
  }
}

.nav-account .dropdown-menu {
  transform: translate3d(0, 30px, 0) !important;
}

.mgn-l-10 {
  margin-left: 10px;
}

.icon {
  vertical-align: middle;
}

.nav-account {
  position: relative;
  .badge {
    padding: 0 10px !important;
    min-width: inherit;
    border: none;
    background: none !important;
    .icon {
      font-size: 24px !important;
      transform: rotate(0) !important;

    }
  }
  .badge[aria-expanded = "true"] {
    background: $c-white !important;

    .icon {
      color: $c-main !important;
      transform: rotate(0) !important;
    }
  }
  .badge[aria-expanded = "true"]:hover:after {
    display: none !important;
    background: none !important;
  }
  &.active {
    .badge::before {
      content: '';
      display: block;
      z-index: 10;
      position: absolute;
      height: 6px;
      width: 6px;
      border-radius: 10px;
      right: 10px;
      top: 5px;
      background: $c-white;
    }
    .badge[aria-expanded = "true"]::before {
      background: $c-main;
    }
  }
}

.demandes-en-cours {
  z-index: 10;

  .table {
    table-layout: fixed;
    thead {
      background-color: $c-grey-t80;
      th {
        color: $c-grey-variant-11;
      }
    }
    tbody {
      background-color: $c-grey-variant-13;
      border: 1px solid $c-grey-variant-11;
      tr {
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background-color: $c-white;
        }

        td {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.tab-content > .active {
  display: block;
  width: 100%;
  height: 100%;
}

.no-border {
  border: none !important;
}

.font-14 {
  font-size: 14px
}

.font-12 {
  font-size: 12px;
}

.modal .modal-dialog.modal-xl {
  width: 778px;
}

.form-standard {

  .form-group {

    .form-error {
      .form-control {
        border-color: $c-error;
      }

      .form-control + .notification {
        color: $c-error;
      }
    }
  }
}

.appbar-utilisateur .d-mobile {
  border: none !important;
}

/* problème sur le dashboard : pour que la colonne de droite soit toujours à la même
hauteur que la colonne de gauche */
.fixme-001 {
  height: 100%;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.mb-d4 {
  margin-bottom: $d4-margin;
}

html, body, #app {
  height: 100%;
  overflow-y: hidden;
}

#app {
  display: flex;
  flex-direction: column;
}

#main {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &.no-scroll {
    overflow-y: hidden;
  }
}

.no-scroll {
  #main {
    overflow-y: hidden;
  }
}

/**************** NAV ***********************/
.nav-search {
  form {
    display: inherit;
  }
  .search-dropdown {
    .options {
      display: none;
      &.toggled {
        &.dropdown {
          display: flex;
        }
      }
    }
  }
}

/**************** TEXT ***********************/
.nouveau-visiteur {
  .form-input {
    .form-label {
      font-weight: 500;
    }
  }
}

/**************** LAYOUT ***********************/
.paper {
  &+.paper {
    margin-top: 10px;
  }
  &+.form-validation {
    margin-top: 20px;
  }
  .head {
    + .body {
      .card {
        border: none;
        border-radius: 0;
        border-top: 1px solid $c-grey-variant-9;
        .card-body {
          padding: 20px;
        }
      }
    }
  }
}

.form-standard {
  .head {
    display: flex;
    align-items: center;
    > * {
      &:first-child {
        flex: 1;
      }
    }
    .form-group {
      margin-bottom: 0;
      &+.btn {
        margin-left: 30px;
      }
    }
  }
}

.localisation-collapse-item {
  display: flex;
  align-items: center;
  & > .form-group {
    &:first-child {
      margin-bottom: 0;
    }
    &+.link {
      &:before {
        content: " ";
        white-space: pre;
      }
    }
  }
  &+.localisation-collapse-group {
    margin-left: 25px;
    .form-group {
      margin-bottom: 0;
    }
  }
}

.main-navigation {
  &+.main-content {
    margin-top: 50px;
  }
}

/**************** FORM ***********************/
.form-standard {
  .form-full {
    .group-form-control {
      width: 100%;
    }
  }
  .form-group {
    &.date-pickers {
      align-items: start;
      flex-direction: column;
      margin-bottom: 10px;
    }
    &.inline {
      &.date-pickers {
        flex-direction: row;
        .form-input {
          &.form-group {
            &:last-child {
              &::before {
                content: '-';
                color: $c-grey-variant-3;
                margin: 15px auto 0;
                text-align: center;
                position: relative;
                width: 25px;
              }
            }
          }
        }
      }
      .group-checkbox {
        display: flex;
        .form-checkbox {
          &+.form-checkbox {
            margin-left: 30px;
          }
        }
      }
    }
    .rdt {
      .form-control {
        &[readonly] {
          background-color: $c-white;
        }
      }
    }
  }
  .form-date-picker {
    &.fixed-position {
      .rdtPicker {
        position: fixed;
      }
    }
  }
  .form-radio {
    &.inline {
      flex-direction: row;
      .pretty {
        &+.pretty {
          margin-left: 30px;
        }
      }
    }
  }
}

.form-upload-file {
  flex-direction: row;
  align-items: center;
  .form-data {
    &+.upload-file {
      margin-top: 10px;
    }
  }
  .upload-file {
    display: flex;
    align-items: center;
  }
  .btn-delete-file {
    height: 14px;
    margin: 0;
    padding-left: 5px;
    padding-right: 0;
    .icon {
      @include fontSize(14px);
    }
  }
}

/**************** LINK ***********************/
.link {
  &.link-primary {
    @include fontSize(14px);
    font-weight: $ft-med;
    .label {
      @include fontSize(14px);
      font-weight: $ft-med;
    }
  }
  &:hover {
    .label {
      &:after {
        width: 100%;
      }
    }
  }
  &.no-underline {
    &:hover {
      .label {
        &:after {
          display: none;
        }
      }
    }
  }
  &-icon {
    &:hover {
      .label {
        &:after {
          width: calc(100% + 20px);
        }
      }
    }
  }
}
.configure-delivery-contacts {
  .link {
    .label {
      font-size: inherit;
      font-weight: inherit;
    }
  }
}

/******************** DROPDOWN ********************/
.dropdown-select {
  font-size: 14px;

  .btn-select {
    color: $c-grey-variant-3;
    background-color: $c-grey-variant-11;
    border-color: $c-grey-variant-10;
    padding: 0 5px 0 10px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
    height: 44px;

    &:hover {
      color: $c-grey-variant-3;
      background-color: $c-white;
      border-color: $c-grey-variant-10;
    }

    &:focus,
    &.focus {
      box-shadow: none;
    }

    &.disabled,
    &:disabled {

    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: $c-grey-variant-3;
      background-color: $c-white;
      border-color: $c-grey-variant-10;
      &:focus {
        box-shadow: none;
      }
    }
  }

  &.show {
    .btn-select {
      background-color: $c-white;
    }
  }
}

.request-item {
  &.dropdown-item {
    padding-right: 0;
    padding-left: 0;
    a {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
    .icon {
      margin-right: 10px;
      color: $c-grey-variant-3
    }
  }
}

/******************** BUTTON ********************/
.btn-auto {
  &:focus,
  &.focus {
    box-shadow: none;
  }
}

.btn.disabled {
  cursor: not-allowed;
}
//mobile
@media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
  .btn-no-border-mobile {
    border: 1px solid $c-white !important;
  }
}

/******************** SPINNER ********************/
.spinner-border {
  &-services {
    border-color: transparent transparent $c-data4-red $c-data4-red;
  }
  &-sustainability {
    border-color: transparent transparent $c-data4-green $c-data4-green;
  }
  &-management {
    border-color: transparent transparent $c-data4-blue $c-data4-blue;
  }
  &-requests {
    border-color: transparent transparent $c-data4-yellow $c-data4-yellow;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-border::after {
  animation: spin 0.7s linear infinite;
}

.spinner-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.inline-loader-container {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  > * {
    position: relative;
    right: 0;
    top: 0;
  }
  > .spinner-placeholder {
    margin: 5px;
  }
  > .form-group:not(:last-child) {
    margin-bottom: 0;
  }
}

/******************** SIDE REQUEST FILTERS ********************/
.side-filters-overlay {
  &+.bm-menu-wrap {
    width: 600px !important; // surcharger la largeur par défaut du react-burger-menu
  }
}
.side-filters {
  background-color: $c-white !important;
  .head {
    .headline {
      margin-top: 0;
      margin-bottom: 0;
    }
    .close {
      position: absolute;
      right: 20px;
      .icon {
       font-size: 20px;
      }
    }
  }
  .paper-form {
    padding: 20px;
    border-bottom: 1px solid $c-grey-variant-9;
    &+.form-validation {
      margin-top: 30px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  .form-standard {
    > * {
      color: initial;
    }
  }
}
.filter-active {
  color: $c-white !important;
  background-color: $c-data4-yellow !important;
}

/******************** DETAIL DEMANDES ********************/
.visitor-information {
  .email {
    &+.company {
      &:before {
        content: " - ";
      }
    }
  }
  .telephone {
    &+.vehicule {
      &:before {
        content: " - ";
      }
    }
  }
}

/******************** CONSIGNES LIVRAISON ********************/
.move-arrows {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .move-up, .move-down {
    height: auto;
    padding: 0 20px 0 0;
    margin: 0;
  }
}

/******************** ALERTE D'URGENCE ********************/
.alert-emergency {
  background-color: $c-main;
  color: $c-white;
}

/******************** ICONE ********************/
.text-icon {
  display: flex;
  align-items: center;
  &.text-right-icon {
    .icon {
      margin-right: 10px;
    }
  }
  &.text-left-icon {
    .icon {
      margin-left: 10px;
    }
  }
}

/**************** Ce bloc doit être mis en dernier ***********************/
/**************** RESPONSIVE ***********************/
//md
@media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
  .side-filters-overlay {
    &+.bm-menu-wrap {
      width: 100% !important;
    }
  }
  .stepper-desk {
    display: none;
  }
  .stepper-mobile {
    display: flex;
  }
  .form-standard {
    .form-radio {
      &.inline {
        flex-direction: column;
        .pretty {
          &+.pretty {
            margin-left: 0;
          }
        }
      }
    }
    .form-group {
      &.inline {
        &.date-pickers {
          flex-direction: column;
          .form-input {
            &.form-group {
              &:last-child {
                &::before {
                  margin-top: 0;
                }
              }
            }
          }
        }
        .group-checkbox {
          flex-direction: column;
          .form-checkbox {
            &+.form-checkbox {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .main-breadcrumb {
    .breadcrumb {
      li {
        &.d-desktop {
          display: none;
        }
      }
    }
    .d-mobile {
      .request-item {
        &.dropdown-item {
          .icon {
            display: none;
          }
        }
      }
    }
  }
  .modal {
    .modal-dialog {
      .modal-content {
        height: 100%;
        overflow: scroll;
      }
    }
  }
  .form-standard {
    .head {
      .select-service & {
        flex-wrap: wrap;
      }
      .form-group {
        &+.btn {
          margin-top: 16px;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
  .collapsable-element {
    .head {
      .titre {
        .form-title {
          min-width: auto;
        }
      }
    }
  }
}
//lg
@media (min-width: $md-queries + 1px)  {
  .stepper-desk {
    display: block;
  }
  .stepper-mobile {
    display: none;
  }
}
