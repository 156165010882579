/* BEGIN Light */
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "Light");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: RobotoCondensed;
	@include fontdef-woff($FontPathCondensed, $FontNameCondensed, $FontVersion, "Light");
	font-weight: 300;
	font-style: normal;
}
/* END Light */
