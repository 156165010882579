.form-switch {
  .form-control {
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    label {
      margin-bottom: 0;
    }
  }
  &.form-group {
    position: relative;

    .custom-switch {
      padding-left: 36px;
      width: 36px;
      .custom-control-label {
        &::before {
          position: absolute;
          height: 18px;
          width: 36px;
          margin: 0px;
          background: $c-grey-variant-5;
          border-radius: 14px;
          cursor: pointer;
          transition: background 0.25s ease 0s;
          top: 1px;
          left: -36px;
        }
        &::after {
          width: 20px;
          height: 20px;
          background: $c-white;
          display: inline-block;
          cursor: pointer;
          border-radius: 50%;
          position: absolute;
          transform: translateX(0);
          top: 0;
          left: -36px;
          outline: 0;
          border: 0;
          transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
          box-shadow: 0px 1px 5px $c-black-t60;
        }
      }
      .custom-control-input {
        &:checked {
          ~ {
            .custom-control-label {
              &::after {
                transform: translateX(18px);
              }
              &::before {
                border-color: $c-main;
                background-color: $c-main;
              }
            }
          }
        }
      }
      & + .form-label {
        margin-left: 10px;
      }
    }
  }
}
