@import "fonts/roboto/variables";
@import "fonts/roboto/mixins";
//
@import "fonts/roboto/Thin";
@import "fonts/roboto/ThinItalic";
@import "fonts/roboto/Light";
@import "fonts/roboto/LightItalic";
@import "fonts/roboto/Regular";
@import "fonts/roboto/Italic";
@import "fonts/roboto/Medium";
@import "fonts/roboto/MediumItalic";
@import "fonts/roboto/Bold";
@import "fonts/roboto/BoldItalic";
@import "fonts/roboto/Black";
@import "fonts/roboto/BlackItalic";

//****************************************************//

//_FONT CUSTOM_//

$font-family-sans-serif:      'Roboto', sans-serif;
$font-family-condensed:       'RobotoCondensed';
$font-size-body:               14px;
$font-weight-body:             400;

html, body {
  font-family: $font-family-sans-serif;
  font-size: $font-size-body;
  font-weight: $font-weight-body;
}

h1 {
  font-family: $font-family-condensed;
}

// mixin font-size calcule rem
@function calculateRem($size) {
	$remSize: $size / $font-size-body;
	@return #{$remSize}rem;
}

@mixin fontSize($size) {
	font-size: $size; //Fallback in px
	//font-size: calculateRem($size);
}
.ft-s-8  { @include fontSize( 8px); }
.ft-s-9  { @include fontSize( 9px); }
.ft-s-10 { @include fontSize(10px); }
.ft-s-11 { @include fontSize(11px); }
.ft-s-12 { @include fontSize(12px); }
.ft-s-13 { @include fontSize(13px); }
.ft-s-14 { @include fontSize(14px); }
.ft-s-15 { @include fontSize(15px); }
.ft-s-16 { @include fontSize(16px); }
.ft-s-18 { @include fontSize(18px); }
.ft-s-20 { @include fontSize(20px); }
.ft-s-21 { @include fontSize(21px); }
.ft-s-22 { @include fontSize(22px); }
.ft-s-24 { @include fontSize(24px); }
.ft-s-25 { @include fontSize(25px); }
.ft-s-26 { @include fontSize(26px); }
.ft-s-28 { @include fontSize(28px); }
.ft-s-30 { @include fontSize(30px); }
.ft-s-32 { @include fontSize(32px); }
.ft-s-35 { @include fontSize(35px); }
.ft-s-36 { @include fontSize(36px); }
.ft-s-40 { @include fontSize(40px); }
.ft-s-45 { @include fontSize(45px); }
.ft-s-50 { @include fontSize(50px); }
.ft-s-54 { @include fontSize(54px); }
.ft-s-60 { @include fontSize(60px); }
.ft-s-68 { @include fontSize(68px); }
.ft-s-76 { @include fontSize(76px); }
.ft-s-82 { @include fontSize(82px); }
.ft-s-90 { @include fontSize(90px); }
.ft-s-100 { @include fontSize(100px); }

$ft-fam-primary :'Roboto', sans-serif;
.ft-fam-primary {	font-family: $ft-fam-primary; }

$ft-fam-secondary: 'Arial', sans-serif;
.ft-fam-secondary { font-family: $ft-fam-secondary; }

$ft-thin:    100;
.ft-thin   { font-weight: $ft-thin; }
$ft-light:   300;
.ft-light  { font-weight: $ft-light; }
$ft-reg:     400;
.ft-reg    { font-weight: $ft-reg; }
$ft-med:     500;
.ft-med    { font-weight: $ft-med; }
$ft-sm-bold: 600;
.ft-sm-bold { font-weight: $ft-sm-bold; }
$ft-bold:    700;
.ft-bold   { font-weight: $ft-bold; }
$ft-black:   800;
.ft-black  { font-weight: $ft-black; }
$ft-xblack:   900;
.ft-xblack  { font-weight: $ft-xblack; }


$ft-upper: uppercase;
.ft-uppercase { text-transform: $ft-upper; }

$ft-low: lowercase;
.ft-low { text-transform: $ft-low; }

$ft-italic: italic;
.ft-italic { font-style: $ft-italic;}


.important { font-weight: $ft-bold; }

.text-ellipsis {
  @extend %text-ellipsis;
}

.text-pre-wrap {
  @extend %text-pre-wrap;
}

.text-normal {
  word-break: normal; // IE & < Edge 18
  overflow-wrap: normal;
}

%text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%text-pre-wrap {
  white-space: pre-wrap;
}

%important-text {
  @include fontSize(14px);
  font-weight: $ft-med;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $c-grey-variant-3;
}

%content-text {
  @include fontSize(14px);
  font-weight: $ft-reg;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $c-grey-variant-3;
}

%form-title {
  @include fontSize(14px);
  font-weight: $ft-bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $c-grey-variant-3;
}

%help-text {
  @include fontSize(14px);
  font-weight: $ft-reg;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $c-grey-variant-6;
}

%option-text {
  @include fontSize(14px);
  font-weight: $ft-reg;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $c-grey-variant-3;
}

%error-text {
  @include fontSize(14px);
  font-weight: $ft-reg;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $c-error;
}

%label {
  @include fontSize(14px);
  font-weight: $ft-med;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $c-grey-variant-3;
}

@mixin truncateMultiLines($nb-lines, $line-height) {
  position: relative;
  max-height: calc(#{$line-height}em * #{$nb-lines});
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $nb-lines;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}




