.blocCharts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  > * {
    //flex: 1;
    //align-items: center;
    //display: flex;
    width: 140px;
    height: 185px;
  }
  margin: 0 (-$d4-margin);
}

.highcharts-container  {
  //display: flex!important;
  //width: auto!important;

  .highcharts-title {
  }

}
