.kpi-lifetime-chart {
  $font-family: $font-family-sans-serif;
  $colors: $c-main $c-grey-variant-10 $c-green-variant-3;
  @import '~highcharts/css/highcharts.scss';

  .series-incomplete {
    .highcharts-color-0 {
      fill: $c-main;
      stroke: $c-main
    }
  }
  .series-valid {
    .highcharts-color-0 {
      fill: $c-green-variant-3;
      stroke: $c-green-variant-3
    }
  }

  .highcharts-subtitle {
    @include fontSize(20px);
    font-weight: $ft-med;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-grey-variant-3;
  }
}

.my-smart-dc-configuration {
  .paper + .paper {
    margin-top: $d4-margin;
  }
  .global-lifetime {
    min-height: 245px;
    .title {
      @extend %important-text
    }
  }
  .areas {
    .table-container {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
  .dcim-dashboard {
    height: auto;
    display: block; // override display:flex on dashboard
    .mysmart-dc {
      box-shadow: none;
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  @media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
    .areas {
      .information-group {
        width: 100%;
      }
    }
  }
}
