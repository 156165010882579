
.main-breadcrumb {
  background: $c-white;
  height: 30px;
  box-shadow: 0 10px 10px 0 $c-grey-variant-10;

  .breadcrumb {
    background: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    height: 30px;
    ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0;
    }
    li {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
    }
    a {
      color: $c-grey-variant-3;
      text-decoration: none;
      @include fontSize(13px);
      }
     li:last-child a {
       color: $c-grey-variant-7;
       cursor: default;
       pointer-events: none;
     }
    .icon {
      @include fontSize(16px);
    }
  }
  .breadcrumb-tools {
    @include zindex(breadcrumb-tools);
    position: absolute;
    right: 15px;
    background: none;
    display: flex;
    flex-direction: row;
    padding: 10px 0 0 0;
    margin: 0;
    height: auto;
    .dropdown-menu {
      min-width: 320px;
    }
    button {
      border-radius: 50px;
      height: 40px;
      width: 40px;
      background: $c-white;
      box-shadow: 0 1px 3px 1px $c-black-t10;
      margin-left: 20px;
      margin-top: 0;
      margin-bottom: 0;
      color: $c-grey-variant-3;
      @extend %transition-1;
      &.active {
        color: $c-main;
      }
      &:hover {
        background: $c-main;
        color: $c-white;
        @extend %transition-1;
      }
      .icon {
        @include fontSize(22px);
      }
    }
  }
}
