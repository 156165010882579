
.modal {
  display: flex!important;
  align-items: center!important;

  .modal-dialog {
    &.modal-xl {
      max-width: 780px;
      .modal-content {
        width: 780px;
        line-height: 1.43;
      }
    }
  }
  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-header {
    background: $c-main;
    color: $c-white;
    height: 60px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0;
    padding: 20px;
    .modal-title {
      @include fontSize(14px);
      font-weight: $ft-bold;
    }
    .icon {
      cursor: pointer;
    }
  }
  .modal-body   {
    padding: 20px;
  }
  .modal-footer {
    border: none;
    border-radius: 0;
    padding: 0 20px 20px;

    > :not(:first-child) {
      margin-left: 20px;
    }
    > :not(:last-child) {
      margin-right: 0;
    }
    > .left {
      flex: 1;
    }
  }

}

.modal .modal-dialog.modal-xl .modal-content {
  width: 100%;
}

@media (max-width: $sm-queries) {
  .modal-dialog, .modal-content {
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }
}
