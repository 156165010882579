
.headline {
  @include fontSize(25px);
  color: $c-main;
  font-weight: $ft-bold;
  margin-top: 30px;
  margin-bottom: $d4-margin;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;

  &.underline {
    color: $c-main;
    &:after {
      content: ' ';
      display: block;
      border: 1px solid black;
      width: 240px;
    }
  }

  &-2 {
    @include fontSize(20px);
    color: $c-grey-variant-3;
    font-weight: $ft-med;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin-bottom: 20px;
  }
}

.title-headline {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .logo {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-right: 13px;
    height: 20px;
    width: auto;
  }
  .logo-dashboard {
    margin-bottom: 30px;
    margin-left: -10px;
    @include fontSize(35px)
  }
}
