.custom-select {
  @include fontSize(14px);
  font-weight: $ft-reg;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $c-grey-variant-3;
  border-color: $c-grey-variant-10;
  height: 30px;
  background: $c-grey-variant-11;
  padding: 3px 10px;
}

.btn-select .tick-round {
  margin: 0;
  padding: 0;
}

.tick-round {
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 7px 10px;

  &:active {
    background: none;
    color: $c-grey-variant-7;
  }

  .tick {
    position: relative;
    z-index: 5000;
    border-radius: 10px;
    height: 13px;
    width: 13px;
    margin-right: 5px;
  }

  .validation {
    background-color: $c-green-variant-3;
  }

  .error {
    background-color: $c-error;
  }

  .refusal {
    background-color: $c-red-variant-1;
  }

  .urgent {
    background-color: $c-red-variant-2;
  }

  .standard {
    background-color: $c-yellow-variant-1;
  }

  .other {
    background-color: $c-error;
  }

  .open {
    background-color: $c-data4-green;
  }

  .in-progress {
    background-color: $c-data4-blue;
  }

  .closed {
    background-color: $c-main;
  }

  .close {
    background-color: $c-grey-variant-2;
  }

  > span:last-child {
    @extend %text-ellipsis
  }
}

.loading-is-active {
  .btn-select {
    position: inherit;
    overflow: visible !important;

    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      right: -35px;
      display: block;
      opacity: 1;
      height: 25px;
      width: 25px;
      border-radius: 50px;
      background-image: url("../img/loader.svg") !important;
      background-size: contain !important;
      //background-color: inherit;
    }

    &:active:after {
      //background: inherit;
      display: block;
      opacity: 1;
    }
  }
}

.loading-is-active ~ * {
  margin-left: 30px !important;
}
