.main-tab {
  display: flex;
  flex-direction: column;

  .head {
    &.nav {
      &.nav-tabs {
        height: 0;
        min-height: 35px;
        display: flex;
        align-items: center;
        border: none;
        flex-wrap: nowrap;

        .nav-item {
          margin: 0;
          height: 100%;
        }

        .nav-link {
          display: flex;
          align-items: center;
          border-radius: 8px 8px 0 0;
          background-color: $c-grey-variant-2;
          border: none;
          height: 100%;
          margin: 0;
          text-transform: none;

          @include fontSize(14px);
          font-weight: $ft-med;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          color: $c-white;
          &:hover {
            color: $c-grey-variant-6;
          }
          &.active {
            font-weight: $ft-med;
            &:hover {
              color: $c-grey-variant-10;
            }
          }
        }
        + .tab-content {
          margin-top: 0;
        }
      }
    }
  }
  .body {
    flex: 1 ;
    display: flex;
    align-items: center;
    justify-content: center;
    .table thead th {
      border: inherit;
      @include fontSize(14px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      color: $c-white;
      background-color: $c-grey-t80;
      @media (max-width: $md-queries) {
        padding-left: 10px;
      }
      &:first-child {
        width: 10%;
        padding-left: 10px;
      }
      &:last-child {
        width: 20%;
        padding-right: 10px;
        @media (max-width: $md-queries) {
          width: 60%;
        }
      }
    }
    .table thead td {
      border-color: $c-grey-variant-9;
    }
    .table th,
    .table td {
      padding: 0;
      vertical-align: middle;
    }

    .table td {
      @include fontSize(14px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 50px;
      letter-spacing: normal;
      color: $c-grey-variant-3;
      .icon-type-demande {
        @include fontSize(20px);
        margin-right: 10px;
        color: $c-blue-variant-3;
        font-weight: $ft-med;
        border-radius: 50%;
        background-color: $c-blue-variant-4;
      }
    }
    .table tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: $c-white;
        }
      }
      td {
        &:first-child {
          padding-left: 10px;
        }
        &:last-child {
          padding-right: 10px;
        }
      }
    }
    .resume-mobile {
      line-height: 20px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      white-space: normal;
    }
    .resume {
      padding-left: 10px;
      @extend %text-ellipsis;
    }
    .code-ref {
      color: $c-grey-variant-6;
      @include fontSize(12px);
      font-weight: $ft-reg;
    }
  }
  .foot {
    display: flex;
    justify-content: space-between;
    padding: $d4-margin/2 $d4-margin;
    min-height: 64px;
    border-top: 1px solid $c-grey-variant-10;
    flex-wrap: wrap;
  }

  .foot-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media (max-width: $md-queries) {
      flex-direction: column;

      .dropdown-select button {
        width: 100%;
      }

      button.link {
        text-align: right;
        margin-top: 10px;
      }
    }
  }
  .criticite {
    position: relative;
    &.urgent:before {
      background-color: $c-data4-red;
    }
    &.standard:before {
      background-color: $c-data4-yellow;
    }
    &.other:before {
      background-color: $c-error;
    }
    &:before {
      position: absolute;
      width: 3px;
      border-radius: 4px;
      height: 25px;
      top: 30%;
      content: "";
      @media (max-width: $md-queries) {
        height: 60%;
        top: 20%
      }
    }
  }

}
