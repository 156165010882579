.form-button-group {
  .selectable-button {
    @extend %important-text;
    cursor: pointer;
    border: 1px solid $c-grey-variant-10;
    background: $c-grey-variant-11;
    position: relative;
    margin-bottom: 5.5px;
    margin-top: 5.5px;
    &.checked {
      background: $c-main;
      border: none;
      color: $c-white;
    }
  }
  .btn-circle {
    border-radius: 100px;
    width: 33px;
    height: 33px;
    text-align: center;
    .label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.form-standard {
  .form-button-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.inline {
      flex-direction: row;
      .selectable-button + .selectable-button {
        margin-left: 10px;
      }
    }
    @media (max-width: $md-queries) {
      &.inline {
        flex-direction: column;
        .selectable-button + .selectable-button {
          margin-left: 0;
        }
      }
    }
  }
}
