#nav {
  @include zindex(nav);

  > * {
    @media (min-width: $md-queries + 1px) {
      padding: 0 55px;
    }
  }
}

.footer {
  height: 45px;
  background: black;
  width: 100%;
  color: $c-white;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-navigation {
  width: 100%;
  background: black;
  color: $c-white;

  height: 56px;
  display: flex;

  padding: 0 20px;
  align-items: center;
  box-shadow: 0 2px 4px $c-black-t10;

  .menu-link {
    cursor: pointer;
  }

  .nav-left {
    display: flex;
    flex-direction: row;

    .logo-label {
      @include fontSize(16px);
      text-transform: uppercase;
    }

    @media (min-width: 991px) {
      .logo-desktop {
        display: flex;
      }
      .logo-mobile {
        display: none;
      }
    }
    @media (max-width: 991px) {
      .logo-desktop {
        display: none;
      }
      .logo-mobile {
        display: flex;
      }
    }

  }

  .nav-right {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }

  .nav-link {
    padding: 0 2rem;
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      color: $c-white;
    }
  }
}

.nav-tools {
  display: flex;
  align-items: center;

  .nav-item + .nav-item {
    margin: 0 0 0 15px;
  }

  .nav-item {
    .btn.btn-icon {
      padding: 0 !important;

      .icon {
        padding: 5px;
        margin: 0 !important;
      }
    }
  }
}

.nav-search {
  display: flex;
  position: relative;
  height: 30px;
  opacity: 1;
  @extend %transition-0;

  .textfield {
    border: none;
    outline: none;
    box-shadow: inherit;
    border-radius: 8px 0 0 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 20px;
    width: 145px;
    background: $c-grey-variant-3;
    @extend %transition-0;
    font-family: $ft-fam-primary;
    color: $c-white;
    text-overflow: ellipsis;
  }

  input::placeholder {
    color: $c-white;
    opacity: .75;
  }

  .search-btn {
    background: $c-grey-variant-3;
    border-radius: 0 8px 8px 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    @extend %transition-0;

    .icon {
      color: $c-white;
      opacity: .5;
      @extend %transition-0;
    }

    &:hover .icon {
      opacity: 1;
      @extend %transition-0
    }
  }

  .dropdown {
    @include zindex(nav-search-dropdown);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    padding: 8px 0;
    background: $c-white;
    border: 1px solid $c-grey-variant-10;
    border-radius: 3px;
    box-shadow: 0 2px 4px $c-black-t10;
  }

  .dropdown-item {
    color: $c-grey-variant-3;
    cursor: pointer;

    &.selected {
      @extend %important-text;
    }

    &:active {
      color: $c-white;
    }
  }

  &.toggled {
    opacity: 1;
    @extend %transition-0;

    .textfield {
      width: 330px;
      color: $c-grey-variant-3;
      background: $c-white;
      @extend %transition-0;
    }

    .search-btn {
      background: $c-white;
      @extend %transition-0;

      .icon {
        color: $c-grey-variant-3;
        opacity: .5;
        @extend %transition-0
      }

      &:hover .icon {
        opacity: 1;
        @extend %transition-0
      }
    }

  }
}

.nav-notification {
  position: relative;

  .btn {
    min-width: inherit;
    border: none;
    background-color: $c-white;
    color: $c-main;
    border-radius: 8px;
  }

  .btn[aria-expanded = "true"] {
    background: $c-white !important;

    .icon {
      color: $c-main;
    }
  }

  .dropdown-menu {
    width: 200px;
    top: 3px !important;
    padding: 0;
    background: $c-white;
    border: 1px solid $c-grey-variant-10;
    border-radius: 4px;
    box-shadow: 0 2px 4px $c-black-t10;
  }

  .dropdown-item {
    padding: 10px;
    border-bottom: 1px solid $c-grey-variant-10;
    white-space: normal;
    line-height: 1.43;

    &:not(:active):not(.active) {
      color: $c-grey-variant-3;
    }

    &:last-child {
      border: none;
    }
  }

  .btn[aria-expanded = "true"]:hover:after {
    display: none !important;
    background: none !important;
  }

  &.active {
    .badge::before {
      content: '';
      display: block;
      z-index: 10;
      position: absolute;
      height: 7px;
      width: 7px;
      border-radius: 10px;
      right: 2px;
      top: 2px;
      background: $c-white;
    }

    &.show,
    &:not(:active):hover {
      .badge::before {
        background: $c-main;
      }
    }
  }

  .notification-item {
    word-break: break-word;
  }

  .not-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    color: $c-grey-variant-6;

    .icon {
      font-size: 50px;
    }

    span:last-child {
      @include fontSize(14px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      margin-top: 10px;
    }
  }
}

.nav-help {
  &.btn {
    min-width: inherit;
  }
}

.nav-account {
  .btn {
    background-color: $c-main;
    color: $c-white;
    border: 1px solid $c-white;

    .user-name {
      font-weight: $ft-med;
    }

    .icon {
      transform: rotate(90deg);
    }

    .icon-appbar-utilisateur {
      transform: rotate(0deg);
      margin: 0 !important;
    }
  }

  .btn[aria-expanded = "true"] {
    background: $c-white;
    color: $c-main;

    .icon {
      color: $c-main;
    }
  }

  .dropdown-menu {
    top: 3px !important;
    left: inherit !important;
    right: 0 !important;
    padding: 8px 0;
    background: $c-white;
    border: 1px solid $c-grey-variant-10;
    border-radius: 3px;
    box-shadow: 0 2px 4px $c-black-t10;
  }

  .dropdown-item {
    color: $c-grey-variant-3;
    padding: .3rem 1.3rem;

    &:active {
      color: $c-white;
      background-color: $c-grey-variant-3;
    }
  }

  &.show .btn:hover:after {
    display: none !important;
    background: none !important;
  }
}

.search-field-mobile {
  height: 80px;
  padding: 0 20px;

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .form-input {
      margin: auto 0;
      border-radius: 8px 0 0 8px;
      background: $c-grey-variant-3;
      text-overflow: ellipsis;
    }
  }

  .form-control {
    height: 44px;
    border-radius: 2px;
    padding: 10px 20px;
    @include fontSize(14px);
    border: none !important;
  }

  .group-form-control {
    flex: 1;
  }

  .search-btn {
    cursor: pointer;
    background: $c-grey-variant-3;
    border-radius: 0 8px 8px 0;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    .icon {
      color: $c-grey-variant-11;
    }
  }
}

@media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
  .dropdown-item {
    // augmenter la surface des options cliquables en mobile
    line-height: 2 !important;
    white-space: normal;
  }
}
