$zindex: (
        nav: 999,
        main-body: 0,
        background: -1,
        empty-space: 0,
        list-collapse: 1,
        list-collapse-active: 10,
        breadcrumb-tools: 11,
        nav-search-dropdown: 12,
        nav-link-border: 1
);

@mixin zindex($element) {
  z-index: map_get($zindex, $element);
}