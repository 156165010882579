
.list-separator {
	background: none;
	position: relative;
	display:  flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	h6 {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 3rem;
		margin: 10px 0;
		background: $c-grey-variant-11;
		height: 26px;
		z-index: 1;

		@include fontSize(14px);
		font-family: $ft-fam-primary;
		font-weight: $ft-bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.43;
		letter-spacing: normal;
		color: $c-grey-variant-3;
	}
	hr {
		position: absolute;
		top: 23px;
		margin-top: inherit;
		margin-bottom: inherit;
		border: inherit;
		border-top: inherit;
		height: 2px ;
		width: 100%;
		background: $c-grey-variant-10;
	}
}

.load-more-container {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.load-more {
		position: relative;
		right: 0;
		top: 0;
	}
}

.align-items-horizontally {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.align-items-vertically {
	display: flex;
	flex-direction: column;
	line-height: 20px;
}

.add-btn {
	.dropdown-menu {
		min-width: 320px;
	}
	button {
		border-radius: 50px;
		height: 40px;
		width: 40px;
		background: $c-white;
		box-shadow: 0 1px 3px 1px $c-black-t10;
		color: $c-grey-variant-2;
		@extend %transition-1;
		&:hover {
			background: $c-grey-variant-2;
			color: $c-white;
			@extend %transition-1;
		}
		.icon {
			@include fontSize(22px);
		}
	}
}
.synthese-liste-title {
	margin-bottom: 15px;
}
.synthese-liste {
	border-collapse: collapse;
	display: flex;
	flex-direction: column;
	thead {
		background-color: $c-white !important;
		display: flex;
		flex-direction: row;
		tr {
			display: flex;
			flex: 1;
			th {
				display: flex;
				border: none !important;
				align-items: center;
				justify-content: center;
				flex: 1;
				color: $c-main !important;
				.synthese-liste-header {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;
					font-weight: 400;
					.icon-type-demande {
						@include fontSize(20px);
						padding: 5px;
						color: $c-blue-variant-3;
						font-weight: $ft-med;
						border-radius: 50%;
						background-color: $c-blue-variant-4;
					}
				}
				.synthese-liste-header-total {
					display: flex;
					align-items: center;
					justify-content: center;
					flex: 1;
				}
			}
		}
	}
	tbody {
		display: flex;
		flex-direction: column;
		border: none !important;
		tr {
			display: flex;
			flex-direction: row;
			background-color: $c-white !important;
			&:first-child {
				td {
					border-top: none !important;
				}
			}
			td {
				display: flex;
				flex: 1;
				align-items: center;
				justify-content: center;
				cursor: default !important;
			}
			.synthese-liste-chip {
				display: flex;
				align-items: center;
				justify-content: center;
				.chip {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 5px;
					border-radius: 16px;
					width: fit-content;
					padding: 0 10px;
					&.open {
						background-color: $c-data4-green-variant-t10 !important;
					}

					&.in-progress {
						background-color: $c-data4-blue-variant-t10 !important;
					}

					&.closed {
						background-color: $c-grey-variant-9 !important;
					}
					&-badge {
						height: 6px;
						width: 6px;
						border-radius: 5px;
						&.open {
							background-color: $c-data4-green;
						}
						&.in-progress {
							background-color: $c-data4-blue;
						}
						&.closed {
							background-color: $c-grey-variant-1;
						}
					}
				}
			}
			.synthese-liste-total-cell {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 800;
			}
		}
	}
}

.synthese-liste-mobile {
	border-collapse: collapse;
	display: flex;
	flex-direction: column;
	thead {
		display: flex;
		flex-direction: row;
		background-color: $c-white !important;
		tr {
			display: flex;
			flex: 1;
			th {
				display: flex;
				border: none !important;
				align-items: center;
				justify-content: center;
				flex: 1;
				color: $c-main !important;
				font-weight: 400;
			}
			.synthese-liste-header-chip {
				display: flex;
				align-items: center;
				justify-content: center;
				.chip {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					text-align: center;
					border-radius: 16px;
					width: fit-content;
					padding: 0 10px;
					&.open {
						background-color: $c-data4-green-variant-t10 !important;
					}

					&.in-progress {
						background-color: $c-data4-blue-variant-t10 !important;
					}

					&.closed {
						background-color: $c-grey-variant-9 !important;
					}
				}
			}
		}
	}
	tbody {
		display: flex;
		flex-direction: column;
		border: none !important;
		tr {
			display: flex;
			flex: 1;
			flex-direction: row;
			background-color: $c-white !important;
			th {
				display: flex;
				min-height: 94px;
				flex: 1;
				align-items: center;
				justify-content: center;
				white-space: break-spaces;
				.synthese-liste-mobile-row-header {
					padding: 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;
					font-weight: 400;
					.icon-type-demande {
						@include fontSize(20px);
						padding: 5px;
						color: $c-blue-variant-3;
						font-weight: $ft-med;
						border-radius: 50%;
						background-color: $c-blue-variant-4;
					}
				}
			}
			&:first-child {
				td, th {
					border-top: none !important;
				}
			}
			td {
				display: flex;
				flex: 1;
				align-items: center;
				justify-content: center;
			}
			.synthese-liste-total-cell-mobile {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 800;
			}
		}
	}
}

.filtre-liste {
	cursor: pointer;
}
