
body {
	background-color: $c-grey-variant-11;
}

.main-body {
	@include zindex(main-body);
	position: relative;
	height: auto;
	min-height: 100%;
	background-image: linear-gradient($c-grey-variant-14, $c-grey-variant-11), url("/resources/img/fond-v2.jpg");
	background-position: right top;
	background-size: auto;
	background-repeat: no-repeat;
	padding-bottom: 60px;
}

.main-wrapper {
	padding-top: 50px;
}

//TRANSITION MIXIN
@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

.error {
	color: $c-error;
}

.important-error {
	color: $c-main;
}

.warning {
	color: $c-error;
}

.btn-tooltip, .pointer {
	cursor: pointer;
}

.tooltip-inner {
	@include fontSize(14px);
	font-weight: $ft-reg;
	cursor: pointer;
	max-width: 300px;
	padding: 0.25rem 0.5rem;
	color: $c-white;
	text-align: center;
	background-color: $c-grey-variant-3;
	border-radius: 0.25rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
	border-right-color: $c-grey-variant-3;
}
.arrow-tooltip {
	background: red!important;
}

.c-background-card {
	@extend %content-text;
	background-color: $c-grey-variant-11;
	padding: 20px;
	box-shadow: 0 1px 2px 0 $c-black-t10;
	& + .c-background-card {
		margin-top: 10px;
	}
}

.page-container {
	.information {
		& + .content {
			margin-top: 20px;
		}
	}
}

.section-support-other {
	background-color: $c-white !important;
	border: 1px solid $c-main !important;
	color: $c-main !important;
}

.section-management-other {
	background-color: $c-data4-blue !important;
	border: 1px solid $c-data4-blue !important;
	color: $c-white !important;
}

.section-requests-other {
	background-color: $c-data4-yellow !important;
	border: 1px solid $c-data4-yellow !important;
	color: $c-main !important;
}

.section-services-other {
	background-color: $c-data4-red !important;
	border: 1px solid $c-data4-red !important;
	color: $c-white !important;
}

.section-sustainability-other {
	background-color: $c-data4-green !important;
	border: 1px solid $c-data4-green !important;
	color: $c-main !important;
}

.section-management-menu {
	color: $c-data4-lightblue !important;
}

.section-requests-menu {
	color: $c-data4-yellow !important;
}

.section-services-menu {
	color: $c-data4-red !important;
}

.section-sustainability-menu {
	color: $c-data4-green !important;
}

// permet de modifier la couleur du chevron dans les titres qui est au format SVG
.section-support-title {
	color: $c-main;
}

.section-management-title {
	color: $c-data4-blue;
}

.section-requests-title {
	color: $c-data4-yellow;
}

.section-services-title {
	color: $c-data4-red;
}

.section-sustainability-title {
	color: $c-data4-green;
}

.section-requests-btn {
	button {
		border: 1px solid $c-data4-yellow !important;
		&:hover {
			background: $c-data4-yellow !important;
		}
	}
}

.section-services-btn {
	button {
		border: 1px solid $c-data4-red !important;

		&:hover {
			background: $c-data4-red !important;
		}
	}
}

.section-sustainability-btn {
	button {
		border: 1px solid $c-data4-green !important;

		&:hover {
			background: $c-data4-green !important;
		}
	}
}

.section-management-btn {
	button {
		border: 1px solid $c-data4-blue !important;

		&:hover {
			background: $c-data4-blue !important;
		}
	}
}
