
.empty-space {
	@include zindex(empty-space);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: 300px;
	.empty-space-box {
		width: 100%;
		margin: 0 auto;
	}
	.icon {
		@include fontSize(36px);
		color: $c-grey-variant-6;
		text-align: center;
		width: 100%;
		font-size: 50px;
		display: block;
	}
	.label {
		@include fontSize(14px);
		color: $c-grey-variant-6;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: center;
		margin-top: 10px;
	}
	.legend {
		position: relative;
		@include fontSize(12px);
		color: $c-grey-variant-7;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		margin-top: 25px;

		&:after {
			content: '';
			position: absolute;
			top: -15px;
			margin: 0 auto;
			display: block;
			height: 1px;
			background: $c-grey-variant-7;
			width: 200px;
			right: 0;
			left: 0;
		}
	}
}

.page-container {
	.empty-space {
		background-color: $c-white;
	}
}
