@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wgvs20');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wgvs20#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wgvs20') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wgvs20') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wgvs20##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-ticket {
  &:before {
    content: $icon-menu-ticket;
  }
}

.icon-acces-badge {
  &:before {
    content: $icon-acces-badge;
  }
}

.icon-acces-cle {
  &:before {
    content: $icon-acces-cle;
  }
}

.icon-acces-digicode {
  &:before {
    content: $icon-acces-digicode;
  }
}

.icon-acces-non-gere {
  &:before {
    content: $icon-acces-non-gere;
  }
}

.icon-appbar-aide {
  &:before {
    content: $icon-appbar-aide;
  }
}

.icon-appbar-menu {
  &:before {
    content: $icon-appbar-menu;
  }
}

.icon-appbar-notifications {
  &:before {
    content: $icon-appbar-notifications;
  }
}

.icon-appbar-pictogramme {
  &:before {
    content: $icon-appbar-pictogramme;
  }
}

.icon-appbar-rechercher {
  &:before {
    content: $icon-appbar-rechercher;
  }
}

.icon-appbar-utilisateur {
  &:before {
    content: $icon-appbar-utilisateur;
  }
}

.icon-demande-acces {
  &:before {
    content: $icon-demande-acces;
  }
}

.icon-demande-multiple {
  &:before {
    content: $icon-demande-multiple;
  }
}

.icon-demande-courant-faible {
  &:before {
    content: $icon-demande-courant-faible;
  }
}

.icon-demande-courant-fort {
  &:before {
    content: $icon-demande-courant-fort;
  }
}

.icon-demande-geste-de-proximite {
  &:before {
    content: $icon-demande-geste-de-proximite;
  }
}

.icon-demande-incident {
  &:before {
    content: $icon-demande-incident;
  }
}

.icon-demande-livraison {
  &:before {
    content: $icon-demande-livraison;
  }
}

.icon-demande-revoquer-acces {
  &:before {
    content: $icon-demande-revoquer-acces;
  }
}

.icon-demande-autre {
  &:before {
    content: $icon-demande-autre;
  }
}

.icon-icon-acces-biometrie {
  &:before {
    content: $icon-icon-acces-biometrie;
  }
}

.icon-menu-consignes-livraison {
  &:before {
    content: $icon-menu-consignes-livraison;
  }
}

.icon-menu-demandes {
  &:before {
    content: $icon-menu-demandes;
  }
}

.icon-menu-espace-documentaire {
  &:before {
    content: $icon-menu-espace-documentaire;
  }
}

.icon-menu-liste-acces-en-cours {
  &:before {
    content: $icon-menu-liste-acces-en-cours;
  }
}

.icon-menu-salles {
  &:before {
    content: $icon-menu-salles;
  }
}

.icon-menu-societes {
  &:before {
    content: $icon-menu-societes;
  }
}

.icon-menu-tableau-de-bord {
  &:before {
    content: $icon-menu-tableau-de-bord;
  }
}

.icon-menu-utilisateurs {
  &:before {
    content: $icon-menu-utilisateurs;
  }
}

.icon-navigation-ajouter {
  &:before {
    content: $icon-navigation-ajouter;
  }
}

.icon-navigation-alerte {
  &:before {
    content: $icon-navigation-alerte;
  }
}

.icon-navigation-confidentiel {
  &:before {
    content: $icon-navigation-confidentiel;
  }
}

.icon-navigation-editer {
  &:before {
    content: $icon-navigation-editer;
  }
}

.icon-navigation-equipe {
  &:before {
    content: $icon-navigation-equipe;
  }
}

.icon-navigation-fermer {
  &:before {
    content: $icon-navigation-fermer;
  }
}

.icon-navigation-filtrer {
  &:before {
    content: $icon-navigation-filtrer;
  }
}

.icon-navigation-fleche-bas {
  &:before {
    content: $icon-navigation-fleche-bas;
  }
}

.icon-navigation-fleche-droite {
  &:before {
    content: $icon-navigation-fleche-droite;
  }
}

.icon-navigation-fleche-gauche {
  &:before {
    content: $icon-navigation-fleche-gauche;
  }
}

.icon-navigation-fleche-haut {
  &:before {
    content: $icon-navigation-fleche-haut;
  }
}

.icon-navigation-graphique-batons {
  &:before {
    content: $icon-navigation-graphique-batons;
  }
}

.icon-navigation-ko {
  &:before {
    content: $icon-navigation-ko;
  }
}

.icon-navigation-localisation {
  &:before {
    content: $icon-navigation-localisation;
  }
}

.icon-navigation-ok {
  &:before {
    content: $icon-navigation-ok;
  }
}

.icon-navigation-perte-connexion {
  &:before {
    content: $icon-navigation-perte-connexion;
  }
}

.icon-navigation-planification {
  &:before {
    content: $icon-navigation-planification;
  }
}

.icon-navigation-plus {
  &:before {
    content: $icon-navigation-plus;
  }
}

.icon-navigation-statut {
  &:before {
    content: $icon-navigation-statut;
  }
}

.icon-navigation-telecharger {
  &:before {
    content: $icon-navigation-telecharger;
  }
}

.icon-navigation-valide {
  &:before {
    content: $icon-navigation-valide;
  }
}

.icon-page-demande {
  &:before {
    content: $icon-page-demande;
  }
}

.icon-page-lien {
  &:before {
    content: $icon-page-lien;
  }
}

.icon-page-serveur {
  &:before {
    content: $icon-page-serveur;
  }
}
.icon-navigation-carnet-d-adresses {
  &:before {
    content: $navigation-carnet-d-adresses;
  }
}

.icon-page-tickets {
  &:before {
    content: $icon-page-tickets;
  }
}

.icon-page-2factors {
  &:before {
    content: $icon-page-2factors;
  }
}

.icon-menu-planification {
  &:before {
    content: $icon-menu-planification;
  }
}

.icon-navigation-temps {
  &:before {
    content: $icon-navigation-temps;
  }
}

.icon-menu-kpi-environementaux {
  &:before {
    content: $icon-menu-kpi-environementaux;
  }
}

.icon-navigation-information {
  &:before {
    content: $icon-navigation-information;
  }
}

.icon-kpi-co2 {
  &:before {
    content: $icon-kpi-co2;
  }
}

.icon-kpi-eau {
  &:before {
    content: $icon-kpi-eau;
  }
}

.icon-kpi-energie {
  &:before {
    content: $icon-kpi-energie;
  }
}

.icon-kpi-ressources {
  &:before {
    content: $icon-kpi-ressources;
  }
}

.icon-kpi-eutrophisation {
  &:before {
    content: $icon-kpi-eutrophisation;
  }
}

.icon-page-crossconnect {
  &:before {
    content: $icon-page-crossconnect;
  }
}

.icon-menu-proximite {
  &:before {
    content: $icon-menu-proximite;
  }
}

.icon-menu-kpi {
  &:before {
    content: $icon-menu-kpi;
  }
}

.icon-menu-rapportparzone {
  &:before {
    content: $icon-menu-rapportparzone;
  }
}

.icon-menu-certificat {
  &:before {
    content: $icon-menu-certificat;
  }
}

.icon-menu-carbone {
  &:before {
    content: $icon-menu-carbone;
  }
}

.icon-chevron {
  &:before {
    content: $icon-chevron;
  }
}

.icon-chevron-support {
  &:before {
    content: $icon-chevronsupport;
  }
}

.icon-drag-and-drop {
  &:before {
    content: $icon-draganddrop;
  }
}


.icon-visibility {
  &:before {
    content: $icon-visibility;
  }
}

.icon-visibility-off {
  &:before {
    content: $icon-visibility-off;
  }
}

.icon-escort {
  &:before {
    content: $icon-escort;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}

.icon-power-usage {
  &:before {
    content: $icon-power-usage;
  }
}

.icon-water-usage {
  &:before {
    content: $icon-water-usage;
  }
}