
.pagination {
  .liste-annuaire + & {
    margin-top: 20px;
  }

  .pagination-container {
    display: flex;
    flex-direction: row;
  }

  .pagination-count {
    height: 25px;
    margin: 6px 0;

    span {
      margin: 0 1px;
    }

    > span:nth-child(4) {
      margin: 0 3px;
    }
  }

  .btn {
    height: 25px;
    padding: 0 5px;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
}