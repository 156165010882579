.kpi-env {
  .reports-list {
    @extend %important-text;
    text-transform: uppercase;
  }
  .reports-list-bloc {
    margin-bottom: 10px;
  }
  .kpi-icon-container {
    padding-top: 5px;
  }
  .kpi-icon {
    color: $c-data4-red;
    @include fontSize(40px);
  }
  .icon-kpi-eutrophisation {
    @include fontSize(30px);
  }
  // le bouton est déplacé à droite au-dessus d'une certaine largeur, sinon on ajoute un padding
  @media (min-width: $sm-queries + 1px) {
    .kpi-dropdown {
      display: flex;
      justify-content: flex-end;
    }
  }
  @media (max-width: $sm-queries) {
    .kpi-dropdown {
      margin-top: 10px;
    }
  }
  // le bloc est masqué en-dessous d'une certaine largeur
  @media (max-width: $sm-queries) {
    .kpi-chart {
      display: none;
    }
  }
  .impact-category {
    padding: 20px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 -5px;
    &:last-child {
      padding-bottom: 0;
    }
    &+.impact-category {
      border-top: 1px solid $c-grey-variant-9;
    }
  }
  .impacts-text-container {
    flex: 0.6;
  }
  .impacts-text {
    margin-bottom: 10px;
    @extend %important-text;
  }

  // Style du bloc pour les indicateurs PUE et WUE
  .report-datacenter-block {
    margin-top: 20px;
    .report-datacenter-row:not(:first-child) {
      padding-top: 18px;
    }
    .report-datacenter-row:not(:last-child) {
      padding-bottom: 18px;
      border-bottom: 1px solid $c-grey-variant-9;
    }
    .content {
      display: flex;
      flex-direction: row;
    }
    .indicator {
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 18px 10px;
      &-divider {
        border-left: 1px solid $c-grey-variant-9;
      }
    }
    .data {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 40px;
      @media (max-width: $sm-queries) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}