$custom-pretty-line-height: 1.72em;
/* SURCHARGE DES VARIABLES */
$pretty--box-size: 18px;
$pretty--label-text-offset: 0;
$pretty--curve-radius: 3px;
$pretty--color-primary: $c-main;
$pretty--color-success: $c-main;
$pretty--color-default: $c-grey-variant-6;

@import '~pretty-checkbox/src/pretty-checkbox.scss';

$custom-pretty-top-offset: calc((#{$custom-pretty-line-height} - #{$pretty--box-size}) / 2);

.pretty {
  margin: 10px 0;
  line-height: $custom-pretty-line-height;
  white-space: normal;
  .state {
    padding-left: calc(1.5em + 9px);
    label {
      &:after,
      &:before {
        top: $custom-pretty-top-offset;
        border-width: 2px;
      }
    }
  }
  input:checked ~ .state label {
    &:before,
    &:after {
      border-color: $c-main;
    }
  }
  &.p-default {
    .state {
      label:after {
        transform: scale(0.5);
      }
    }
  }
  &.p-icon {
    .state {
      .icon {
        font-weight: $ft-xblack;
        top: $custom-pretty-top-offset;
      }
      label {
        &:after,
        &:before {
          top: $custom-pretty-top-offset;
        }
      }
    }
  }
}
