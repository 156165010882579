/* BEGIN Light Italic */
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "LightItalic");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: RobotoCondensed;
	@include fontdef-woff($FontPathCondensed, $FontNameCondensed, $FontVersion, "LightItalic");
	font-weight: 300;
	font-style: italic;
}
/* END Light Italic */
