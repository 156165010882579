.forfait-select-block {
  @media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
    .forfait-selector {
      margin-bottom: 10px;
    }
  }
}
.forfait-actions-list {
  .champ-obligatoire {
    margin-bottom: 10px;
    margin-top: 30px;
  }
}
.forfait-actions-item {
  border-top: 1px solid $c-grey-variant-9;
  padding: 15px 10px;
  position: relative;
  .head {
    @extend %important-text;
    margin-bottom: 5px;
  }
  .content {
    @extend %content-text;
  }
  .date {
    @extend %content-text;
    position: absolute;
    top: 15px;
    right: 10px;
    @media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
      display: none;
    }
  }
}

.forfait-deficit {
  color: $c-error !important;
}

.tickets-information {
  @extend %form-title;
  .icon {
    margin-right: 10px;
  }
}

.one-shot-forfait-header {
  display: flex;
  align-items: center;
  .tickets-information {
    text-align: right;
    @media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
      text-align: left;
    }
  }
}
