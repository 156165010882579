.collapsable-cgu {
  .head {
    @extend %form-title;
  }
}

.cgu-locked-navigation {
  .cgu-locked-label {
    @extend %form-title;
    color: $c-white;
  }
}

@media (max-width: $sm-queries)  {
  .cgu-locked-navigation {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    .nav-left {
      width: 80%;
    }
  }
}