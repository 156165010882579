#main {
  &.non-connected {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: url('/resources/img/background.jpg') top center no-repeat;
    background-size: cover;
    .app-version {
      position: absolute;
      left: 15px;
      top: 0;
      & + .full-height {
        padding-top: 20px;
      }
    }

    .login-md-title {
      width: 100%;
      padding-right: 35px;
      padding-left: 35px;
      margin-right: auto;
      margin-left: auto;
      color: black;
    }

    .login-title-form {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }

    .login-big-title {
      margin-top: 5%;
      text-align: center;
      font-size: 25px;
      color: black;
    }

    .margin-login {
      margin: auto;
      display: flex;
      flex-direction: column;
    }

    .display-login-page {
      display: flex;
      align-items: center;
      justify-content: space-around;
      min-height: 100vh;
    }

    .login-right {
      width: 55%;
      min-height: 100vh;
      background: $c-white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .footer-right {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 55%;
      position: fixed;
      bottom: 0;
    }

    .login-left {
      width: 45%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    }

    .footer-left {
      font-size: 17px;
      position: fixed;
      bottom: 0;
      text-align: center;
    }

    .half-bg{
      max-width: 50%;
      background: fade_out($c-white, .15);
    }
    .full-bg {
      background: fade_out($c-white, .15);
    }
    .full-height {
      height: 100%;
    }
    img.logo {
      display: block;
      width: 50%;
    }
    .link-box {
      margin-top: 20px;
      padding-top: 20px;
      width: 150px;
      border-top: 2px solid $c-grey-variant-6;
      text-align: center;
      > a {
        &:hover {
          text-decoration: none;
          color: $c-main;
        }
      }
    }
  }
}

.login-form {
  .password-forgotten {
    @extend %important-text;
    cursor: pointer;
  }
}
.login-form-password {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: $c-grey-variant-9;
  height: $form-field-height;
  padding: 0;
  .hide-password-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 10px;
    z-index: 2;
    right: 5px;
    &:hover {
      color: $c-red-variant-1;
    }
  }
}

.paper-login {
  width: 300px;
  min-height: 215px;
  box-shadow: none !important;
  .modal {
    position: relative;
    display: block;
  }
  .modal-dialog {
    margin: 0;
    pointer-events: auto;
  }
}

.paper-reset-password {
  box-shadow: none !important;
}

.empty-body {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  justify-content: center;
  align-items: center;
  .icon {
    @include fontSize(41px);
    margin-bottom: 10px;
  }
}

.login-footer {
  background: transparent !important;
  height: auto !important;
  display: flex;
  justify-content: center;
  > :not(:first-child) {
    margin-left: 20px;
  }
  > :not(:last-child) {
    margin-right: 0;
  }
}

@media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
  .login-footer {
    flex-direction: column;

    > * {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

@media (max-width: $md-queries) and (orientation: landscape) {
  .full-bg, .login-right {
    overflow: scroll;
  }

  .footer-right {
    display: none !important;
  }
}

@media (max-width: $sm-queries) {
  .display-login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .login-big-title, .login-md-title {
    margin-top: 5% !important;
  }

  .login-right{
    width: 100% !important;
    justify-content: flex-start !important;
  }

  .footer-right {
    width: 300px !important;
  }

  .footer-left{
    font-size: 14px !important;
  }

  .login-left {
    width: 100% !important;
    height: 40% !important;
  }
}
