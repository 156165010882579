.no-suggestions {
    color: $c-grey-variant-6;
    padding: 0.5rem;
}

.form-standard{
    .autocomplete.group-form-control {
        position: relative;
        display: inline-block;
    }
}

.suggestions {
    @include fontSize(14px);
    border: 1px solid $c-grey-variant-9;
    background-color: $c-white;
    border-radius: 2px;
    list-style: none;
    padding-left: 0;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: $c-grey-variant-3;
    font-family: $ft-fam-primary;
    cursor: pointer;
    z-index: 1000;
    position: absolute;
    left: 0;
    width: 100%;
}

.suggestions li {
    padding: 0.5rem;
    min-height: 44px;
}

.suggestion-active,
.suggestions li:hover {
    color: $c-white;
    background-color: $c-main;
    cursor: pointer;
}
