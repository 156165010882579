.background-icon {
  position: fixed;
  bottom: 20px;
  pointer-events: none;
  @include zindex(background);
  .icon {
    @include fontSize(300px);
    color: transparentize($c-grey-variant-3, .9);
  }
}

// variables reprises de bootstrap
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);

// l'icône est masquée en-dessous d'une certaine largeur
@media (max-width: #{map-get($grid-breakpoints, "sm") - 1}) {
  .background-icon {
    display: none;
  }
}

@each $breakpoint, $container-max-width in $container-max-widths {
  @media (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {
    .background-icon {
      // la position de l'icône est ajustée en fonction de la largeur du container
      right: calc((100vw - #{$container-max-width}) / 2 - 100px)
    }
  }
}
