.list-collapse {
  position: relative;
  background: $c-white;
  box-shadow: 0 0 10px 0 $c-black-t00, 0 0 20px 0 $c-black-t00;
  @include zindex(list-collapse);
  margin: 0;
  padding: 0;
  border: 0px solid $c-white;
  border-bottom: 1px solid $c-grey-variant-9;
  transition: all .15s ease-in-out;
  transition-delay: 0ms;
  &:last-child {
    margin-bottom: 20px;
    border-bottom: 0;
  }
  &.active {
    box-shadow: 0 0 10px 0 $c-grey-variant-10, 0px 20px 20px 0 $c-grey-variant-11;
    @include zindex(list-collapse-active);
    margin: 20px -20px;
    padding: 0;
    border: 1px solid $c-grey-variant-10;
    transition: all .25s ease-in-out;
    .list-collapse-head {
      .list-head-label {
        .label-group {
          .label-1 {
            word-break: break-word;
            white-space: pre-line;
          }
        }
      }
    }
  }
  .list-collapse-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    min-height: 70px;
    width: 100%;
    cursor: pointer;
    @include fontSize(14px);
    background: inherit;
    color: $c-grey-variant-3;

    &:last-child {
      padding-right: 10px;
    }

    &.list-header {
      cursor: auto;
      background-color: $c-grey-t80;
      color: $c-white;
      min-height: auto;
      height: 45px;
    }

    .list-head-right {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      width: 100%;
    }
    .list-head-right-top {
      display: flex;
      flex-direction: row;
      width: 50%;
    }
    .list-head-right-bottom {
      display: flex;
      flex-direction: row;
      flex: 0 1 0;
      align-items: center;
    }

    .list-head {
      .icon-escort {
        @include fontSize(22px);
        color: $c-data4-red;
        font-weight: $ft-thin;
      }
    }
    .list-head-icon {
      width: 10%;
      display: flex;
      justify-content: space-evenly;

      .icon {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $c-blue-variant-3;
        background-color: $c-blue-variant-4;
      }
    }
    .list-head-ref {
      display: flex;
      flex-direction: column;
      flex: 0 1 145px;
      min-width: 145px;

      .societe {
        @include fontSize(14px);
        @include truncateMultiLines(2, 1.3);
        font-weight: $ft-bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
      }
      .code-ref {
        @include fontSize(12px);
        font-weight: $ft-reg;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
        color: $c-grey-variant-6;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
    .list-head-alert {
      padding-right: 10px;
      .important {
        margin: 0;
        background-color: $c-main;
      }
    }
    .list-head-notes {
      display: flex;
      flex: 0 1;
      position: relative;
      min-width: 45px;
      align-items: center;
      .bullet-pick {
        position: relative;
        z-index: 10;
        @include fontSize(14px);
        font-weight: $ft-reg;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        height: 20px;
        width: 24px;
        background: $c-grey-variant-10;
        border: solid 1px $c-grey-variant-10;
        border-radius: 2px;
      }
      .arrow {
        content: '';
        position: relative;
        z-index: 0;
        right: 4px;
        top: 0;
        display: flex;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        background: $c-grey-variant-10;
        box-shadow: 0 0 0px 1px $c-grey-variant-10;
      }
      .important {
        position: absolute;
        display: block;
        height: 14px;
        width: 14px;
        z-index: 11;
        top: calc((100% - 20px) / 2 - 6px);
        right: 15px;
        background: $c-white;
        border: 1px solid $c-main;
        border-radius: 10px;
        @include fontSize(8px);
        text-align: center;
        line-height: 1.5;
        &.question-mark {
          color: $c-main;
          &:after {
            content: '?'
          }
        }
      }
    }
    .list-head-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1 1 100%;

      .badge-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $c-main;
        color: $c-white;
        text-transform: inherit;
        padding: 0 .6rem;
        border-radius: 50px;
        margin-right: 7px;
        min-height: 20px;
        max-height: 20px;

        @include fontSize(11px);
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }
      .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $c-main;
        color: $c-white;
        text-transform: uppercase;
        padding: 0 1rem;
        border-radius: 50px;
        margin-right: 15px;
        min-height: 20px;
        max-height: 20px;

        @include fontSize(11px);
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }
      .label-group {
        @extend %text-ellipsis;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 24px;
        width: 100%;
        max-width: inherit;
        .label-1, .label-2 {
          @extend %text-ellipsis;
          position: relative;
          @include fontSize(14px);
          font-weight: $ft-med;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
        }
        .label-1 {
          color: $c-grey-variant-3;
          margin-right: 5px;
          max-width: 330px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
        .label-1-urgent {
          color: $c-red-variant-1;
        }
        .label-2 {
          color: $c-grey-variant-6;
          margin-right: 15px;
          display: flex;
          align-items: center;
          max-width: 250px;
          > * {
            @extend %text-ellipsis;
          }
        }
        .label-3 {
          color: $c-grey-variant-6;
          @include fontSize(12px);
          > * {
            @extend %text-ellipsis;
          }
        }
        .post-recent {
          @include fontSize(12px);
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: right;
          color: $c-error;
          white-space: break-spaces;
          max-width: 150px;
        }
      }

    }
    .list-head-date {
      width: 10%;

      .post-timer {
        @include fontSize(14px);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        white-space: nowrap;
      }
    }
    .list-head-status {
      width: 10%;
      div {
        display: flex;
        justify-content: left;
        align-items: center;
        &::before {
          content: '';
          border-radius: 50%;
          height: 10px;
          width: 10px;
          margin-right: 10px;
        }
        &.urgent {
          color: $c-red-variant-1;
        }
        &.urgent:before {
          background-color: $c-data4-red;
        }
        &.standard:before {
          background-color: $c-data4-yellow;
        }
        &.other:before {
          background-color: $c-error;
        }
        &.open:before {
          background-color: $c-data4-green;
        }
        &.in-progress:before {
          background-color: $c-data4-blue;
        }
        &.closed:before {
          background-color: $c-main;
        }
      }
      .chip {
        border-radius: 16px;
        width: fit-content;
        padding: 0 10px;
        &.open {
          background-color: $c-data4-green-variant-t10 !important;
        }

        &.in-progress {
          background-color: $c-data4-blue-variant-t10 !important;
        }

        &.closed {
          background-color: $c-grey-variant-9 !important;
        }
      }
    }
    .align-space-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .list-collapse-body {
    padding: 20px 20px 0;
    opacity: 0;
    transition: height .2s ease;
    border-top: 1px solid $c-grey-variant-9;
    &.collapse.show {
      opacity: 1;
    }
    &.collapse {
      opacity: 0;
    }
    &.collapsing {
      opacity: 0;
      padding: 0 20px;
      border-top: 0;
    }

    .list-body-item {
      margin-bottom: 20px;
    }
    .separator-container {
      margin-right: -20px;
      margin-left: -20px;
    }
    .separator {
      height: 1px;
      width: 100%;
      background-color: $c-grey-variant-9;
    }
  }
  .btn.btn-secondary {
    background: $c-white;
    color: inherit;
    border-radius: 16px;
    max-width: inherit;
  }

  .list-body-item {
    .head {
      margin-bottom: 20px;
    }
    .paper {
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 $c-black-t10;
      border: solid 1px $c-grey-variant-9;
      background-color: $c-grey-variant-11;
    }
    .title {
      @include fontSize(14px);
      font-weight: $ft-med;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $c-main;
    }
    .content {
      @include fontSize(14px);
      font-weight: $ft-reg;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $c-main;
      &+.content {
        margin-top: 10px
      }
    }
    .link {
      @include fontSize(12px);
      font-weight: $ft-med;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $c-main;
    }
  }
  .demande-acces {
    .paper {
      padding: 20px;
      background-color: $c-grey-variant-11;
    }
    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
      & + .body {
        margin-top: 20px;
      }
    }
    .body {
      .btn-download {
        padding-left: 0;
      }
    }
    .highlight-data {
      color: $c-data4-red;
    }
  }
  .demande-espace-associe {
    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 20px 0;
    }
    .body {
      padding: 20px;
      table {
        th {
          @extend %help-text;
        }
        td {
          @extend %important-text;
        }
        th, td {
          padding: 10px 20px;
        }
      }
      thead {
        th {
          border: none;
          width: 200px;
        }
      }
      tbody {
        td {
          width: 200px;
          color: $c-main;
          span {
            & + span {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .demande-ouverte {
    .paper {
      padding: 20px;
      background-color: $c-grey-variant-11;
    }
    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .body {
      .information-group {
        display: flex;
        align-items: center;
        > .icon {
          @include fontSize(22px);
          margin-right: 10px;
        }
        > .form-group {
          flex: 1;
        }

        .form-control {
          border: none !important;

          .btn.btn-secondary {
            &.open {
              background-color: $c-data4-green-variant-t10 !important;
            }

            &.in-progress {
              background-color: $c-data4-blue-variant-t10 !important;
            }

            &.closed {
              background-color: $c-grey-variant-9 !important;
            }
          }
        }
      }
    }
  }
  .geste-action-row {
    row-gap: 30px;
  }
  .demande-escalader {
    .paper {
      padding: 20px;
      background-color: $c-grey-variant-11;
    }
    .body {
      margin: 0;
      padding: 0;
    }
    .form-control {
      background: none !important;
    }
  }
  %middle-row-container {
    background: $c-grey-variant-11;
    padding: 20px 20px 40px;
    margin: 0 -20px;
  }
  %middle-row-paper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
  }
  %middle-row-block {
    margin-top: 20px;
  }
  .demande-actions {
    @extend %middle-row-container;
    .head {
      margin-bottom: 0;
    }
    .paper{
      @extend %middle-row-paper;
      height: 100%;
    }
    .action-block {
      @extend %middle-row-block;
      min-height: 180px;
      height: 100%;
    }
    .action-information {
      word-wrap: break-word;
      word-break: break-all;
      white-space: pre-wrap;
      &+.action-information {
        margin-top: 10px;
      }
      .info > .tickets {
        display: flex;
        align-items: center;
        .btn-tooltip {
          margin-left: 10px;
          .icon {
            font-size: 14px;
          }
        }
      }
    }
    .btn-add-action {
      margin: auto;
    }
  }
  .demande-services {
    @extend %middle-row-container;
    .head {
      margin-bottom: 0;
    }
    .paper{
      @extend %middle-row-paper;
    }
    .service-block {
      @extend %middle-row-block;
    }
    .service-information {
      &+.service-information {
        margin-top: 10px;
      }
    }
  }
  .demande-visiteur {
    .head {
      margin-bottom: 0;
    }
    .btn-tooltip {
      height: 20px;
    }
    .paper {
      @extend %middle-row-paper;
      height: 100%;
      padding: 20px;
    }
    .visitor-block {
      @extend %middle-row-block;
      background-color: $c-white;
      &-lg {
        height: 200px;
      }
      &-sm {
        height: 145px;
      }
      .form-control {
        border: none !important;
        background: none !important;
      }
    }
    .name {
      @include fontSize(14px);
      color: $c-grey-variant-3;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .mail {
      @include fontSize(14px);
      color: $c-grey-variant-3;
      font-weight: $ft-med;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .info {
      @include fontSize(14px);
      color: $c-grey-variant-3;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      height: 100%;
    }
    .icon {
      .c-primary {
        color: $c-main;
      }
    }
    .visitor-information {
      color: $c-main;
      &+.visitor-information {
        margin-top: 10px;
      }
      &.row {
        align-items: center;
      }
      .open-visitors-book {
        display: flex;
        justify-content: flex-end;
        .btn {
          width: auto;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .demande-note {
    .head {
      margin-bottom: 10px;
      margin-top: 20px;
    }
    .body {
      .demande-note-post-commentaire {
        padding: 0;
        border-bottom: 0px solid $c-grey-variant-10;
        .files-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 15px;
          .btn-download {
            max-height: 36px;
          }
        }
        .btn-download {
          .icon {
            margin-right: 0;
          }
        }
      }
      .text {
        line-height: normal;
        color: $c-grey-variant-2;
      }
      .icon {
        margin-right: 1rem;
      }
      .form-control-file {
        width: auto;
      }
      textarea {
        height: 100px;
      }
      .form-standard {
        &.add-note {
          .group-checkbox {
            flex: 1;
          }
        }
      }
    }
  }
  .taken-over-data4-label {
    &:after {
      content: " - ";
    }
  }
}

//xl
@media (min-width: $lg-queries + 1px) {
  .list-collapse {
    .list-collapse-head {
      .list-head-date {
        white-space: nowrap;
      }
    }
  }
}

.list-collapse.mobile {
  margin-left: -15px;
  margin-right: -15px;
  .list-collapse-head {
    justify-content: flex-start;
    .list-head {
      padding-right: 0;
      padding-left: 10px;
    }
    .list-head-right {
      flex-direction: column;
    }
    .list-head-icon {
      width: auto;
      padding: 0 10px;
    }
    .list-head-right-top {
      width: 100%;

      .label-group {
        padding: 5px 0;

        .summary-mobile {
          width: 200px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .list-head-status, .list-head-date {
        width: auto;
      }
    }
    .criticite {
      position: relative;
      &.urgent:before {
        background-color: $c-data4-red;
      }
      &.standard:before {
        background-color: $c-data4-yellow;
      }
      &.other:before {
        background-color: $c-error;
      }
      &:before {
        position: absolute;
        width: 3px;
        border-radius: 4px;
        height: 25px;
        top: 30%;
        content: "";
        @media (max-width: $md-queries) {
          height: 60%;
          top: 20%
        }
      }
    }
    .list-head-ref {
      flex-direction: row;
      align-items: center;
      flex: 1;
    }

    .list-head-notes {
      padding-right: 0;
      margin-right: 10px;
      min-width: 35px;
      .important {
        right: 0;
      }
    }

    .societe, .code-ref {
      margin-right: 10px;
    }

    .list-head-date {
      padding-right: 1rem;
      padding-left: 0;
    }
  }

  .information-group {
    width: 100%;
  }

  @media (min-width: $sm-queries + 1px) {
    .list-collapse-head {
      .icon-escort {
        margin-right: 20px;
      }
      .list-head-right {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
      .list-head-right-top {
        margin-bottom: 0;
      }
      .list-head-date {
        bottom: 25px;
      }
    }
  }
}
