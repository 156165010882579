.kpi-certificates {
  .kpi-certificates-bloc {
    margin-top: 20px;
  }
  @media (min-width: $xs-queries + 1px) and (max-width: $md-queries) {
    .kpi-certificate {
      & + .kpi-certificate {
        margin-top: 20px;
      }
    }
  }
}