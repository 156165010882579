
.title-advanced {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		bottom: -10px;
		height: 1px;
		width: 50px;
		background: $c-main;
	}

	.label-small {
		@include fontSize(16px);
		font-weight: $ft-bold;
		color: $c-main;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
	}
	.label-large {
		@include fontSize(16px);
		font-weight: $ft-bold;
		text-transform: uppercase;
		color: $c-grey-variant-3;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
	}
}
