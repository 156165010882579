.access-detail {
  .head {
    @extend %important-text;
  }
  .request-access-title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .head {
      flex: 1;
    }
  }
  .access-information {
    @extend %content-text;
    margin-bottom: 40px;
  }
}