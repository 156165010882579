
.bloc-config {
	display: flex;
	height: 100%;
	border-top: 1px solid $c-grey-variant-10;
	&:first-child {
		border-top: none;
	}
	.box {
		display: flex;
		flex-direction: column;
		padding: 20px;
		width: 100%;
		.head {
			margin-bottom: 10px;
			.title {
				@include fontSize(14px);
				font-weight: $ft-reg;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: $c-grey-variant-6;
				margin: 0;
			}
		}
		.body {
			flex: 1;
			.list-items {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 5px;
				.legend {
					@include fontSize(13px);
					font-weight: $ft-reg;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.45;
					letter-spacing: normal;
					color: $c-grey-variant-3;
				}
				.result {
					@include fontSize(14px);
					font-weight: $ft-med;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.43;
					letter-spacing: normal;
					text-align: right;
					color: $c-grey-variant-3;
				}
			}
		}
		.foot {
			display: flex;
			justify-content: flex-end;
		}

	}
}
