/* BEGIN Black Italic */
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "BlackItalic");
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: RobotoCondensed;
	@include fontdef-woff($FontPathCondensed, $FontNameCondensed, $FontVersion, "BlackItalic");
	font-weight: 900;
	font-style: italic;
}
/* END Black Italic */
